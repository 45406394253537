<template>
  <div class="role-list">
    <Table
      border
      :loading="loadingShow"
      :columns="roleTitle"
      :data="roleList"
      :stripe="true"
      highlight-row
      @on-selection-change="selectRole"
    >
      <template slot-scope="{ row, index }" slot="action">
        <Button type="text" size="small" @click="setPermissions(row, index)"
          >设置权限</Button
        >
        <Button type="text" size="small" @click="editRole(row, index)"
          >编辑</Button
        >
        <Button type="text" size="small" @click="delectRole(row, index)"
          >删除</Button
        >
      </template>
    </Table>
    <Page
      ref="page"
      style="margin-top:10px"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    />
    <Modal
      v-model="setShow"
      title="编辑角色权限"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <template v-if="!draggable">
        <MenuList
          :showCheckbox="setShow"
          :roleId="roleId"
          :menuList="menuRoleList"
          :sortShow="draggable"
        />
      </template>
      <template v-else>
        <Form :model="roleFrom" :label-width="80">
          <FormItem label="角色名称">
            <Input v-model.trim="roleFrom.name" style="width: auto"></Input>
          </FormItem>
          <FormItem label="角色级别">
            <Input v-model.trim="roleFrom.rank" style="width: auto"></Input>
          </FormItem>
          <FormItem label="角色序号">
            <Input
              v-model.trim="roleFrom.serialNumber"
              style="width: auto"
            ></Input>
          </FormItem>
          <FormItem label="备注说明">
            <Input
              v-model.trim="roleFrom.remark"
              style="width: auto"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 5 }"
            ></Input>
          </FormItem>
        </Form>
      </template>
    </Modal>
  </div>
</template>

<script>
import MenuList from "./system-menu/MenuList.vue";
export default {
  props: ["roleList", "count", "index", "pageLimit", "loadingShow"],
  components: {
    MenuList,
  },
  data() {
    return {
      setShow: false,
      roleId: "",
      roleTitle: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "角色名称",
          key: "roleName",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.roleName))]);
          },
        },
        {
          title: "角色等级",
          key: "remake",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.remake))]);
          },
        },
        {
          title: "角色说明",
          key: "remake",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.remake))]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      menuRoleList: [],
      roleFrom: {
        name: "",
        rank: "",
        serialNumber: "",
        remark: "",
      },
      draggable: false,
    };
  },
  created() {
    // 获取权限菜单列表
    this.$axios
      .get(this.apiUrl + "systemMenu/FindAllSystemMenuRoleList")
      .then((res) => {
        // console.log(res);
        this.menuRoleList = res.data.data;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    // 编辑角色权限
    setPermissions(row, index) {
      this.setShow = true;
      this.roleId = row.id;
    },
    // 修改角色
    editRole(row, index) {
      this.draggable = true;
      this.roleFrom = {
        name: row.name,
        rank: row.rank,
        serialNumber: row.number,
        remark: row.remark,
      };
    },
    // 删除角色
    delectRole(row, index) {
       this.$confirm("是否确定删除").then(()=>{
        this.$emit("delete-role",row);
      }).catch(()=>{
        this.$message("取消删除")
      })
    },
    // 提交编辑权限
    ok() {
      this.$Message.info("修改权限成功");
    },
    cancel() {
      this.$Message.info("取消修改");
    },
    selectRole(select) {
      this.$emit("select-list", select);
    },
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>