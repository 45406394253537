<template>
  <div class="operation-device-list">
    <Table
      border
      :loading="loadingShow"
      :columns="language=='Chinese'?listTitle:listTitleEnglish"
      :data="equipmentList"
      :stripe="true"
      highlight-row
      :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
      @on-row-click="equipmentDataApollo"
    >
      <template slot-scope="{ row, index }" slot="action">
        <el-link
          type="primary"
          v-if="row.equipmentConnectstate=='1'&&row. equipmentUpgrade==1"
          style="margin-right: 10px"
          @click.native.stop="controlApollo(row, index)"
          >{{language=="Chinese"?"设备升级":"Equipment upgrade"}}</el-link
        >
        <!-- <el-link
          type="primary"
          style="margin-right: 10px"
          @click.native.stop="faultInfo(row, index)"
          >{{language=="Chinese"?"故障信息":"Fault Information"}}</el-link
        > -->
        <el-link type="primary" @click.native.stop="editDevice(row, index)"
          >{{language=="Chinese"?"编辑":"Edit"}}</el-link
        >
      </template>
    </Table>
    <Page
      ref="page"
      style="margin-top: 10px"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    />
    <Modal
      v-model="modelShow"
      :title="title"
      :loading="loading"
      :ok-text="language=='Chinese'?'确认':'Confirm'"
      :cancel-text="language=='Chinese'?'取消':'Cancel'"
      @on-ok="submitOk"
      @on-cancel="cancel"
    >
      <!-- <template v-if="title == '编辑设备信息'||title == 'Edit device information'"> -->
        <Form :model="operationFrom" :label-width="90" label-colon>
          <FormItem :label="language=='Chinese'?'备注':'Remarks'">
            <Input
              v-model="operationFrom.deviceRemark"
              :placeholder="language=='Chinese'?'请输入备注信息':'Please enter Remark info'"
              style="width: auto"
            ></Input>
          </FormItem>
          <FormItem :label="language=='Chinese'?'地址':'Address'">
            <Input
              v-model="operationFrom.deviceAddress"
              :placeholder="language=='Chinese'?'请输入地址信息':'Please enter Address'"
              style="width: auto"
            ></Input>
          </FormItem>
        </Form>
      <!-- </template> -->
      </Modal>
  </div>
</template>

<script>
export default {
  props: ["equipmentList", "count", "index", "pageLimit", "loadingShow","language"],
  data() {
    return {
      // 列表表头参数和是否启用多选
      listTitle: [
        {
          title: "模块ID",
          key: "wifiId", // 设备列表的数据名称,根据接口数据修改
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.wifiId))]);
          },
        },
        {
          title: "厂家",
          key: "equipmentFactoryName",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentFactoryName)),
            ]);
          },
        },
        {
          title: "设备类型",
          key: "equipmentType",
          align:"center",
          width: "100",
          render: (h, param) => {
            return h("div", [
              h(
                "span",
                this.judgeValue(param.row.equipmentType)
              ),
            ]);
          },
        },
        {
          title: "设备状态",
          key: "equipmentConnectstate",
          align:"center",
          width: "100",
          render: (h, param) => {
            return h("div", [
              h(
                "span",
                param.row.equipmentConnectstate == "1" ? "在线" : "离线"
              ),
            ]);
          },
        },
        {
          title: "版本号", // 暂无数据
          key: "equipmentEdition",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                 this.judgeValue(params.row.equipmentEdition)
              ),
            ]);
          },
        },
        {
          title: "备注",
          key: "equipmentNotes",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentNotes)),
            ]);
          },
        },
        {
          title: "地址",
          key: "equipmentAddress",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentAddress)),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          // width: "auto",
          // width:550,
          color: "#2D8CF0",
        },
      ],
      listTitleEnglish: [
        {
          title: "Module ID",
          key: "wifiId", // 设备列表的数据名称,根据接口数据修改
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.wifiId))]);
          },
        },
        {
          title: "Manufactor",
          key: "equipmentFactoryName",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentFactoryName)),
            ]);
          },
        },
        {
          title: "Equipment Type",
          key: "equipmentType",
          align:"center",
          width: "110",
          render: (h, param) => {
            return h("div", [
              h(
                "span",
               this.judgeValue(param.row.equipmentType)
              ),
            ]);
          },
        },
        {
          title: "Equipment Status",
          key: "equipmentConnectstate",
          align:"center",
          width: "110",
          render: (h, param) => {
            return h("div", [
              h(
                "span",
                param.row.equipmentConnectstate == "1" ? "Online" : "Offline"
              ),
            ]);
          },
        },
        {
          title: "Version", // 暂无数据
          key: "equipmentEdition",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                 this.judgeValue(params.row.equipmentEdition)
              ),
            ]);
          },
        },
        {
          title: "Remarks",
          key: "equipmentNotes",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentNotes)),
            ]);
          },
        },
        {
          title: "Address",
          key: "equipmentAddress",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentAddress)),
            ]);
          },
        },
        {
          title: "Operation",
          slot: "action",
          align: "center",
          color: "#2D8CF0",
        },
      ],
      operationFrom: {
        deviceRemark: "", // 设备编辑备注信息
        deviceAddress: "", // 设备编辑地址信息
      },
      modelShow: false,
      title: null,
      submitUrl: "equipmentApollo/equipmentApolloEdit", // 编辑设备
      deviceId: "",
      ApolloControl: "", // Apollo控制指令
      equipmentId: "",
      loading: false,
      wifiId:"",
      currentPage:this.index,
    };
  },
  created() {
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 编辑设备信息
    editDevice(row, index) {
      this.operationFrom.deviceRemark = row.equipmentNotes;
      this.operationFrom.deviceAddress = row.equipmentAddress;
      this.modelShow = true;
      this.title = this.language=="Chinese"?"编辑设备信息":"Edit device information";
      this.deviceId = row.id;
      this.equipmentId = row.equipmentId;
      this.wifiId = row.wifiId;
    },
    // 设备升级
    controlApollo(row, index) {
      this.deviceId = row.id;
      this.equipmentId = row.equipmentId;
      this.wifiId = row.wifiId;
      this.ApolloControl = "121*1"
      this.$Modal.confirm({
        title:this.language=="Chinese"?"设备升级":"Equipment Upgrade",
        content:this.language=="Chinese"?"是否确认将设备升级?":"Whether upgrade the device?",
        okText:this.language=="Chinese"?"确认":"Confirm",
        cancelText:this.language=="Chinese"?"取消":"Cancel",
        onOk:()=>{
          this.$axios({
              url: this.apiUrl + "equipmentApollo/ApolloMsgSend",
              method: "post",
              params: {
                wifiId: this.wifiId,
                commandVal: this.ApolloControl,
              },
            })
              .then((res) => {
                if (res.data.code == 207) {
                  this.ApolloControl = "";
                  this.equipmentId = "";
                  this.wifiId = "";
                  this.$Message.success(res.data.msg);
                } else {
                  this.$Message.error(res.data.msg);
                }
              })
              .catch((err) => {
                this.loading = false;
                console.log(err);
              });
        }
      })
    },
    // App数据
    equipmentDataApollo(row, index) {
      if(!row.wifiId) return this.$Modal.warning({
          title:this.language=="Chinese"?"提示":"Prompt",
          content:this.language=="Chinese"?"该设备没有模块ID，无法查看数据":"The device does not have a module ID and cannot view data",
          okText:this.language?"确定":"Confirm"
        })
      this.$emit("equipment-data", row);
    },
    // 故障信息
    faultInfo(row, index) {
      this.$emit("fault-info", row);
    },
    // 提交弹框内容信息
    submitOk() {
      let param = {
        id: this.deviceId,
      };
       if (this.title == "编辑设备信息"||this.title == "Edit device information") {
        param = {
          wifiId:this.wifiId,
          equipmentNotes: this.operationFrom.deviceRemark
            ? this.operationFrom.deviceRemark
            : "",
          equipmentAddress: this.operationFrom.deviceAddress
            ? this.operationFrom.deviceAddress
            : "",
        };
        this.$axios({
          url: this.apiUrl + this.submitUrl,
          method: "post",
          params: param,
        })
          .then((res) => {
            this.modelShow = false;
            if (res.data.status == 200) {
              this.$emit("device-setting");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // this.modelShow = false;
    },
    // 取消修改
    cancel() {
      this.operationFrom.deviceRemark = "";
      this.operationFrom.deviceAddress = "";
      this.deviceId = "";
    },
  },
  watch:{
    pageLimit(newValue,oldValue){
      if(newValue!=this.$refs.page.currentPageSize){
        this.$refs.page.currentPageSize = newValue
      }
    }
  }
};
</script>

<style lang="less" scoped>
</style>
<style lang="less">
.ivu-table-tip table {
  width: auto;
}
.ivu-table-overflowX {
  overflow-x: hidden !important;
}
</style>