<template>
  <div class="registered-device-list">
    <Table
      border
      :loading="loadingShow"
      :columns="language=='Chinese'?listTitle:listTitleEnglish"
      :data="registeredDeviceList"
      :stripe="true"
      @on-selection-change="selectionList"
      highlight-row
      :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
    >
      <template slot-scope="{ row, index }" slot="action">
        <el-link type="primary" @click="allocation(row, index)"
          >{{language=='Chinese'?'厂家分配':'Manufacturer Distribution'}}</el-link
        >
      </template>
    </Table>
    <div style="display:flex;margin-top: 10px;align-content: center">
    <template v-if="language=='Chinese'">
        <Page
        ref="page"
        style="margin-top: 10px"
        :total="count"
        :current.sync="$parent.index"
        show-total
        show-elevator
        show-sizer
        @on-page-size-change="$parent.pageLimit = $event"
        />
    </template>
    <template v-else>
        <Page
        ref="page"
        :total="count"
        :current.sync="$parent.index"
        show-total
        ><slot>
        {{count}} in total
        </slot>
        </Page>
        <el-pagination layout="slot">
        <p class="ivu-select" style="font-weight:500">
            <Select v-model="$parent.pageLimit" style="width:auto;">
            <Option :value="10">10 pieces/page</Option>
            <Option :value="20">20 pieces/page</Option>
            <Option :value="30">30 pieces/page</Option>
            <Option :value="40">40 pieces/page</Option>
            </Select>
        </p>
        </el-pagination>
        <el-pagination layout="slot">
        <p class="ivu-page-options-elevator" style="font-weight:500">
            Jump to 
            <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
            pages
        </p>
        </el-pagination>
    </template>
    </div>
    <Modal
      v-model="selectShow"
      :title="language=='Chinese'?'厂家分配':'Manufacturer Distribution'"
      :ok-text="language=='Chinese'?'确认':'Confirm'"
      :cancel-text="language=='Chinese'?'取消':'Cancel'"
      @on-ok="submitOk"
      @on-cancel="cancel"
      >{{language=='Chinese'?'厂家':'Manufactor'}}：
      <Select v-model="equipmentFactory" style="width: 50%">
        <Option v-for="item in factoryList" :value="item.id" :key="item.id" :disabled="selectId==item.id">{{
          item.mechanismName
        }}</Option>
      </Select>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "registeredDeviceList",
    "deviceUrl",
    "index",
    "count",
    "pageLimit",
    "distributionUrl",
    "loadingShow",
    "language"
  ],
  data() {
    return {
      listTitle: [
        // 是否启用多选
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "模块ID",
          key: "wifiId",
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.wifiId))]);
          },
        },
        {
          title: "厂家",
          key: "equipmentFactoryName",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentFactoryName)),
            ]);
          },
        },
        {
          title: "版本号",
          key: "equipmentEdition",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentEdition)),
            ]);
          },
        },
        {
          title: "注册时间",
          key: "equipmentCreatime",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                // new Date(params.row.equipmentCreatime).toLocaleDateString()
                this.timeString(params.row.equipmentCreatime)
              ),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      listTitleEnglish: [
        // 是否启用多选
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "Module ID",
          key: "wifiId",
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.wifiId))]);
          },
        },
        {
          title: "Manufactor",
          key: "equipmentFactoryName",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentFactoryName)),
            ]);
          },
        },
        {
          title: "Version Number",
          key: "equipmentEdition",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentEdition)),
            ]);
          },
        },
        {
          title: "Registration Time",
          key: "equipmentCreatime",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                // new Date(params.row.equipmentCreatime).toLocaleDateString()
                this.timeString(params.row.equipmentCreatime)
              ),
            ]);
          },
        },
        {
          title: "Operation",
          slot: "action",
          align: "center",
        },
      ],
      selectShow: false,
      equipmentFactory: "",
      factoryList: [],
      factoryId: null,
      selectId:"",
      currentPage:this.index,
    };
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 厂家分配
    allocation(row, index) {
      this.factoryId = row.id;
      this.selectShow = true;
      this.selectId = row.equipmentFactoryFk;
      this.equipmentFactory = Number(this.selectId)
      this.$axios({
        url: this.apiUrl + "mechanism/mechanismChildrenByEquipId",
        method: "get",
        params: {
          mechanismType: "1000",
        },
      })
        .then((res) => {
          // console.log(res);
          this.factoryList = res.data.data;
        })
        .catch((err) => err);
    },
    // 获取设备注册列表多选数据
    selectionList(selection) {
      this.$emit("selection-delete", selection);
    },
    // 提交厂家分配数据
    submitOk() {
      if(this.selectId == this.equipmentFactory){
        return this.$alert(this.language=='Chinese'?"不允许重复分配":"Double allocation is not allowed")
      }
      this.$axios({
        url: this.apiUrl + this.distributionUrl,
        method: "post",
        params: {
          Eqid: this.factoryId,
          id: this.equipmentFactory,
          mechanismType:"1001",
        },
      })
        .then((res) => {
          // console.log(res);
          this.$emit("update-factory");
        })
        .catch((err) => err);
    },
    // 取消提交
    cancel() {
      this.factoryId = null;
      this.equipmentFactory = "";
    },
  },
  watch:{
    pageLimit(newValue,oldValue){
      if(newValue!=this.$refs.page.currentPageSize){
        this.$refs.page.currentPageSize = newValue
      }
    }
  }
};
</script>

<style lang="less" scoped>
</style>
<style lang="less">
.ivu-table table {
  width: auto !important;
}
</style>