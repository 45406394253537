<template>
 <!-- Apollo告警列表 -->
  <div class="apollo-alarm">
    <template v-if="language == 'Chinese'">
        <SearchAlarm
        @search-list="getApolloAlarmList($event)"
        :selectAlarm="selectAlarm"
        :fileUrl="fileApolloUrl"
        @update-list="getApolloAlarmList"
        />
    </template>
    <template v-else>
        <SearchAlarmEnglish
        @search-list="getApolloAlarmList($event)"
        :selectAlarm="selectAlarm"
        :fileUrl="fileApolloUrl"
        @update-list="getApolloAlarmList"
        />
    </template>
    <ApolloAlarmList
      @update-alarm="getApolloAlarmList"
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :alarmList="alarmApolloList"
      @selection-alarm="selectAlarm = $event"
      :loadingShow="loadingShow"
      :language="language"
    />
  </div>
</template>

<script>
import SearchAlarm from "../../components/equipment/Chinese/SearchAlarm.vue";
import ApolloAlarmList from "../../components/equipment/ApolloAlarmList.vue";
import SearchAlarmEnglish from "../../components/equipment/English/SearchAlarmEnglish.vue";
export default {
    components: {
        SearchAlarm,
        ApolloAlarmList,
        SearchAlarmEnglish,
    },
    props: ["language"],
    data() {
        return {
        count: 0,
        index: 1,
        pageLimit: 10,
        alarmApolloUrl: "equWarnApollo/equWarnApolloList",
        alarmApolloList: [], // 数据列表
        selectAlarm: [], // 多选列表
        fileApolloUrl: "equWarnApollo/exportGirdData",
        loadingShow: true,
        searchParam: {},
        };
    },
    created() {
        this.getApolloAlarmList();
    },
    methods: {
        // 获取Apollo告警设备列表
        getApolloAlarmList(param) {
        this.index = param
            ? JSON.stringify(param) == JSON.stringify(this.searchParam)
            ? this.index
            : 1
            : this.index;
        this.pageLimit = param
            ? JSON.stringify(param) == JSON.stringify(this.searchParam)
            ? this.pageLimit
            : 10
            : this.pageLimit;
        this.loadingShow = true;
        this.searchParam = param?param:this.searchParam;
        this.$axios({
            url: this.apiUrl + this.alarmApolloUrl,
            method: "get",
            params: {
            page: this.index,
            limit: this.pageLimit,
            warnOperstate: 0,
            equipmentFactoryFk: this.$root.userInfo.organizationId,
            MechanismType: this.$root.userInfo.organizationType,
            ...this.searchParam,
            },
        })
            .then((res) => {
            this.loadingShow = false;
            if (res.data.status == 200) {
                this.alarmApolloList = res.data.data;
                this.count = res.data.count;
            }else{
                this.alarmApolloList = []
            }
            })
            .catch((err) => {
            this.alarmApolloList = []
            this.loadingShow = false;
            console.log(err);
            });
        },
    },
    watch: {
        index(newValue, oldValue) {
        if (newValue != oldValue) {
            this.getApolloAlarmList();
        }
        },
        pageLimit(newValue, oldValue) {
        if (newValue != oldValue) {
            this.getApolloAlarmList();
        }
        },
    },
};
</script>

<style lang="less" scoped>
</style>