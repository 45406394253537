<template>
  <div class="alarm-device">
    <Table
      border
      stripe
      :loading="loadingShow"
      highlight-row
      :columns="language=='Chinese'?listTitle:listTitleEnglish"
      :data="alarmList"
      :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
      @on-selection-change="selectionList"
    >
      <template slot-scope="{ row, index }" slot="action">
        <el-link type="primary" @click="delectAlarm(row, index)">{{language=="Chinese"?"删除":"Delete"}}</el-link
        >&nbsp;
        <el-link type="primary" @click="editAlarm(row, index)">{{language=="Chinese"?"编辑":"Edit"}}</el-link>
      </template>
    </Table>
    <div style="display:flex;margin-top: 10px;align-content: center">
        <template v-if="language=='Chinese'">
            <Page
            ref="page"
            style="margin-top: 10px"
            :total="count"
            :current.sync="$parent.index"
            show-total
            show-elevator
            show-sizer
            @on-page-size-change="$parent.pageLimit = $event"
            />
        </template>
        <template v-else>
            <Page
            ref="page"
            :total="count"
            :current.sync="$parent.index"
            show-total
            ><slot>
            {{count}} in total
            </slot>
            </Page>
            <el-pagination layout="slot">
            <p class="ivu-select" style="font-weight:500">
                <Select v-model="$parent.pageLimit" style="width:auto;">
                <Option :value="10">10 pieces/page</Option>
                <Option :value="20">20 pieces/page</Option>
                <Option :value="30">30 pieces/page</Option>
                <Option :value="40">40 pieces/page</Option>
                </Select>
            </p>
            </el-pagination>
            <el-pagination layout="slot">
            <p class="ivu-page-options-elevator" style="font-weight:500">
                Jump to 
                <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
                pages
            </p>
            </el-pagination>
        </template>
    </div>
    <Modal
      v-model="editNodes"
      :ok-text="language=='Chinese'?'确认':'Confirm'"
      :cancel-text="language=='Chinese'?'取消':'Cancel'"
      :title="language=='Chinese'?'编辑设备告警信息':'Edit equipment alarm information'"
      @on-ok="submitEdit"
      @on-cancel="cancelEdit"
    >
      <Form :model="alarmFrom" :label-width="90" label-colon>
        <FormItem :label="language=='Chinese'?'备注':'Remarks'">
          <Input
            v-model="alarmFrom.editAlarmNodes"
            :placeholder="language=='Chinese'?'请输入备注信息':'Please enter remarks'"
            style="width: auto"
          ></Input>
        </FormItem>
        <FormItem :label="language=='Chinese'?'处理状态':'Processing Status'">
          <Select
            id="edit-alarm-state"
            v-model="alarmFrom.alarmState"
            style="width: 200px"
            :disabled="alarmFrom.alarmState=='1'"
          >
            <Option
              v-for="item in disposeState"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "alarmList",
    "count",
    "index",
    "pageLimit",
    "faultShow",
    "loadingShow",
    "language"
  ],
  data() {
    return {
      listTitle: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "模块ID",
          key: "warnWifiid",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.warnWifiid)),
            ]);
          },
        },
        {
          title: "备注",
          key: "warnNotes",
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.warnNotes))]);
          },
        },
        {
          title: "告警详情",
          key: "warnParm",
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.warnParm))]);
          },
        },
        {
          title: "告警时间",
          key: "warnCreatime",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h("span", this.timeString(params.row.warnCreatime)),
            ]);
          },
        },
        {
          title: "处理状态",
          key: "warnOperstate",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h("span", params.row.warnOperstate == "0" ? "未处理" : "已处理"),
            ]);
          },
        },
        {
          title: "处理时间",
          key: "warnHandletime",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h(
                "span",
                this.timeString(params.row.warnHandletime)
                // new Date(params.row.warnHandletime).toLocaleDateString()
              ),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      listTitleEnglish: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "ModuleID",
          key: "warnWifiid",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.warnWifiid)),
            ]);
          },
        },
        {
          title: "Remarks",
          key: "warnNotes",
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.warnNotes))]);
          },
        },
        {
          title: "Alarm Details",
          key: "warnParm",
          align:"center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.warnParm))]);
          },
        },
        {
          title: "Alarm Time",
          key: "warnCreatime",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h("span", this.timeString(params.row.warnCreatime)),
            ]);
          },
        },
        {
          title: "Processing Status",
          key: "warnOperstate",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h(
                "span",
                params.row.warnOperstate == "0" ? "Untreated" : "Processed"
              ),
            ]);
          },
        },
        {
          title: "Processing Time",
          key: "warnHandletime",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h(
                "span",
                this.timeString(params.row.warnHandletime)
                // new Date(params.row.warnHandletime).toLocaleDateString()
              ),
            ]);
          },
        },
        {
          title: "Operation",
          slot: "action",
          align: "center",
        },
      ],
      editNodes: false,
      alarmFrom: {
        editAlarmNodes: "",
        alarmState: "",
      },
      disposeState: [
        {
          value: "1",
          label: this.language=="Chinese"?"已处理":"Processed",
        },
        {
          value: "0",
          label: this.language=="Chinese"?"未处理":"Untreated",
        },
      ],
      editAlarmUrl: "",
      editId: "",
      delectAlarmUrl: "",
      currentPage:this.index,
    };
  },
  created() {
    this.updateUrl();
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 删除告警信息
    delectAlarm(row, index) {
      this.$confirm(this.language=="Chinese"?"是否确定删除":"Are you sure to delete")
        .then(() => {
          this.$axios({
            url: this.apiUrl + this.delectAlarmUrl,
            method: "post",
            params: {
              id: row.id,
            },
          })
            .then((res) => {
              // console.log(res);
              if (res.data.status == 200) {
                this.$message.success(res.data.msg);
                this.$emit("update-alarm");
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => err);
        })
        .catch(() => {
          this.$message.info(this.language=="Chinese"?"取消删除":"Cancel Deletion");
        });
      // this.$alert("暂无删除功能")
    },
    // 获取告警设备中的各个设备的删除接口和修改接口
    updateUrl() {
      if (this.$route.path == "/inverter-alarm") {
        this.editAlarmUrl = "equWarnInverter/updateEquWarnInverter"; // 逆变器
        this.delectAlarmUrl = "";
      } else if (this.$route.path == "/pv-alarm") {
        this.editAlarmUrl = "equWarnPv/updateEquWarnPv"; // PV
        this.delectAlarmUrl = "";
      } else if (this.$route.path == "/MPPT-all-in-one-alarm") {
        this.editAlarmUrl = "equWarnMppt/updateEquWarnMppt"; // MPPT
        this.delectAlarmUrl = "equWarnMppt/delEquWarnMpptById";
      }
    },
    // 设置默认值（修改）
    editAlarm(row, index) {
      this.editNodes = true;
      this.alarmFrom.editAlarmNodes = row.warnNotes;
      this.alarmFrom.alarmState = row.warnOperstate;
      this.editId = row.id;
      // this.updateUrl();
    },
    // 提交修改
    submitEdit() {
      this.$axios({
        url: this.apiUrl + this.editAlarmUrl,
        method: "post",
        params: {
          id: this.editId,
          warnNotes: this.alarmFrom.editAlarmNodes?this.alarmFrom.editAlarmNodes:"",
          warnOperstate: this.alarmFrom.alarmState,
        },
      })
        .then((res) => {
          if (res.data.status == 200) {
            // console.log(res);
            this.$emit("update-alarm");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消修改
    cancelEdit() {
      this.editAlarmNodes = "";
    },
    // 所选设备
    selectionList(selection) {
      this.$emit("selection-alarm", selection);
    },
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>
<style lang="less">
.ivu-table-border td,
.ivu-table-border th {
  text-align: center;
}
</style>