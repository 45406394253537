<template>
  <!-- 初级管理员、经销商、终端管理列表 -->
  <div>
    <Table
      border
      :loading="loadingShow"
      :columns="institutionTitle"
      :data="institutionList"
      highlight-rows
      :no-data-text="'No Data'"
      @on-selection-change="selectInstitutions"
    >
      <template slot-scope="{ row, index }" slot="institution">
        <Button
          type="primary"
          size="small"
          style="margin-left: 5px"
          @click="dealerManagement(row, index)"
          v-if="type == '1002'"
          >Dealer</Button
        >
        <Button
          type="primary"
          size="small"
          style="margin-left: 5px"
          @click="terminalManage(row, index)"
          v-if="type == '1002' || type == '1003'"
          >Terminal</Button
        >
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="primary"
          size="small"
          style="margin-right: 5px"
          @click="editInstitutionInfo(row, index)"
          >Edit</Button
        >
        <Button type="error" size="small" @click="remove(row, index)"
          >Delete</Button
        >
      </template>
    </Table>
    <!-- <Page
      ref="page"
      style="margin-top: 10px"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    ><slot>
      {{count}} in total
    </slot>
    </Page> -->
    <div style="display:flex;margin-top: 10px;align-content: center">
    <Page
      ref="page"
      :total="count"
      :current.sync="$parent.index"
      show-total
    ><slot>
      {{count}} in total
    </slot>
    </Page>
     <el-pagination layout="slot">
      <p class="ivu-select" style="font-weight:500">
        <Select v-model="$parent.pageLimit" style="width:auto;">
          <Option :value="10">10 pieces/page</Option>
          <Option :value="20">20 pieces/page</Option>
          <Option :value="30">30 pieces/page</Option>
          <Option :value="40">40 pieces/page</Option>
        </Select>
      </p>
    </el-pagination>
     <el-pagination layout="slot">
      <p class="ivu-page-options-elevator" style="font-weight:500">
        Jump to 
        <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
        pages
      </p>
    </el-pagination>
    </div>
    <Modal
      v-model="showEditInfo"
      title="Edit Agency Information"
      ok-text="Confirm"
      cancel-text="Cancel"
      @on-ok="editSubmit"
      @on-cancel="editCancel"
    >
      <Form :model="editFrom" :label-width="80" label-colon>
        <FormItem label="Agency Type">
          <Input v-model.trim="editFrom.institutionType" disabled></Input>
        </FormItem>
        <FormItem
          label="Subordinate to the Institution"
          v-if="institutionManufacturers != ''"
        >
          <Input
            v-model.trim="editFrom.institutionManufacturers"
            disabled
          ></Input>
        </FormItem>
        <FormItem label="Agency Name">
          <Input v-model.trim="editFrom.institutionName"></Input>
        </FormItem>
        <FormItem label="Contact">
          <Input v-model.trim="editFrom.name"></Input>
        </FormItem>
        <FormItem label="Contact Phone">
          <Input
            v-model.trim="editFrom.phone"
            :maxlength="11"
            type="tel"
          ></Input>
        </FormItem>
        <FormItem label="Email">
          <Input v-model.trim="editFrom.email" type="email"></Input>
        </FormItem>
        <FormItem label="Remarks">
          <Input v-model.trim="editFrom.note" type="textarea"></Input>
        </FormItem>
        <FormItem label="Address">
          <Input v-model.trim="editFrom.address" type="textarea"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "institutionList",
    "count",
    "index",
    "pageLimit",
    "institutionsType",
    "editUrl",
    "loadingShow",
    "institutionManufacturers",
    "institutionName",
    "type",
  ],
  data() {
    return {
      institutionTitle: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "Institution Name",
          key: "mechanismName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismName)),
            ]);
          },
        },
        {
          title: "Contact",
          key: "mechanismContacts",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismContacts)),
            ]);
          },
        },
        {
          title: "Contact Number",
          key: "mechanismPhone",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismPhone)),
            ]);
          },
        },
        {
          title: "Email",
          key: "mechanismEmail",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismEmail)),
            ]);
          },
        },
        {
          title: "Remarks",
          key: "mechanismNotes",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismNotes)),
            ]);
          },
        },
        {
          title: "Address",
          key: "address",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.address))]);
          },
        },
        {
          title: "Operate",
          slot: "action",
          align: "center",
        },
      ],
      showEditInfo: false,
      institutionId: null,
      institutionParent: null,
      editFrom: {
        institutionType: this.institutionsType,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        institutionName: "",
        name: "",
        phone: "",
        email: "",
        note: "",
        address: "",
      },
      currentPage:this.index,
    };
  },
  created() {
    // 判断当前页面是否属于终端页面
    if (this.type != "1004" && this.type != "1005") {
      this.institutionTitle.splice(-1, 0, {
        title: "Subordinate Institution",
        slot: "institution",
        align: "center",
        width: 210,
      });
    }
    if (this.type != "1002") {
      this.institutionTitle.splice(7, 0, {
        title: "Directly Under Institution",
        key: "mechanismParentName",
        align: "center",
        render: (h, params) => {
          return h("div", [
            h("span", this.judgeValue(params.row.mechanismParentName)),
          ]);
        },
      });
    }
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 编辑终端
    editInstitutionInfo(row, index) {
      this.showEditInfo = true;
      this.institutionId = row.id;
      this.institutionParent = row.mechanismParentFk;
      this.editFrom = {
        institutionType:
          row.mechanismType == "1002"
            ? "Junior Administrator"
            : row.mechanismType == "1003"
            ? "Dealer"
            : "Terminal User",
        institutionName: row.mechanismName,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        name: row.mechanismContacts,
        phone: row.mechanismPhone,
        email: row.mechanismEmail,
        note: row.mechanismNotes,
        address: row.address,
      };
    },
    // 弹窗保存
    editSubmit() {
      if (
        this.editFrom.institutionName == "" ||
        this.editFrom.name == "" ||
        this.editFrom.phone == ""
      )
        return this.$alert(
          "Institutions name, contact person and contact number cannot be blank"
        );
      if (
        this.editFrom.phone.length != 11 ||
        this.editFrom.phone.search(/[^\d]/g) != -1
      ) {
        this.addCancel();
        return this.$alert(
          "The entered mobile phone number does not meet the specification"
        );
      }

      if (this.editFrom.email != "") {
        var re =
          /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if (!re.test(this.editFrom.email)) {
          return this.$alert("The mailbox format entered is incorrect");
        }
      }
      let param = {};
      if (this.institutionManufacturers != "") {
        if (this.institutionsType == "Junior administrator") {
          if (this.type == "1002") {
            param = {
              mechanismAddress: this.editFrom.address,
              mechanismSubordinateAdminFk: this.institutionManufacturers.id,
            };
          } else {
            param = {
              mechanismAddress: this.editFrom.address,
            };
          }
        } else {
          param = {
            mechanismAddress: this.editFrom.address,
            mechanismSubordinateDistrFk: this.institutionManufacturers.id,
          };
        }
      } else {
        param = {
          address: this.editFrom.address,
          mechanismParentFk: this.institutionParent,
          // mechanismParentFk: this.$root.userInfo.userMechanismFk,
        };
      }
      this.$axios({
        url: this.apiUrl + this.editUrl,
        method: "post",
        params: {
          id: this.institutionId,
          mechanismName: this.editFrom.institutionName,
          mechanismType: this.type,
          // mechanismParentFk: this.institutionParent,
          mechanismContacts: this.editFrom.name,
          mechanismPhone: this.editFrom.phone,
          mechanismEmail: this.editFrom.email,
          mechanismNotes: this.editFrom.note,
          address: this.editFrom.address,
          ...param,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.$emit("updata-list");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 弹窗取消
    editCancel() {
      this.editFrom = {
        institutionType: this.institutionsType,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        institutionName: "",
        name: "",
        phone: "",
        email: "",
        note: "",
        address: "",
      };
    },
    // 终端管理
    terminalManage(row, index) {
      this.$parent.index = 1;
      this.$parent.pageLimit = 10;
      this.$emit("institutions-terminal", row);
    },
    // 经销商管理
    dealerManagement(row, index) {
      this.$parent.index = 1;
      this.$parent.pageLimit = 10;
      this.$emit("institutions-dealer", row);
    },
    // 删除
    remove(row, index) {
      this.$confirm("Are you sure to delete")
        .then(() => {
          this.$emit("delete-list", row);
        })
        .catch(() => {
          this.$message.info("Cancel Delete");
        });
    },
    // 多选
    selectInstitutions(select) {
      this.$emit("select-list", select);
    },
  },
  watch: {
    institutionManufacturers(newValue, oldValue) {
      if (newValue != "") {
        this.editFrom.institutionManufacturers = newValue.mechanismName;
      }
    },
    type(newValue, oldValue) {
      if (newValue != "1004") {
        if (
          this.institutionTitle
            .map((item) => item.title)
            .includes("Subordinate Institution")
        )
          return;
        this.institutionTitle.splice(-1, 0, {
          title: "Subordinate Institution",
          slot: "institution",
          align: "center",
          width: 210,
        });
      } else {
        if (this.institutionName != "终端用户") {
          this.institutionTitle.splice(-2, 1);
        }
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>