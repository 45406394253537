import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPash = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return originalPash.call(this,location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    redirect: '/login' // 数据统计
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/equipment-overview',
    children: [
      {
        path: "/equipment-overview",
        name: "EquipmentOverview",
        meta: {
          title: '设备概述',
          titleEnglish:'Equipment Overview'
        },
        component: () => import("../views/EquipmentOverview.vue")
      },{
        path: "/apollo-operation",
        name: "ApolloOperation",
        meta: {
          title: 'Apollo运行管理',
          titleEnglish:'Apollo Operation'
        },
        component: () => import("../views/Apollo-equipment/ApolloOperation.vue")
      },{
        path: "/apollo-alarm",
        name: "ApolloAlarm",
        meta: {
          title: 'Apollo告警管理',
          titleEnglish:'Apollo Alarm'
        },
        component: () => import("../views/Apollo-equipment/ApolloAlarm.vue")
      },{
        path: "/apollo-distribution",
        name: "ApolloDistribution",
        meta: {
          title: 'Apollo分配管理',
          titleEnglish:'Apollo Distribution'
        },
        component: () => import("../views/Apollo-equipment/ApolloDistribution.vue")
      },{
        path: "/apollo-registration",
        name: "ApolloRegistration",
        meta: {
          title: 'Apollo注册管理',
          titleEnglish:'Apollo Registration'
        },
        component: () => import("../views/Apollo-equipment/ApolloRegistration.vue")
      },{
        path: "/apollo-instruction",
        name: "ApolloInstruction",
        meta: {
          title: 'Apollo指令监控',
          titleEnglish:'Apollo Instruction'
        },
        component: () => import("../views/Apollo-equipment/ApolloInstruction.vue")
      },{
        path: "/user-control",
        name: "UserControl",
        meta: {
          title: '用户管理',
          titleEnglish:'User Management'
        },
        component: () => import("../views/system/UserControl.vue")
      },{
        path: "/third-party-users",
        name: "ThirdPartyUsers",
        meta: {
          title: '第三方用户管理',
          titleEnglish:'Third Party Users'
        },
        component: () => import("../views/system/ThirdPartyUsers.vue")
      },{
        path: "/institutions",
        name: "Institutions",
        meta: {
          title: '机构管理',
          titleEnglish:'Institutions'
        },
        component: () => import("../views/system/Institutions.vue")
      },{
        path: "/junior-administrator",
        name: "JuniorAdministrator",
        meta: {
          title: '初级管理员机构管理',
          titleEnglish:'Junior Administrator'
        },
        component: () => import("../views/system/JuniorAdministrator.vue")
      },{
        path: "/dealer",
        name: "Dealer",
        meta: {
          title: '经销商机构管理',
          titleEnglish:'Dealer Management'
        },
        component: () => import("../views/system/Dealer.vue")
      },{
        path: "/terminal",
        name: "Terminal",
        meta: {
          title: '终端机构管理',
          titleEnglish:'Terminal Management'
        },
        component: () => import("../views/system/Terminal.vue")
      },{
        path: "/role",
        name: "Role",
        meta: {
          title: '角色管理',
          titleEnglish:'Role Management'
        },
        component: () => import("../views/system/Role.vue")
      },{
        path: "/type-code",
        name: "TpyeCode",
        meta: {
          title: '类型码',
          titleEnglish:'Tpye Code'
        },
        component: () => import("../views/system/TpyeCode.vue")
      },{
        path: "/basic-code",
        name: "BasicCode",
        meta: {
          title: '基础码',
          titleEnglish:'Basic Code'
        },
        component: () => import("../views/system/BasicCode.vue")
      },{
        path: "/jurisdiction",
        name: "Jurisdiction",
        meta: {
          title: '权限管理',
          titleEnglish:'Rights Management'
        },
        component: () => import("../views/system/Jurisdiction.vue")
      },{
        path: "/system-menu",
        name: "SystemMenu",
        meta: {
          title: '系统菜单',
          titleEnglish:'System Menu'
        },
        component: () => import("../views/system/SystemMenu.vue")
      },{
        path: "/file-upload",
        name: "FileUpload",
        meta: {
          title: 'WiFi模组文件上传',
          titleEnglish:'WiFi Module File Upload'
        },
        component: () => import("../views/system/FileUpload.vue")
      },{
        path: "/4G-file",
        name: "4GFile",
        meta: {
          title: '4G模块文件上传',
          titleEnglish:'4G Module File Upload'
        },
        component: () => import("../views/system/4GModuleFile.vue")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    // redirect:"/index/login",
    meta: {
      title: '登录',
      titleEnglish:'Login'
    },
    component: () => import( /* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/statics",
    name: "Statics",
    // redirect:"/index/login",
    meta: {
      title: '大屏显示',
      titleEnglish:'Statics'
    },
    component: () => import( /* webpackChunkName: "login" */ "../views/Statics.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
//   mode: 'hash',
  base: process.env.BASE_URL,
  // base:"/dist",
  routes
})

export default router
