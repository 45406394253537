<template>
  <div class="distribution-device">
    <!-- 分配管理列表 -->
    <Table
      border
      highlight-row
      :loading="loadingShow"
      :columns="language=='Chinese'?listTitle:listTitleEnglish"
      :data="distributionList"
      :stripe="true"
      :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
    >
      <template slot-scope="{ row, index }" slot="action">
        <el-link
          style="margin-right: 10px"
          v-if="$root.userInfo.organizationType == '1000'"
          type="primary"
          @click="manufacturerDistribution(row, index)"
          >{{language=='Chinese'?"厂家分配":"Manufacturer Distribution"}}</el-link
        >
        <el-link
          style="margin-right: 10px"
          v-if="
            $root.userInfo.organizationType == '1000' ||
            $root.userInfo.organizationType == '1001'
          "
          type="primary"
          @click="juniorDistribution(row, index)"
          >{{language=='Chinese'?"初级管理员机构分配":"Junior Administrator Assignment"}}</el-link
        >
        <el-link
          style="margin-right: 10px"
          v-if="
            $root.userInfo.organizationType == '1000' ||
            $root.userInfo.organizationType == '1001' ||
            $root.userInfo.organizationType == '1002'
          "
          type="primary"
          @click="dealersDistribution(row, index)"
          >{{language=='Chinese'?"经销商机构分配":"Distributor Allocation"}}</el-link
        >
        <el-link
          style="margin-right: 10px"
          v-if="
            $root.userInfo.organizationType != '1003' ||
            $root.userInfo.organizationType != '1004'
          "
          type="primary"
          @click="terminalDistribution(row, index)"
          >{{language=='Chinese'?"终端机构分配":"Terminal Allocation"}}</el-link
        >
        <el-link
          type="primary"
          v-if="
            $root.userInfo.organizationType == '1000' ||
            $root.userInfo.organizationType == '1001'
          "
          @click="countriesHandler(row, index)"
          >{{language=='Chinese'?"分配国家":"Allocation country"}}</el-link
        >
      </template>
    </Table>
    <div style="display:flex;margin-top: 10px;align-content: center">
    <template v-if="language=='Chinese'">
        <Page
        ref="page"
        style="margin-top: 10px"
        :total="count"
        :current.sync="$parent.index"
        show-total
        show-elevator
        show-sizer
        @on-page-size-change="$parent.pageLimit = $event"
        />
    </template>
    <template v-else>
        <Page
        ref="page"
        :total="count"
        :current.sync="$parent.index"
        show-total
        ><slot>
        {{count}} in total
        </slot>
        </Page>
        <el-pagination layout="slot">
        <p class="ivu-select" style="font-weight:500">
            <Select v-model="$parent.pageLimit" style="width:auto;">
            <Option :value="10">10 pieces/page</Option>
            <Option :value="20">20 pieces/page</Option>
            <Option :value="30">30 pieces/page</Option>
            <Option :value="40">40 pieces/page</Option>
            </Select>
        </p>
        </el-pagination>
        <el-pagination layout="slot">
        <p class="ivu-page-options-elevator" style="font-weight:500">
            Jump to 
            <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
            pages
        </p>
        </el-pagination>
    </template>
    </div>
    <Modal v-model="factoryShow" :title="factoryTitle" @on-ok="asyncOK">
      {{ factoryTitle }}：
      <Select v-model="factory" style="width: 200px">
        <Option value="">{{language=='Chinese'?"不分配":"Not assigned"}}</Option>
        <Option
          v-for="item in factoryList"
          :value="item.id"
          :key="item.id"
          :disabled="item.id == selectId"
          >{{ item.mechanismName }}</Option
        >
      </Select>
    </Modal>
    <Modal v-model="countriesShow"
     :title="language=='Chinese'?'分配国家':'Allocation country'"
      :ok-text="language=='Chinese'?'确认':'Confirm'"
      :cancel-text="language=='Chinese'?'取消':'Cancel'"
     @on-ok="submitCountries"
     >
      {{language=='Chinese'?'国家':'Countries'}}：
      <el-cascader
        v-model="countries"
        :options="$countryList"
        :show-all-levels="false"
        :props="countryProps"
        filterable
      ></el-cascader>
      <span style="color: red; font-size: 13px"
        >&nbsp;&nbsp;&nbsp; * {{language=='Chinese'?'可手动输入国家名称进行检索':'Country name can be manually entered for retrieval'}}</span
      >
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "distributionList",
    "count",
    "index",
    "pageLimit",
    "factorUrl",
    "loadingShow",
    "language"
  ],
  data() {
    return {
      listTitle: [
        {
          title: "模块ID",
          key: "wifiId",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.wifiId))]);
          },
        },
        {
          title: "厂家名称",
          key: "equipmentFactoryName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentFactoryName)),
            ]);
          },
        },
        {
          title: "所属国家",
          key: "country",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.country))]);
          },
        },
        {
          title: "注册时间",
          key: "equipmentCreatime",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.timeString(params.row.equipmentCreatime)),
            ]);
          },
        },
        {
          title: "设备通电状态",
          key: "equipmentConnectstateName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentConnectstateName)),
            ]);
          },
        },
        {
          title: "初级管理员机构分配",
          key: "equipmentPrimaryName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentPrimaryName)),
            ]);
          },
        },
        {
          title: "经销商机构分配",
          key: "equipmentDealerName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentDealerName)),
            ]);
          },
        },
        {
          title: "终端分配",
          key: "equipmentTerminalName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentTerminalName)),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          // width:'400'
        },
      ],
       listTitleEnglish: [
        {
          title: "ModuleID",
          key: "wifiId",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.wifiId))]);
          },
        },
        {
          title: "Name Manufacturer",
          key: "equipmentFactoryName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentFactoryName)),
            ]);
          },
        },
        {
          title: "Country",
          key: "country",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", 
            (()=>{
              let code = params.row.countryCode;
              let text;
              this.$countryList.forEach(item=>{
                item.children.forEach(items => {
                  if(items.areaCode == code){
                    text = items.nameEnglish
                  }
                })
              })
              return text
            })()
            )]);
          },
        },
        {
          title: "Registration Time",
          key: "equipmentCreatime",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.timeString(params.row.equipmentCreatime)),
            ]);
          },
        },
        {
          title: "Equipment Power On Status",
          key: "equipmentConnectstateName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                this.judgeValue(params.row.equipmentConnectstateName) == "离线"
                  ? "Offline"
                  : "Online"
              ),
            ]);
          },
        },
        {
          title: "Junior Administrator Assignment",
          key: "equipmentPrimaryName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentPrimaryName)),
            ]);
          },
        },
        {
          title: "Distributor Allocation",
          key: "equipmentDealerName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentDealerName)),
            ]);
          },
        },
        {
          title: "Terminal Assignment",
          key: "equipmentTerminalName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.equipmentTerminalName)),
            ]);
          },
        },
        {
          title: "Operation",
          slot: "action",
          align: "center",
          // width:'400'
        },
      ],
      factoryList: [],
      factoryShow: false,
      factoryTitle: "",
      factory: "",
      factoryId: "",
      selectId: "",
      countries: "",
      countriesShow: false,
      countryProps: {
        value: "areaCode",
        label:this.language=="Chinese"? "name":"nameEnglish",
      },
      wifiId: "",
      currentPage:this.index,
    };
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 厂家分配
    manufacturerDistribution(row, index) {
      this.factoryList = [];
      this.factoryId = row.id;
      this.factoryShow = true;
      this.factoryTitle = "厂家分配";
      this.selectId = row.equipmentFactoryFk;
      this.factory = Number(this.selectId);
      this.wifiId = row.wifiId;
      // console.log(row);
      let param = {
        mechanismType: "1001",
      };
      this.getFactory(param);
    },
    // 初级管理员分配
    juniorDistribution(row, index) {
      this.factoryList = [];
      this.factoryId = row.id;
      this.factoryShow = true;
      this.factoryTitle = this.language=='Chinese'?"初级管理员分配":"Junior Administrator Assignment";
      this.selectId = row.equipmentPrimaryFk;
      this.factory = Number(this.selectId);
      this.wifiId = row.wifiId;
      // console.log(row);
      let param = {
        id: row.id,
        mechanismType: "1002",
        mechanismParentFk: row.equipmentFactoryFk,
      };
      this.getFactory(param);
    },
    // 经销商机构分配
    dealersDistribution(row, index) {
      this.factoryList = [];
      this.factoryId = row.id;
      this.factoryShow = true;
      this.factoryTitle = this.language=='Chinese'?"经销商机构分配":"Distributor Allocation";
      this.selectId = row.equipmentDealerFk;
      this.factory = Number(this.selectId);
      this.wifiId = row.wifiId;
      // console.log(row);
      let param = {
        id: row.id,
        mechanismType: "1003",
        mechanismParentFk:
          row.equipmentPrimaryFk!=null&&row.equipmentPrimaryFk != ""
            ? row.equipmentPrimaryFk
            : row.equipmentFactoryFk,
      };
      this.getFactory(param);
    },
    // 终端机构分配
    terminalDistribution(row, index) {
      this.factoryList = [];
      this.factoryId = row.id;
      this.factoryShow = true;
      this.factoryTitle = this.language=='Chinese'?"终端机构分配":"Terminal Allocation";
      this.selectId = row.equipmentTerminalFk;
      this.factory = Number(this.selectId);
      this.wifiId = row.wifiId;
      // console.log(row);
      let param = {
        id: row.id,
        mechanismType: "1004",
        mechanismParentFk:
          row.equipmentDealerFk!=null && row.equipmentDealerFk != ""
            ? row.equipmentDealerFk
            : row.equipmentPrimaryFk!=null && row.equipmentPrimaryFk != ""
            ? row.equipmentPrimaryFk
            : row.equipmentFactoryFk,
      };
      // console.log(param);
      this.getFactory(param);
    },
    // 分配国家
    countriesHandler(row, index) {
      this.countries = row.countryCode;
      this.countriesShow = true;
      this.factoryId = row.id;
      this.wifiId = row.wifiId;
    },
    // 获取厂家分配列表
    getFactory(param) {
      this.$axios({
        url: this.apiUrl + "mechanism/mechanismChildrenByEquipId",
        method: "get",
        params: {
          ...param,
          type: this.$root.userInfo.organizationType,
          organizationId: this.$root.userInfo.organizationId,
        },
      })
        .then((res) => {
          this.factoryList = res.data.data;
        })
        .catch((err) => err);
    },
    // 提交分配数据
    asyncOK() {
      if (this.selectId == this.factory) {
        return this.$alert(this.language=="Chinese"?"不允许重复分配":"Double allocation is not allowed");
      }
      let param = {};
      if (this.factoryTitle == "厂家分配"||this.factoryTitle == "Manufacturer Distribution") {
        param = {
          mechanismType: "1001",
        };
      } else if (this.factoryTitle == "初级管理员分配"||this.factoryTitle == "Junior Administrator Assignment") {
        param = {
          mechanismType: "1002",
        };
      } else if (this.factoryTitle == "经销商机构分配"||this.factoryTitle == "Distributor Allocation") {
        param = {
          mechanismType: "1003",
        };
      } else if (this.factoryTitle == "终端机构分配"||this.factoryTitle == "Terminal Allocation") {
        param = {
          mechanismType: "1004",
        };
      }
      this.$axios({
        url: this.apiUrl + this.factorUrl,
        method: "post",
        params: {
          id: this.factory,
          Eqid: this.factoryId,
          WifiID: this.wifiId,
          ...param,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.$emit("update-list");
            this.factory = "";
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 提交国家（IOS）
    submitCountries() {
      if (this.countries == "") return this.$alert(this.language=="Chinese"?"请选择国家":"Please select country");
      this.$axios({
        url: this.apiUrl + "equipmentApollo/SetCountry",
        method: "post",
        params: {
          countyCode: this.countries[1],
          wifiId: this.wifiId,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.$emit("update-list");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>