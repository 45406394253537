<template>
  <div class="role-list">
    <Table
      border
      :loading="loadingShow"
      :columns="roleTitle"
      :data="roleList"
      :stripe="true"
      highlight-row
      :no-data-text="'No Data'"
      @on-selection-change="selectRole"
    >
      <template slot-scope="{ row, index }" slot="action">
        <Button type="text" size="small" @click="setPermissions(row, index)"
          >Set Permissions</Button
        >
        <Button type="text" size="small" @click="editRole(row, index)"
          >Edit</Button
        >
        <Button type="text" size="small" @click="delectRole(row, index)"
          >Delete</Button
        >
      </template>
    </Table>
    <!-- <Page
      ref="page"
      style="margin-top:10px"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    ><slot>
      {{count}} in total
    </slot>
    </Page> -->
    <div style="display:flex;margin-top: 10px;align-content: center">
    <Page
      ref="page"
      :total="count"
      :current.sync="$parent.index"
      show-total
    ><slot>
      {{count}} in total
    </slot>
    </Page>
     <el-pagination layout="slot">
      <p class="ivu-select" style="font-weight:500">
        <Select v-model="$parent.pageLimit" style="width:auto;">
          <Option :value="10">10 pieces/page</Option>
          <Option :value="20">20 pieces/page</Option>
          <Option :value="30">30 pieces/page</Option>
          <Option :value="40">40 pieces/page</Option>
        </Select>
      </p>
    </el-pagination>
     <el-pagination layout="slot">
      <p class="ivu-page-options-elevator" style="font-weight:500">
        Jump to 
        <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
        pages
      </p>
    </el-pagination>
    </div>
    <Modal
      v-model="setShow"
      title="Editing Role Permissions"
      ok-text="Confirm"
      cancel-text="Cancel"
      @on-ok="ok"
      @on-cancel="cancel"
    >
      <template v-if="!draggable">
        <MenuListEnglish
          :showCheckbox="setShow"
          :roleId="roleId"
          :menuList="menuRoleList"
          :sortShow="draggable"
        />
      </template>
      <template v-else>
        <Form :model="roleFrom" :label-width="80">
          <FormItem label="Role Name">
            <Input v-model.trim="roleFrom.name" style="width: auto"></Input>
          </FormItem>
          <FormItem label="Character Level">
            <Input v-model.trim="roleFrom.rank" style="width: auto"></Input>
          </FormItem>
          <FormItem label="Role Number">
            <Input
              v-model.trim="roleFrom.serialNumber"
              style="width: auto"
            ></Input>
          </FormItem>
          <FormItem label="Instruction Manual">
            <Input
              style="width: auto"
              v-model.trim="roleFrom.remark"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 5 }"
            ></Input>
          </FormItem>
        </Form>
      </template>
    </Modal>
  </div>
</template>

<script>
import MenuListEnglish from "./system-menu/MenuListEnglish.vue";
export default {
  props: ["roleList", "count", "index", "pageLimit", "loadingShow"],
  components: {
    MenuListEnglish,
  },
  data() {
    return {
      setShow: false,
      roleId: "",
      roleTitle: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "Role Name",
          key: "roleEnglishname",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.roleName))]);
          },
        },
        {
          title: "Character Level",
          key: "remake",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.remake))]);
          },
        },
        {
          title: "Role Description",
          key: "remake",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.remake))]);
          },
        },
        {
          title: "Operate",
          slot: "action",
          align: "center",
        },
      ],
      menuRoleList: [],
      roleFrom: {
        name: "",
        rank: "",
        serialNumber: "",
        remark: "",
      },
      draggable: false,
      currentPage:this.index,
    };
  },
  created() {
    // 获取菜单列表
    this.$axios
      .get(this.apiUrl + "systemMenu/FindAllSystemMenuRoleList")
      .then((res) => {
        // console.log(res);
        this.menuRoleList = res.data.data;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 编辑角色权限
    setPermissions(row, index) {
      this.setShow = true;
      this.roleId = row.id;
    },
    // 修改角色
    editRole(row, index) {
      this.draggable = true;
      this.roleFrom = {
        name: row.name,
        rank: row.rank,
        serialNumber: row.number,
        remark: row.remark,
      };
    },
    // 删除角色
    delectRole(row, index) {
      this.$confirm("Are you sure to delete").then(()=>{
        this.$emit("delete-role",row);
      }).catch(()=>{
        this.$message("Cancel Delete")
      })
    },
    // 编辑修改
    ok() {
      this.$Message.info("Clicked ok");
    },
    // 取消修改
    cancel() {
      this.$Message.info("Clicked cancel");
    },
    // 设置多选角色列表
    selectRole(select) {
      this.$emit("select-list", select);
    },
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>