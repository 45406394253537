<template>
  <div class="instruction-device">
    <Table
      border
      stripe
      :loading="loadingShow"
      :columns="language=='Chinese'?listTitle:listTitleEnglish"
      :data="instructionList"
      :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
      highlight-row
    >
    </Table>
    <div style="display:flex;margin-top: 10px;align-content: center">
        <template v-if="language=='Chinese'">
            <Page
            ref="page"
            style="margin-top: 10px"
            :total="count"
            :current.sync="$parent.index"
            show-total
            show-elevator
            show-sizer
            @on-page-size-change="$parent.pageLimit = $event"
            />
        </template>
        <template v-else>
            <Page
            ref="page"
            :total="count"
            :current.sync="$parent.index"
            show-total
            ><slot>
            {{count}} in total
            </slot>
            </Page>
            <el-pagination layout="slot">
            <p class="ivu-select" style="font-weight:500">
                <Select v-model="$parent.pageLimit" style="width:auto;">
                <Option :value="10">10 pieces/page</Option>
                <Option :value="20">20 pieces/page</Option>
                <Option :value="30">30 pieces/page</Option>
                <Option :value="40">40 pieces/page</Option>
                </Select>
            </p>
            </el-pagination>
            <el-pagination layout="slot">
            <p class="ivu-page-options-elevator" style="font-weight:500">
                Jump to 
                <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
                pages
            </p>
            </el-pagination>
        </template>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "instructionList",
    "count",
    "index",
    "pageLimit",
    // "compileUrl",
    "loadingShow",
    "language"
  ],
  data() {
    return {
      listTitle: [
        {
          title: "模块ID",
          key: "instructWifiid",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructWifiid)),
            ]);
          },
        },
        {
          title: "通讯IP",
          key: "instructIp",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructIp)),
            ]);
          },
        },
        {
          title: "调用方法",
          key: "instructCallmethod",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructCallmethod)),
            ]);
          },
        },
        {
          title: "指令内容",
          key: "instructData",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructData)),
            ]);
          },
        },
        {
          title: "上传时间",
          key: "instructCreatime",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h("span", this.timeString(params.row.instructCreatime)),
            ]);
          },
        },
        {
          title: "加密方式",
          key: "instructType",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructType)),
            ]);
          },
        },
      ],
      listTitleEnglish: [
        {
          title: "Module ID",
          key: "instructWifiid",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructWifiid)),
            ]);
          },
        },
        {
          title: "Communication IP",
          key: "instructIp",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructIp)),
            ]);
          },
        },
        {
          title: "Call Method",
          key: "instructCallmethod",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructCallmethod)),
            ]);
          },
        },
        {
          title: "Instruction Content",
          key: "instructData",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructData)),
            ]);
          },
        },
        {
          title: "Upload Time",
          key: "instructCreatime",
          align:"center",
          render: (h, params) => {
            // console.log(params);
            return h("div", [
              h("span", this.timeString(params.row.instructCreatime)),
            ]);
          },
        },
        {
          title: "Encryption mode",
          key: "instructType",
          align:"center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.instructType)),
            ]);
          },
        },
      ],
      currentPage:this.index,
    };
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
  },
  watch: {
    pageLimit(newValue,oldValue){
      if(newValue!=this.$refs.page.currentPageSize){
        this.$refs.page.currentPageSize = newValue
      }
    }
  },
};
</script>

<style lang="less" scoped>
</style>
<style lang="less">
.ivu-table-overflowX {
  overflow-x: hidden;
}
</style>