<template>
  <!-- 用户管理 -->
  <div class="user-control" v-if="language == 'Chinese'">
    <SearchUser
      @search-user="newDate($event)"
      @handle-delect="handleDelete(DeleteAllUser)"
      @update-list="newDate"
      :index="index"
      :pageLimit="pageLimit"
    />
    <Table
      border
      :stripe="false"
      :loading="loadingShow"
      :columns="userTitle"
      :data="userDate"
      @on-selection-change="handleDeleteAllUser"
    >
      <template slot-scope="{ row, index }" slot="action">
        <el-button
          v-if="$root.userInfo"
          :disabled="
            $root.userInfo.organizationType != '1000' &&
            row.mechanismType == '1000'
          "
          size="mini"
          @click="handleEdit(row, index)"
          >编辑</el-button
        >
        <el-button
          v-if="$root.userInfo"
          :disabled="
            $root.userInfo.organizationType != '1000' &&
            row.mechanismType == '1000'
          "
          size="mini"
          type="danger"
          @click="handleDelete(row)"
          >删除</el-button
        >
      </template>
    </Table>
    <Page
      ref="page"
      :total="count"
      :current.sync="index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="pageLimit = $event"
    />
    <UserBox
      :dialogFormVisible="dialogFormVisible"
      :editUser="editUser"
      userInfoTitle="undate"
      @update-list="newDate"
      >编辑用户信息</UserBox
    >
  </div>
  <div class="user-control" v-else>
    <SearchUserEnglish
      @search-user="newDate($event)"
      @handle-delect="handleDelete(DeleteAllUser)"
      @update-list="newDate"
      :index="index"
      :pageLimit="pageLimit"
    />
    <Table
      border
      :stripe="false"
      :loading="loadingShow"
      :columns="userTitleEnglish"
      :data="userDate"
      :no-data-text="'No Data'"
      @on-selection-change="handleDeleteAllUser"
    >
      <template slot-scope="{ row, index }" slot="action">
        <el-button
          v-if="$root.userInfo"
          :disabled="
            $root.userInfo.organizationType != '1000' &&
            row.mechanismType == '1000'
          "
          size="mini"
          @click="handleEdit(row, index)"
          >Edit</el-button
        >
        <el-button
          v-if="$root.userInfo"
          :disabled="
            $root.userInfo.organizationType != '1000' &&
            row.mechanismType == '1000'
          "
          size="mini"
          type="danger"
          @click="handleDelete(row)"
          >Delete</el-button
        >
      </template>
    </Table>
    <!-- <Page
      ref="page"
      :total="count"
      :current.sync="index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="pageLimit = $event"
    ><slot>
      {{count}} in total
    </slot>
    </Page> -->
    <div style="display:flex;margin-top: 10px;align-content: center">
    <Page
      ref="page"
      :total="count"
      :current.sync="index"
      show-total
    ><slot>
      {{count}} in total
    </slot>
    </Page>
     <el-pagination layout="slot">
      <p class="ivu-select" style="font-weight:500">
        <Select v-model="pageLimit" style="width:auto;">
          <Option :value="10">10 pieces/page</Option>
          <Option :value="20">20 pieces/page</Option>
          <Option :value="30">30 pieces/page</Option>
          <Option :value="40">40 pieces/page</Option>
        </Select>
      </p>
    </el-pagination>
     <el-pagination layout="slot">
      <p class="ivu-page-options-elevator" style="font-weight:500">
        Jump to 
        <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
        pages
      </p>
    </el-pagination>
    </div>
    <UserBoxEnglish
      :dialogFormVisible="dialogFormVisible"
      :editUser="editUser"
      userInfoTitle="undate"
      @update-list="newDate"
      >Edit User Information</UserBoxEnglish
    >
  </div>
</template>

<script>
import SearchUser from "../../components/system/Chinese/SearchUser.vue";
import UserBox from "../../components/system/Chinese/UserBox.vue";
import SearchUserEnglish from "../../components/system/English/SearchUserEnglish.vue";
import UserBoxEnglish from "../../components/system/English/UserBoxEnglish.vue";
export default {
  components: {
    SearchUser,
    UserBox,
    SearchUserEnglish,
    UserBoxEnglish,
  },
  props: ["language"],
  data() {
    return {
      userTitle: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "用户名称",
          key: "userName",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userName))]);
          },
        },
        {
          title: "用户账户",
          key: "userAccount",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.userAccount)),
            ]);
          },
        },
        {
          title: "手机号码",
          key: "userPhone",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userPhone))]);
          },
        },
        {
          title: "角色",
          key: "userRoleFk",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                this.judgeValue(params.row.userRoleFk) == 1
                  ? "超级管理员"
                  : this.judgeValue(params.row.userRoleFk) == 2
                  ? "高级管理员"
                  : this.judgeValue(params.row.userRoleFk) == 3
                  ? "初级管理员"
                  : this.judgeValue(params.row.userRoleFk) == 4
                  ? "经销商"
                  : "终端"
              ),
            ]);
          },
        },
        {
          title: "所属机构",
          key: "userMechanismName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.userMechanismName)),
            ]);
          },
        },
        {
          title: "邮箱地址",
          key: "userEmail",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userEmail))]);
          },
        },
        {
          title: "用户地址",
          key: "userAddress",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userAddress))]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      userTitleEnglish: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "User Name",
          key: "userName",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userName))]);
          },
        },
        {
          title: "User Account",
          key: "userAccount",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.userAccount)),
            ]);
          },
        },
        {
          title: "Phone Number",
          key: "userPhone",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userPhone))]);
          },
        },
        {
          title: "Role",
          key: "userRoleFk",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                this.judgeValue(params.row.userRoleFk) == 1
                  ? "Super administrator"
                  : this.judgeValue(params.row.userRoleFk) == 2
                  ? "Senior administrator"
                  : this.judgeValue(params.row.userRoleFk) == 3
                  ? "Junior administrator"
                  : this.judgeValue(params.row.userRoleFk) == 4
                  ? "Distributor"
                  : "Terminal"
              ),
            ]);
          },
        },
        {
          title: "Affiliation",
          key: "userMechanismName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.userMechanismName)),
            ]);
          },
        },
        {
          title: "Email Address",
          key: "userEmail",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userEmail))]);
          },
        },
        {
          title: "User Address",
          key: "userAddress",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.userAddress))]);
          },
        },
        {
          title: "Operate",
          slot: "action",
          align: "center",
        },
      ],
      dialogFormVisible: false,
      index: 1, // 页码
      userDate: [], // 用户列表
      count: 0,
      pageLimit: 10,
      editUser: null, // 选中的编辑用户信息
      DeleteAllUser: [], // 选中的多条信息
      loadingShow: true,
      disabledShow:false,
      searchParam:{},
      currentPage:1,
    };
  },
  created() {
    this.newDate();
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.index = this.index;
        this.currentPage = this.index;
      }else{
        this.index = this.currentPage
      }
    },
    // 获取编辑用户的列表，并打开编辑页面
    handleEdit(row, index) {
      this.dialogFormVisible = true;
      this.editUser = row;
      // console.log(index, row);
    },
    // 删除用户信息
    handleDelete(row) {
      // console.log( Array.isArray(row));
      // if (confirm("是否确定删除")) {
      // 判断所选信息是否为多条用户信息组成的数组
      this.$confirm("是否确定删除")
        .then(() => {
          if (Array.isArray(row)) {
            row.forEach((item,index) => {
              this.deleteUser(item.id);
              if(index == row.length-1){
                this.$message.success("删除成功")
              }
            });
          } else {
            this.deleteUser(row.id);
          }
        })
        .catch(() => {
          this.$message("取消删除");
        });
    },
    // 根据id删除用户信息
    deleteUser(id) {
      this.$axios({
        url: this.apiUrl + "user/delUserById",
        method: "post",
        params: {
          id: id,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data.status == 200) {
            if(!this.DeleteAllUser.length){
              this.$message.success(res.data.msg)
            }
            this.newDate();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取用户数据
    newDate(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.searchParam = param?param:this.searchParam;

      this.$axios({
        url: this.apiUrl + "user/userList",
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          userId: this.$root.userInfo.userId,
          organizationType:this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.userDate = res.data.data;
            this.count = res.data.count;
          }else if(res.data.status == 201){
            this.userDate = []
          }
          this.loadingShow = false;
        })
        .catch((err) => {
          this.loadingShow = false;
          this.userDate = []
          console.log(err);
        });
    },
    // 获取多选列表
    handleDeleteAllUser(val) {
      this.DeleteAllUser = val;
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.newDate();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.newDate();
      }
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user-control {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  .ivu-table-wrapper-with-border {
    margin-bottom: 10px;
  }
}
</style>