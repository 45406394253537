<template>
  <!-- 指令监控top搜索 -->
  <div class="search-instruction">
    <template v-if="!$route.path.includes('apollo')">
    <label for="equipment-id">DeviceID：</label>
    <Input
      id="equipment-id"
      v-model.trim="euqipmentID"
      placeholder="Please Enter DeviceID"
      style="width: 200px"
      @keyup.native.enter="searchInstruction"
    ></Input>
    &nbsp;
    </template>
    <label for="equipment-id">ModuleID：</label>
    <Input
      id="equipment-id"
      v-model.trim="wifiID"
      placeholder="Please Enter ModuleID"
      style="width: 200px"
      @keyup.native.enter="searchInstruction"
    ></Input>
    &nbsp;
    
    <label for="equipment-id">Communication IP：</label>
    <Input
      id="equipment-id"
      v-model.trim="communicationIP"
      placeholder="Please Enter Communication IP,如:192.168.0.1"
      style="width: 200px"
      @keyup.native.enter="searchInstruction"
    ></Input>
    &nbsp;
    <Button type="primary" @click="searchInstruction">Inquiry</Button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wifiID: "",
      euqipmentID: "",
      communicationIP: "",
    };
  },
  methods: {
    // 搜索
    searchInstruction() {
      let param = {
        instructWifiid: this.wifiID,
        instructEquipmentid: this.euqipmentID,
        instructIp: this.communicationIP,
      };
      this.$emit("search-instruction", param);
    },
  },
};
</script>

<style lang="less" scoped>
.search-instruction {
  margin-bottom: 10px;
}
</style>