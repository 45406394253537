<template>
  <div class="search-role">
    <label for="role-search">角色：</label>
    <Input
      id="role-search"
      v-model.trim="roleInput"
      placeholder=""
      style="width: auto"
      @keyup.native.enter="searchRole"
    />&nbsp;&nbsp;
    <Button type="primary" shape="circle" icon="ios-search" @click="searchRole"
      >查询</Button
    >&nbsp; <Button type="primary" @click="addRole">添加</Button>&nbsp;
    <Button type="primary" @click="delectRole">删除</Button>
    <Modal
      v-model="dialogFormVisible"
      title="添加角色"
      @on-ok="addSubmit"
      @on-cancel="addCancel"
    >
      <Form :model="roleFrom" :label-width="80" label-colon>
        <FormItem label="角色名称">
          <Input v-model.trim="roleFrom.name" style="width: auto"></Input>
        </FormItem>
        <FormItem label="角色级别">
          <Input v-model.trim="roleFrom.rank" style="width: auto"></Input>
        </FormItem>
        <FormItem label="角色序号">
          <Input
            v-model.trim="roleFrom.serialNumber"
            style="width: auto"
          ></Input>
        </FormItem>
        <FormItem label="备注说明">
          <Input
            v-model.trim="roleFrom.remark"
            style="width: auto"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
          ></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: ["selectList"],
  data() {
    return {
      dialogFormVisible: false,
      roleInput: "",
      roleFrom: {
        name: "",
        rank: "",
        serialNumber: "",
        remark: "",
      },
    };
  },
  methods: {
    // 搜索角色
    searchRole() {
      let param = {};
      this.$emit("search-list", param);
    },
    // 添加角色
    addRole() {
      this.dialogFormVisible = true;
    },
    // 删除
    delectRole() {
      this.$confirm("是否确定删除")
        .then(() => {
          this.selectList.forEach((item) => {
            this.$emit("delete-role", item);
          });
        })
        .catch(() => {
          this.$message("取消删除");
        });
    },
    // 添加角色
    addSubmit() {},
    // 取消添加
    addCancel() {},
  },
};
</script>

<style lang="less" scoped>
.search-role {
  margin-bottom: 10px;
}
</style>