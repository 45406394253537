<template>
  <div id="equipment-alarm-state" style="width: 100%; height: 350px"></div>
</template>

<script>
export default {
  props: ["alarmDate"],
  data() {
    return {
      chart: null,
      option: null,
    };
  },
  created() {
    this.option = {
      // backgroundColor: "#dcdee2",
      title: {
        top: 10,
        text: "Device Alarm Status",
        textStyle: {
          fontWeight: "normal",
          fontSize: 15,
          // color: "#ffffff",
        },
        left: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {},
      xAxis: [{
        axisTick: {
          alignWithLabel: true,
          inside: true,
          length: 2,
        },
        axisLabel:{
          rotate:20
        },
        data: this.alarmDate.nameEnglish
          ? this.alarmDate.nameEnglish
          : ["MPPT all-in-one", "PV equipment", "Inverter equipment","Apollo equipment"],
      }],
      yAxis: {
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          inside: true,
          length: 3,
        },
      },
      series: [
        {
          name: "Alarm",
          type: "bar",
          barMinHeight: 2,
          data: this.alarmDate.data ? this.alarmDate.data : [0, 0, 0],
        },
      ],
    };
  },
  mounted() {
    this.initState();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initState() {
      //   console.log(document.querySelector(".equipment-state"));
      this.chart = this.$echarts.init(
        document.getElementById("equipment-alarm-state")
      );
      // this.chart.clear();
      this.chart.setOption(this.option);
      window.onresize = this.chart.resize;
    },
  },
  watch: {
    series(newValue, oldValue) {
      if (newValue != oldValue) {
        this.initState();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.equipment-alarm-state {
  // flex: 0.3;
  width: 100%;
}
</style>