<template>
  <!-- Apollo指令监控 -->
  <div class="apollo-instruction">
    <template v-if="language == 'Chinese'">
        <SearchInstruction @search-instruction="getInstrutionApolloList($event)" />
    </template>
    <template v-else>
        <SearchInstructionEnglish
        @search-instruction="getInstrutionApolloList($event)"
        />
    </template>
    <ApolloInstructionList
      :instructionList="instructionApolloList"
      :index="index"
      :count="count"
      :pageLimit="pageLimit"
      :loadingShow="loadingShow"
      :language="language"
    />
  </div>
</template>

<script>
import SearchInstruction from "../../components/equipment/Chinese/SearchInstruction.vue";
import ApolloInstructionList from "../../components/equipment/ApolloInstructionList.vue";
import SearchInstructionEnglish from "../../components/equipment/English/SearchInstructionEnglish.vue";
export default {
  components: {
    SearchInstruction,
    ApolloInstructionList,
    SearchInstructionEnglish,
  },
  props: ["language"],
  data() {
    return {
      index: 1,
      count: 0,
      pageLimit: 10,
      instructionApolloUrl: "equInstructApollo/equInstructApolloList", // 列表接口
      instructionApolloList: [],
      loadingShow: true,
      searchParam: {},
    };
  },
  created() {
    this.getInstrutionApolloList();
  },
  methods: {
    // 获取Apollo设备指令监控列表
    getInstrutionApolloList(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.searchParam = param ? param : this.searchParam;
      this.loadingShow = true;
      this.$axios({
        url: this.apiUrl + this.instructionApolloUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          equipmentFactoryFk: this.$root.userInfo.organizationId,
          MechanismType: this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.instructionApolloList = res.data.data;
            this.count = res.data.count;
          }else{
            this.instructionApolloList = []
          }
        })
        .catch((err) => {
          this.instructionApolloList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getInstrutionApolloList();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getInstrutionApolloList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>