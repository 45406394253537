<template>
  <div class="equipment-state-list">
    <Row
      style="background: #eee; padding: 20px; justify-content: space-between"
    >
      <Col span="6" v-for="(item, index) in equipmentData" :key="index">
        <Card border>
          <h5 slot="title">{{ item.xAxis.type }}概况</h5>
          <div class="left">
            <p>设备总数：{{ item.series.data[3]=="null"?"0":item.series.data[3] }}</p>
            <p>设备在线数：{{ item.series.data[0]=="null"?"0":item.series.data[0] }}</p>
            <p>设备离线数：{{ item.series.data[1]=="null"?"0":item.series.data[1] }}</p>
            <template v-if="item.xAxis.type == 'PV'">
              <p>日发电量：{{ item.series.data[4]=="null"?"0":item.series.data[4] }}KW.H</p>
              <p>总发电量：{{ item.series.data[5]=="null"?"0":item.series.data[5] }}KW.H</p>
            </template>
            <template v-if="item.xAxis.type == 'MPPT'">
              <p>光伏发电量：{{ item.series.data[4]=="null"?"0":item.series.data[4] }}KW.H</p>
            </template>
          </div>
          <div class="right">
            <EquipmentStatePie
              :index="index"
              :dataTitle="item.series.name + '在线状态'"
              :data="[
                { value: item.series.data[0], name: '在线' },
                { value: item.series.data[1], name: '离线' },
              ]"
            />
          </div>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import EquipmentStatePie from "./EquipmentStatePie.vue";
export default {
  props: ["equipmentData"],
  components: {
    EquipmentStatePie,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
.equipment-state-list {
  .ivu-card-body {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    p{
      font-size: 15px;
      line-height: 25px;
    }
  }
}
</style>