<template>
  <Card class="login-info">
    欢迎来到电源物联智能监控系统
  </Card>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.login-info {
  margin-right: 20px;
  background-color: #28a7459c;
  font-size: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 5px;
  .ivu-card-body{
    padding: 16px;
  }
}
</style>