<template>
  <!-- 终端机构管理 -->
  <div class="terminal" v-if="language == 'Chinese'">
    <SearchInstitutionInfo
      :selectList="selectList"
      @update-list="getTerminal"
      :addTitle="addTitleChinese"
      institutionManufacturers=""
      :institutionsType="institutionsTypeChinese"
      :addUrl="addTerminalUrl"
      @delete-select="deleteTerminal($event)"
      @search-list="getTerminal($event)"
      :type="institutionsType"
      >机构名称：</SearchInstitutionInfo
    >
    <InstitutionsList
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :institutionList="terminalList"
      :loadingShow="loadingShow"
      :editUrl="editTerminalUrl"
      @select-list="selectList = $event"
      :institutionsType="institutionsTypeChinese"
      :type="institutionsType"
      institutionManufacturers=""
      :institutionName="addTitleChinese"
      @delete-list="deleteTerminal($event)"
      @updata-list="getTerminal"
    />
  </div>
  <div class="terminal" v-else>
    <SearchInstitutionInfoEnglish
      :selectList="selectList"
      @update-list="getTerminal"
      :addTitle="addTitleEnglish"
      institutionManufacturers=""
      :institutionsType="institutionsTypeEnglish"
      :addUrl="addTerminalUrl"
      @delete-select="deleteTerminal($event)"
      @search-list="getTerminal($event)"
      :type="institutionsType"
      >Institution Name：</SearchInstitutionInfoEnglish
    >
    <InstitutionsListEnglish
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :institutionList="terminalList"
      :loadingShow="loadingShow"
      institutionManufacturers=""
      :editUrl="editTerminalUrl"
      @select-list="selectList = $event"
      :institutionsType="institutionsTypeEnglish"
      :type="institutionsType"
      :institutionName="addTitleChinese"
      @delete-list="deleteTerminal($event)"
      @updata-list="getTerminal"
    />
  </div>
</template>

<script>
import SearchInstitutionInfo from "../../components/system/Chinese/institutions/SearchInstitutionInfo.vue";
import InstitutionsList from "../../components/system/Chinese/institutions/InstitutionsList.vue";
import SearchInstitutionInfoEnglish from "../../components/system/English/institutions/SearchInstitutionInfoEnglish.vue";
import InstitutionsListEnglish from "../../components/system/English/institutions/InstitutionsListEnglish.vue";
export default {
  components: {
    SearchInstitutionInfo,
    InstitutionsList,
    SearchInstitutionInfoEnglish,
    InstitutionsListEnglish,
  },
  props: ["language"],
  data() {
    return {
      terminalList: [],
      count: 0,
      index: 1,
      pageLimit: 10,
      loadingShow: true,
      selectList: [],
      // 机构列表查询
      // terminalUrl: "mechanism/mechanismList",
      terminalUrl: "mechanismDirectly/mechanismTerminalList",
      addTerminalUrl: "mechanismDirectly/SaveMechanismmTerminal",
      editTerminalUrl: "mechanismDirectly/updateMechanismTerminal",
      addTitleChinese: "终端用户",
      institutionsType: "1004",
      institutionsTypeChinese: "厂家",
      addTitleEnglish: "Terminal User",
      institutionsTypeEnglish: "Manufacturer",
      searchParam:{},
    };
  },
  created() {
    this.getTerminal();
  },
  methods: {
    // 获取终端机构列表数据
    getTerminal(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.searchParam = param?param:this.searchParam;
      this.$axios({
        url: this.apiUrl + this.terminalUrl,
        method: "get",
        /* params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismType: this.institutionsType,
          id: this.$root.userInfo.userMechanismFk,
          ...this.searchParam,
        }, */
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismParentFk: this.$root.userInfo.userMechanismFk,
          userMechanismType:this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          this.loadingShow = false;
          // console.log(res);
          if (res.data.status == 200) {
            this.terminalList = res.data.data;
            this.count = res.data.count;
          }else{
            this.terminalList = [];
          }
        })
        .catch((err) => {
          this.terminalList = [];
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 删除终端机构列表数据
    deleteTerminal(row) {
      this.$axios({
        url: this.apiUrl + "mechanismDirectly/delMechanismTerminalById",
        method: "post",
        params: {
          id: row.id,
        },
      })
        .then((res) => {
          if (res.data.status == 200) {
            // this.$message.success(res.data.msg);
            this.getTerminal();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getTerminal();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getTerminal();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>