<template>
  <!-- 初级管理员、经销商、终端管理搜索 -->
  <div class="search-institutions">
    <label for="search-institution"><slot></slot></label>
    <Input
      id="search-institution"
      v-model.trim="searchInstitutionName"
      placeholder="机构名称"
      style="width: 200px"
      @keyup.native.enter="searchInstitution"
    ></Input>
    &nbsp;
    <Button
      type="primary"
      shape="circle"
      icon="ios-search"
      @click="searchInstitution"
      >查询</Button
    >
    &nbsp;
    <Button type="primary" @click="addInstitutions">添加</Button>&nbsp;
    <Button type="primary" @click="delectInstitutions">删除</Button>&nbsp;
    <Button
      type="primary"
      v-if="institutionManufacturers != ''"
      @click="rentunTo"
      >返回上一级</Button
    >
    <Modal
      v-model="dialogFormVisible"
      :title="'添加' + addTitle"
      @on-ok="addSubmit"
      @on-cancel="addCancel"
    >
      <Form :model="addFrom" :label-width="80" label-colon>
        <FormItem label="机构类型">
          <Input v-model.trim="addFrom.institutionType" disabled></Input>
        </FormItem>
        <FormItem label="所属机构" v-if="institutionManufacturers != ''">
          <Input
            v-model.trim="addFrom.institutionManufacturers"
            disabled
          ></Input>
        </FormItem>
        <FormItem label="机构名称">
          <Input v-model.trim="addFrom.institutionName"></Input>
        </FormItem>
        <FormItem label="联系人">
          <Input v-model.trim="addFrom.name"></Input>
        </FormItem>
        <FormItem label="联系电话">
          <Input
            type="tel"
            v-model.trim="addFrom.phone"
            :maxlength="11"
          ></Input>
        </FormItem>
        <FormItem label="Email">
          <Input type="email" v-model.trim="addFrom.email"></Input>
        </FormItem>
        <FormItem label="备注">
          <Input v-model.trim="addFrom.note" type="textarea"></Input>
        </FormItem>
        <FormItem label="地址">
          <Input v-model.trim="addFrom.address" type="textarea"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "selectList",
    "addTitle",
    "institutionsType",
    "institutionManufacturers",
    "addUrl",
    "type",
  ],
  data() {
    return {
      searchInstitutionName: "",
      dialogFormVisible: false,
      addFrom: {
        institutionType: this.addTitle,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        institutionName: "",
        name: "",
        phone: "",
        email: "",
        note: "",
        address: "",
      },
    };
  },
  methods: {
    // 搜索机构信息
    searchInstitution() {
      let param = {
        searchName: this.searchInstitutionName,
      };
      this.$emit("search-list", param);
    },
    // 打开添加机构信息页面
    addInstitutions() {
      this.dialogFormVisible = true;
    },
    // 删除机构信息
    delectInstitutions() {
      this.$confirm("是否确定删除").then(() => {
        this.selectList.forEach((item) => {
          this.$emit("delete-select", item);
        });
      }).catch(()=>{
        this.$message.info("取消删除")
      });
      // this.$emit("update-list");
    },
    // 添加机构信息
    addSubmit() {
      if (
        this.addFrom.institutionName == "" ||
        this.addFrom.name == "" ||
        this.addFrom.phone == ""
      )
        return this.$alert("机构名称、联系人、联系电话不能为空");
      if (this.addFrom.phone.length != 11 ||this.addFrom.phone.search(/[^\d]/g) != -1) {
        this.addCancel();
        return this.$alert("输入的手机号码不符合规范");
      }
      if (this.addFrom.email != "") {
        var re =
          /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if (!re.test(this.addFrom.email)) {
          return this.$alert("输入的邮箱格式不正确");
        }
      }
      let param = {};
      // 根据不同机构去判断所需参数
      if (this.institutionManufacturers != "") {
        if (this.institutionsType == "初级管理员") {
          param = {
            mechanismParentFk: this.institutionManufacturers.id,
            userMechanismType:this.$root.userInfo.organizationType,
          };
        } else {
          param = {
            mechanismParentFk: this.institutionManufacturers.id,
            userMechanismType:this.$root.userInfo.organizationType,
          };
        }
      } else {
        param = {
          mechanismParentFk: this.$root.userInfo.userMechanismFk,
          userMechanismType:this.$root.userInfo.organizationType,
        };
      }
      // 提交信息
      this.$axios({
        url: this.apiUrl + this.addUrl,
        method: "post",
        params: {
          mechanismName: this.addFrom.institutionName,
          mechanismType: this.type,
          mechanismContacts: this.addFrom.name,
          mechanismPhone: this.addFrom.phone,
          mechanismEmail: this.addFrom.email,
          mechanismNotes: this.addFrom.note,
          address: this.addFrom.address,
          ...param,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.$emit("update-list");
            this.addCancel();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 取消添加
    addCancel() {
      this.addFrom = {
        institutionType: this.addTitle,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        institutionName: "",
        name: "",
        phone: "",
        email: "",
        note: "",
        address: "",
      };
    },
    // 返回上级
    rentunTo() {
      this.$parent.index = 1;
      this.$parent.pageLimit = 10;
      this.$emit("renturn-list");
      this.searchInstitutionName = "";
    },
  },
  watch: {
    institutionManufacturers(newValue, oldValue) {
      if (newValue != "") {
        this.addFrom.institutionManufacturers = newValue.mechanismName;
      }
    },
  },
};
</script>

<style lang="less">
.search-institutions {
  margin-bottom: 10px;
}
.ivu-form-item {
  .ivu-form-item-content {
    width: 40%;
  }
}
</style>