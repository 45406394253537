<template>
  <div class="nav-tag" v-if="options.length">
    <div class="tage">
      <Tabs
        type="card"
        @on-click="tabClick"
        v-model="activeIndex"
        @on-tab-remove="tabRemove"
        :value="$route.path"
        closable
      >
        <TabPane
          v-for="item in options"
          :key="item.route"
          :label="item.title"
          :name="item.route"
        ></TabPane>
      </Tabs>
      <Tooltip content="清除其他导航标签" placement="left">
        <Icon class="clear-all" type="md-close" @click="clearAll" />
      </Tooltip>
    </div>
    <router-view
      v-if="!fullscreenLoading"
      :language="language"
      @update-nav="$emit('update-nav')"
    ></router-view>
  </div>
</template>

<script>
export default {
  props: ["language", "fullscreenLoading"],
  data() {
    return {
      activeIndex: "",
      options: [], // tag标签
    };
  },
  created() {
    this.newRouterTag();
  },
  methods: {
    // 点击tab标签
    tabClick(name) {
      if (this.$route.path == name) return;
      this.$router.push(name);
    },
    // 移除tap标签
    tabRemove(name) {
      if (this.options.length == 1) {
        this.$router.push("/home");
        this.newRouterTag();
        return;
      }
      this.options = this.options.filter((item) => item.route != name);
      let goRoute = this.options[this.options.length - 1].route;
      this.$router.push(goRoute);
      this.activeIndex = goRoute;
    },
    // 添加新的tab标签
    newRouterTag() {
      this.options.push({
        title: this.$route.meta.title,
        route: this.$route.path,
      });
      this.activeIndex = this.$route.path;
    },
    // 清除全部tag标签
    clearAll() {
      this.options = [
        {
          route: this.$route.path,
          title: this.$route.meta.title,
        },
      ];
      this.activeIndex = this.$route.path;
      this.$router.push(this.$route.path);
      // this.newRouterTag();
    },
  },
  watch: {
    // 监听路由的变化
    $route(to, from) {
      let isroute = this.options.find((item) => item.route == to.path);
      if (!isroute) {
        this.options = this.options.filter((item) =>
          item.title ? item : null
        );
        this.options.push({
          title: to.meta.title,
          route: to.path,
        });
      }
      this.activeIndex = to.path;
    },
  },
};
</script>

<style lang="less" scoped>
.nav-tag {
  height: 100%;
  .tage {
    display: flex;
    .ivu-tabs {
      flex: 1;
    }
    .clear-all {
      line-height: 35px;
      display: block !important;
      margin-left: 10px;
    }
  }
}
</style>