<template>
  <div class="code-list">
    <Table
      border
      :loading="loadingShow"
      :columns="codeListTitle"
      :data="codeList"
      @on-selection-change="selectChangeCode"
    >
      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" @click="editCode(row, index)"
          >编辑</Button
        >
      </template>
    </Table>
    <Page
      ref="page"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    />
    <Modal
      v-model="addCodeShow"
      title="编辑"
      @on-ok="addSubmit"
      @on-cancel="addCancel"
    >
      <Form :model="addFrom" :label-width="100" label-colon>
        <FormItem label="类型号">
          <Input v-model.trim="addFrom.type" style="width: auto"></Input>
        </FormItem>
        <FormItem :label="code == '类型码' ? '类型名称' : '码名称'">
          <Input v-model.trim="addFrom.name" style="width: auto"></Input>
        </FormItem>
        <FormItem :label="code == '类型码' ? '类型英文名称' : '码英文名称'">
          <Input v-model.trim="addFrom.nameEnglish" style="width: auto"></Input>
        </FormItem>
        <template v-if="code == '基础码'">
          <FormItem label="码号">
            <Input v-model.trim="addFrom.barCode" style="width: auto"></Input>
          </FormItem>
          <FormItem label="序号">
            <Input
              v-model.trim="addFrom.serialNumber"
              style="width: auto"
            ></Input>
          </FormItem>
          <FormItem label="分类">
            <Input v-model.trim="addFrom.classify" style="width: auto"></Input>
          </FormItem>
        </template>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "codeListTitle",
    "codeList",
    "index",
    "count",
    "pageLimit",
    "loadingShow",
    "code",
  ],
  data() {
    return {
      addCodeShow: false,
      addFrom: {
        type: "",
        name: "",
        nameEnglish: "",
        barCode: "",
        serialNumber: "",
        classify: "",
      },
    };
  },
  methods: {
    // 多选类型码或基础码列表
    selectChangeCode(selection) {
      this.$emit("select-code", selection);
    },
    // 修改类型码或基础码
    editCode(row, index) {
      this.addCodeShow = true;
      if (this.code == "基础码") {
        this.addFrom = {
          type: row.type,
          name: row.name,
          nameEnglish: row.nameEnglish,
          barCode: row.barCode,
          serialNumber: row.serialNumber,
          classify: row.classify,
        };
      } else {
        this.addFrom.type = row.type;
        this.addFrom.name = row.name;
        this.addFrom.nameEnglish = row.nameEnglish;
      }
    },
    // 提交修改
    addSubmit() {},
    // 取消修改
    addCancel() {},
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ivu-table-wrapper-with-border {
  margin-bottom: 10px;
}
</style>