<template>
  <!-- 注册设备top搜索 -->
  <div class="search-registered">
    <template v-if="!$route.path.includes('apollo')">
    <label for="search-deviceID">设备ID：</label>
    <Input
      id="search-deviceID"
      v-model.trim="deviceIDValue"
      placeholder="请输入设备ID"
      style="width: 200px"
      @keyup.native.enter="searchRegisteredEquipment"
    ></Input>
    &nbsp;
    </template>
    <label for="search-wifiID">模块ID：</label>
    <Input
      id="search-wifiID"
      v-model.trim="WifiIDValue"
      placeholder="请输入模块ID"
      style="width: 200px"
      @keyup.native.enter="searchRegisteredEquipment"
    ></Input>
    &nbsp;
    
    <label for="search-manufacturer">厂家名称：</label>
    <Select id="search-manufacturer" v-model="supplier" style="width: 200px">
      <Option value="All">全部</Option>
      <Option v-for="item in supplierList" :value="item.id" :key="item.id">{{
        item.mechanismName
      }}</Option>
    </Select>
    &nbsp;
    <Button type="primary" icon="ios-search" @click="searchRegisteredEquipment"
      >搜索</Button
    >
    &nbsp;
    <Button type="primary" @click="registeringDevice">注册新设备</Button>
    &nbsp;
    <Button type="primary" @click="delectDevice">删除</Button>
    &nbsp;
    <Button slot="trigger" type="primary" @click="egisteredTemplate"
      >注册模板下载</Button
    >
    &nbsp;
    <el-upload
      class="upload-demo"
      ref="uploadregistered"
      :action="apiUrl + fileUploadUrl"
      :file-list="fileList"
      :auto-upload="false"
      :on-change="submitUpload"
      :limit="1"
      list-type="xcl"
      :on-success="successFile"
    >
      <!-- :on-preview="handlePreview"
      :on-remove="handleRemove" -->
      <Button slot="trigger" type="primary">批量注册新设备</Button>
      <span slot="tip" class="el-upload__tip"> 只能上传excel </span>
    </el-upload>
    <Modal
      v-model="show"
      title="注册新设备"
      @on-ok="submit"
      @on-cancel="cancel"
    >
      <Form :model="registeredFrom" :label-width="90" label-colon>
        <FormItem label="设备类型">
          <Select v-model="selectModule" style="width:100px">
            <Option v-for="item in moduleLish" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <FormItem :label="typeName">
          <Input
            v-model="registeredFrom.registeredWiFiID"
            style="width: 200px"
            :placeholder="'请输入'+typeName"
          ></Input>
        </FormItem>
        <FormItem label="厂家">
          <Select
            id="manufacturer"
            style="width: 200px"
            v-model="registeredFrom.manufacturerName"
          >
            <Option
              v-for="item in supplierList"
              :value="item.id"
              :key="item.id"
              >{{ item.mechanismName }}</Option
            >
          </Select>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "deleteUrl",
    "selectionDevice",
    "fileUploadUrl",
    "addUrl",
    "templateUrl",
  ],
  data() {
    return {
      show: false, // 弹框是否显示
      WifiIDValue: "", // 搜索WiFiID
      deviceIDValue: "", // 搜索设备ID
      supplier: "All", // 搜索厂家
      supplierList: [], // 厂家列表
      registeredFrom: {
        registeredWiFiID: "", // 注册设备的WiFiID
        manufacturerName: "", // 注册设备的厂家名称
      },
      fileList: [], // 导入文件列表
      selectModule:"WIFI",
      moduleLish:[{
        value:"WIFI",
        label:"WiFi"
      },{
        value:"4G",
        label:"4G"
      }],
    };
  },
  created() {
    // 获取厂家名称列表数据
    this.$axios({
      url: this.apiUrl + "mechanism/mechanismChildrenByEquipId",
      method: "get",
      params: {
        mechanismType: this.$root.userInfo.organizationType,
      },
    })
      .then((res) => {
        // console.log(res);
        this.supplierList = res.data.data;
      })
      .catch((err) => err);
  },
  computed:{
    typeName(){
      return this.selectModule == "WIFI"?"WiFiID":"4G"
    }
  },
  methods: {
    // 搜索注册设备
    searchRegisteredEquipment() {
      let param = {
        wifiId: this.WifiIDValue,
        equipmentId: this.deviceIDValue,
        equipmentFactoryFk:
          this.supplier == "All"
            ? this.$root.userInfo.organizationId
            : this.supplier,
      };
      this.$emit("search-registered", param);
    },
    // 注册新设备
    registeringDevice() {
      this.show = true;
    },
    // 弹框确认提交数据
    submit() {
      if (
        this.registeredFrom.registeredWiFiID == "" ||
        // this.registeredFrom.deviceID == "" ||
        this.registeredFrom.manufacturerName == ""
      )
        return this.$alert("WiFiID和厂家不能为空");
      this.$axios({
        url: this.apiUrl + this.addUrl,
        method: "post",
        params: {
          wifiId: this.registeredFrom.registeredWiFiID,
          // equipmentId: this.registeredFrom.deviceID,
          equipmentFactoryFk: this.registeredFrom.manufacturerName,
          equipmentType:this.selectModule
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.$emit("delece-updata");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 弹框取消后清除数据
    cancel() {
      this.registeredWiFiID = "";
      // this.deviceID = "";
      this.manufacturerName = "";
    },
    // 删除设备
    delectDevice() {
      // console.log(this.selectionDevice);
      // if(this.selectionDevice.length)
      this.$confirm("是否确定删除")
        .then(() => {
          this.selectionDevice.forEach((item,index) => {
            // console.log(item.id);
            this.$axios({
              url: this.apiUrl + this.deleteUrl,
              method: "post",
              params: {
                id: item.id,
              },
            }).then((res) => {
              // console.log(res);
              if (res.data.status == 200) {
                if (index == this.selectionDevice.length - 1) {
                  this.$message.success(res.data.msg);
                }
                this.$emit("delece-updata");
              } else {
                if (index == this.selectionDevice.length - 1) {
                  this.$message.error(res.data.msg);
                }
              }
            });
          });
        })
        .catch(() => {
          this.$message("取消删除");
        });
    },
    // 批量导入注册
    // 提交
    submitUpload() {
      this.$refs.uploadregistered.submit();
    },
    // 上传成功后的回调
    successFile(res, file, fileList) {
      // console.log(res, file);
      if (res.code == 200) {
        // console.log(res, file);
        this.$emit("updata-list");
        this.fileList = [];
      }
    },
    // 批量注册模板下载
    egisteredTemplate() {
      this.$axios({
        url: this.apiUrl + this.templateUrl,
        method: "post",
        params: {
          type: "zc",
        },
        withCredentials: true,
        responseType: "blob",
      })
        .then((res) => {
          const link = document.createElement("a");
          let blob = new Blob([res.data], {
            type: "application/x-excel",
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "批量注册模板.xls";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.$Notice.error({
            title: "错误",
            desc: "网络连接错误",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.search-registered {
  margin-bottom: 10px;
  .upload-demo {
    display: inline-block;
    .el-upload-list {
      &.el-upload-list--xcl {
        display: none;
      }
    }
  }
}
</style>
<style lang="less">
.search-registered {
  .upload-demo {
    .el-upload-list {
      &.el-upload-list--xcl {
        // display: none;
        display: inline-block;
        li {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
}
</style>