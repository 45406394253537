<template>
  <!-- Apollo注册管理 -->
  <div class="apollo-registration">
    <template v-if="language == 'Chinese'">
    <SearchRegistered
      :deleteUrl="deleteApolloUrl"
      :selectionDevice="selectionDevice"
      @delece-updata="getApolloRegistrationList"
      @search-registered="getApolloRegistrationList($event)"
      :fileUploadUrl="fileApolloUploadUrl"
      @updata-list="getApolloRegistrationList"
      :addUrl="addApolloUrl"
      :templateUrl="templateApolloUrl"
    />
    </template>
    <template v-else>
    <SearchRegisteredEnglish
      :deleteUrl="deleteApolloUrl"
      :selectionDevice="selectionDevice"
      @delece-updata="getApolloRegistrationList"
      @search-registered="getApolloRegistrationList($event)"
      :fileUploadUrl="fileApolloUploadUrl"
      @updata-list="getApolloRegistrationList"
      :addUrl="addApolloUrl"
      :templateUrl="templateApolloUrl"
    />
    </template>
    <ApolloRegisteredList
      ref="list"
      :language="language"
      :deviceUrl="ApolloRegisteredUrl"
      @selection-delete="selectionDevice = $event"
      :registeredDeviceList="registeredApolloList"
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :distributionUrl="distributionApolloUrl"
      @update-factory="getApolloRegistrationList"
      :loadingShow="loadingShow"
    />
  </div>
</template>

<script>
import SearchRegistered from "../../components/equipment/Chinese/SearchRegistered.vue";
import ApolloRegisteredList from "../../components/equipment/ApolloRegisteredList.vue";
import SearchRegisteredEnglish from "../../components/equipment/English/SearchRegisteredEnglish.vue";
export default {
  components: {
    SearchRegistered,
    ApolloRegisteredList,
    SearchRegisteredEnglish
  },
  props: ["language"],
  data() {
    return {
      count: 0,
      index: 1,
      pageLimit: 10,
      ApolloRegisteredUrl: "equipmentApollo/equipmentApolloList", // 设备列表
      selectionDevice: null,
      registeredApolloList: [], // 注册Apollo设备列表
      fileApolloUploadUrl: "equipmentApollo/importApolloInData", // 批量注册
      deleteApolloUrl: "equipmentApollo/delEquipmentApolloById", // 删除列表
      distributionApolloUrl: "equipmentApollo/updateEquipmentApollo", // 厂家分配
      addApolloUrl: "equipmentApollo/saveEquipmentApollo", // 注册
      templateApolloUrl: "equipmentApollo/importApolloModel", // 批量注册模板
      loadingShow: true,
      searchParam: {},
    };
  },
  created() {
    this.getApolloRegistrationList();
  },
  methods: {
    // 获取Apollo注册数据
    getApolloRegistrationList(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.searchParam = param?param:this.searchParam;
      this.loadingShow = true;
      this.$axios({
        url: this.apiUrl + this.ApolloRegisteredUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          equipmentFactoryFk: this.$root.userInfo.organizationId,
          MechanismType: this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          this.selectionDevice = null;
          this.loadingShow = false;
          if (res.data.status == 200) {
            // console.log(res);
            this.registeredApolloList = res.data.data;
            this.count = res.data.count;
          }else{
            this.registeredApolloList = []
          }
        })
        .catch((err) => {
          this.registeredApolloList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getApolloRegistrationList();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getApolloRegistrationList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>