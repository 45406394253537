<template>
  <div class="role-list">
    <Table
      border
      :loading="loadingShow"
      :columns="roleTitle"
      :data="roleList"
      highlight-row
      :stripe="true"
      :no-data-text="'No Data'"
    >
      <template slot-scope="{ row, index }" slot="action">
        <Button type="text" size="small" @click="setPermissions(row, index)"
          >Set Permissions</Button
        >
      </template>
    </Table>
    <!-- <Page
      ref="page"
      style="margin-top: 10px"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    ><slot>
      {{count}} in total
    </slot>
    </Page> -->
    <div style="display:flex;margin-top: 10px;align-content: center">
    <Page
      ref="page"
      :total="count"
      :current.sync="$parent.index"
      show-total
    ><slot>
      {{count}} in total
    </slot>
    </Page>
     <el-pagination layout="slot">
      <p class="ivu-select" style="font-weight:500">
        <Select v-model="$parent.pageLimit" style="width:auto;">
          <Option :value="10">10 pieces/page</Option>
          <Option :value="20">20 pieces/page</Option>
          <Option :value="30">30 pieces/page</Option>
          <Option :value="40">40 pieces/page</Option>
        </Select>
      </p>
    </el-pagination>
     <el-pagination layout="slot">
      <p class="ivu-page-options-elevator" style="font-weight:500">
        Jump to 
        <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
        pages
      </p>
    </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["roleList", "count", "index", "pageLimit", "loadingShow"],
  data() {
    return {
      roleTitle: [
        {
          title: "Character Name",
          key: "roleName",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.roleName))]);
          },
        },
        {
          title: "Role Instructions",
          key: "remake",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.remake))]);
          },
        },
        {
          title: "Operation",
          slot: "action",
          align: "center",
        },
      ],
      currentPage:this.index,
    };
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 设置权限
    setPermissions(row, index) {
      let data = {
        show: true,
        roleId: row.id,
      };
      this.$emit("setting-permissions", data);
    },
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>