<template>
  <div class="ms-login">
    <div class="ms-title">
      Power IoT Intelligent Monitoring System
      <br />
      <el-dropdown
        style="color: #fff; margin-left: 10px"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          English<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="Chinese">Chinese</el-dropdown-item>
          <el-dropdown-item command="English">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-form :model="param" ref="login" label-width="0px" class="ms-content">
      <el-form-item prop="account">
        <el-input
          v-if="user == 'account'"
          v-model.trim="param.userAccount"
          placeholder="Please enter the account number"
        >
          <template #prepend>
            <el-button icon="el-icon-user" disabled></el-button>
          </template>
        </el-input>
        <el-input
          v-if="user == 'userphone'"
          v-model.trim="param.userPhone"
          placeholder="Please enter your mobile number"
        >
          <template #prepend>
            <el-button icon="el-icon-mobile-phone" disabled></el-button>
          </template>
        </el-input>
        <el-input
          v-if="user == 'email'"
          v-model.trim="param.userEmail"
          placeholder="Please enter the email"
        >
          <template #prepend>
            <el-button icon="el-icon-message" disabled></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          show-password
          v-model.trim="param.userPassword"
          @keyup.native.enter="submitForm"
          placeholder="Please input a password"
          autocomplete="new-password"
        >
          <template #prepend>
            <el-button icon="el-icon-lock" disabled></el-button>
          </template>
        </el-input>
      </el-form-item>
      <div class="login-btn">
        <el-button type="primary" @click="submitForm">Sign In</el-button>
      </div>
      <p class="login-tips">
        Tips : Please enter the correct
        {{
          userLogin == "mobile phone number"
            ? "account"
            : userLogin == "email"
            ? "mobile phone number"
            : "email"
        }}and password。<el-link
          type="primary"
          style="color: #0f38a4"
          @click="userToggle"
          >Log in using {{ userLogin }}</el-link
        >
      </p>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["user", "userName"],
  data() {
    return {
      param: {
        userPassword: "",
        userPhone: "",
        userAccount: "",
        userEmail: "",
      },
    };
  },
  computed: {
    userLogin() {
      if (this.user == "userphone") return "email";
      if (this.user == "account") return "mobile phone number";
      if (this.user == "email") return "account";
    },
  },
  methods: {
    // 提交登录信息
    submitForm() {
      if (this.user == "userphone") {
        if (this.param.userPassword == "" || this.param.userPhone == "") {
          return this.$alert("Mobile phone number or password cannot be empty");
        } else {
          if (
            this.param.userPhone.length != 11 ||
            this.param.userPhone.search(/[^\d]/g) != -1
          ) {
            this.param = {
              userPassword: "",
              userPhone: "",
              userAccount: "",
              userEmail: "",
            };
            return this.$alert(
              "The entered mobile phone number does not meet the specification"
            );
          }
        }
      } else if (this.user == "account") {
        if (this.param.userPassword == "" || this.param.userAccount == "")
          return this.$alert("Account or password cannot be blank");
      } else if (this.user == "email") {
        // let reg=new RegExp(/^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/);
        let reg = new RegExp(
          /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        );
        // let reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (this.param.userPassword == "" || this.param.userEmail == "") {
          return this.$alert("Email or password cannot be blank");
        } else {
          if (!reg.test(this.param.userEmail)) {
            this.param = {
              userPassword: "",
              userPhone: "",
              userAccount: "",
              userEmail: "",
            };
            return this.$alert(
              "The mailbox entered does not conform to the specification"
            );
          }
        }
      }
      // console.log(123);
      // console.log(this.$router.push('/home'));
      let data = {};
      Object.values(this.param).forEach((item, index) => {
        if (item != "") {
          data[Object.keys(this.param)[index]] = item;
        }
      });
      // console.log(_this.apiUrl);
      this.$axios({
        url: this.apiUrl + "index/doLogin",
        method: "post",
        params: data,
        withCredentials: true,
      })
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.status == 200) {
            // console.log(res);
            if (res.data.data.organizationType == "1004") {
              return this.$message("Account has no permission to log in");
            }
            document.cookie = "Cookie="+res.headers.sessionid;
            this.$message.success(res.data.msg);
            this.$emit("login-name", res.data.data.name);
            this.$router.push("/home");
            // _this.$router.push("/xjai/equipment-overview");
            window.sessionStorage.setItem(
              "userInfo",
              JSON.stringify(res.data.data)
            );
            this.$root.userInfo = res.data.data;
          } else {
            this.$alert(res.data.msg);
          }
        })
        .catch((err) => this.$alert("The server is not started yet"));
      // console.log(this.$route.path);
    },
    // 判断当前使用的是账号密码登录还是手机号密码登录
    userToggle() {
      if (this.user == "userphone") return (this.$parent.user = "account");
      if (this.user == "account") return (this.$parent.user = "userphone");
      if (this.user == "email") return (this.$parent.user = "account");
    },
    // 切换中英文界面
    handleCommand(command) {
      this.$emit("toggle-lang", command);
    },
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue != oldValue) {
        this.param.userPassword = "";
        this.param.userPhone = "";
        this.param.userAccount = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ms-title {
  padding: 10px 0;
  width: 100%;
  // line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 380px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>