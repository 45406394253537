<template>
  <div class="search-user">
    <label for="search-key">Username：</label>
    <Input
      id="search-key"
      v-model.trim="valueUserName"
      placeholder="Please Enter Username"
      style="width: auto"
      @keyup.native.enter="searchUser"
    ></Input>
    &nbsp;
    <label for="search-key">Account：</label>
    <Input
      id="search-key"
      v-model.trim="valueUserAccount"
      placeholder="Please Enter Account"
      style="width: auto"
      @keyup.native.enter="searchUser"
    ></Input>
    &nbsp;
    <Button type="primary" icon="ios-search" @click="searchUser" shape="circle"
      >Search</Button
    >
    &nbsp;
    <Button type="primary" @click="dialogFormVisible = true">Add</Button>
    &nbsp;
    <Button type="primary" @click="$emit('handle-delect')">Delete</Button>
    <UserBoxEnglish
      :dialogFormVisible="dialogFormVisible"
      userInfoTitle="add"
      @update-list="$emit('update-list')"
      >Add User</UserBoxEnglish
    >
  </div>
</template>

<script>
import UserBoxEnglish from "./UserBoxEnglish.vue";
export default {
  props:["index","pageLimit"],
  components: {
    UserBoxEnglish,
  },
  data() {
    return {
      valueUserName: "",
      dialogFormVisible: false,
      valueUserAccount:""
    };
  },
  methods: {
    // 搜索用户信息
    searchUser() {
      this.$emit("search-user", {
        userName: this.valueUserName,
        userAccount:this.valueUserAccount
      });
      /* this.$axios({
        url: this.apiUrl+"user/userList",
        method: "get",
        params: {
          page: 1,
          limit: 10,
          userMechanismFk:this.$root.userInfo.organizationId,
          organizationType:this.$root.userInfo.organizationType,
          userName: this.valueUserName,
          userAccount:this.valueUserAccount
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$emit("search-user", res.data.data);
          }else{
            this.$emit("search-user");
          }
        })
        .catch((err) => {
          console.log(err);
        }); */
    },
  },
};
</script>

<style lang="less" scoped>
.search-user {
  width: 100%;
  border: 1px solid #e7e7e7;
  padding: 10px 0;
  padding-left: 10px;
  border-radius: 5px;
}
</style>