<template>
  <div class="menu-list">
    <el-tree
      :data="menuList"
      :show-checkbox="showCheckbox ? showCheckbox : false"
      :icon-class="icon"
      :check-on-click-node="showCheckbox ? showCheckbox : false"
      highlight-current
      node-key="id"
      ref="tree"
      @node-click="selectOn"
      :default-checked-keys="defaultList"
      :props="defaultProps"
    >
      <!-- lazy -->
      <!-- default-expand-all  是否默认展开所有 -->
      <span class="custom-tree-node" slot-scope="{ node }">
        <span class="menu-name">{{ node.label }}</span>
        <!-- @click.prevent="selectOn($event, node)" -->
      </span>
    </el-tree>
    <template v-if="showCheckbox && $route.path == '/jurisdiction'">
      <Button type="primary" @click="getCurrentKey">Submit</Button>
      <Button style="margin-left: 8px" @click="cancelRole">Cancel</Button>
    </template>
  </div>
</template>

<script>
export default {
  props: ["showCheckbox", "roleId", "menuList"],
  data() {
    return {
      defaultList: [], // 默认选中的菜单列表
      icon: "el-icon-caret-right",
      defaultProps: {
        children: "children",
        label: "egLable",
      },
      oldChild: [], // 默认选中的数组
      newChild: [], // 带上原来的新的数组数据
    };
  },
  created() {
    if (this.roleId && this.menuList.length) {
      this.defaultCheacked();
    }
  },
  methods: {
    // 获取所选中的菜单列表（可编辑的）
    selectOn(data, node, event) {
      // 被点击的数据
      // console.log(event.$el, data);
      if (this.showCheckbox) return;
      this.$emit("select-menu", data);
      let menuName = document.querySelectorAll(".menu-name");
      menuName.forEach((item) => {
        item.classList.remove("select");
      });
      let menuChild = event.$el.querySelector(".menu-name");
      menuChild.classList.add("select");
    },
    // 设置默认选中
    defaultCheacked() {
      this.menuList.forEach((item) => {
        item.children.forEach((items) => {
          if (items.isRoleMenu != "0") {
            this.defaultList.push(items.id);
          }
        });
      });
      if (this.defaultList.length) {
        setTimeout(() => {
          this.oldChild = this.$refs.tree.getCheckedKeys();
        });
      }
    },
    // 提交修改菜单列表
    submitEditMenu(menuId) {
      this.$axios({
        url: this.apiUrl + "systemMenu/EditorMenuRole",
        method: "post",
        data: this.qs.stringify(
          { RoleID: this.roleId, MenuID: menuId },
          { indices: false }
        ),
      })
        .then((res) => {
          // console.log(res);
          this.$emit("edit-role", false);
          this.$emit("update-nav");
        })
        .catch((err) => err);
    },
    // 修改用户权限(获取所有选中列表)
    getCurrentKey(data) {
      this.newChild = this.$refs.tree.getCheckedKeys();
      // console.log(this.newChild);
      let menuId = [];
      let parentMenuId = this.menuList.map((item) => item.id);
      let indexParent = [];
      this.newChild.forEach((item) => {
        if (!this.oldChild.includes(item)) {
          if (!parentMenuId.includes(item)) {
            menuId.push(item + "*" + 1);
          } else {
           indexParent.push(parentMenuId.indexOf(item));
          }
        }
      });
      if (indexParent != []) {
        indexParent.forEach((item) => {
          this.menuList[item].children.every((items) =>
            menuId.includes(items.id + "*" + 1)
          )
            ? menuId.push(parentMenuId[item] + "*" + 1)
            : "";
        });
      }
      this.oldChild.forEach((item) => {
        if (!this.newChild.includes(item)) {
          if (!parentMenuId.includes(item)) {
            menuId.push(item + "*" + 0);
          } else {
           indexParent.push(parentMenuId.indexOf(item));
          }
        }
      })
      if (indexParent != []) {
        indexParent.forEach((item) => {
          this.menuList[item].children.every((items) =>
            menuId.includes(items.id + "*" + 0)
          )
            ? menuId.push(parentMenuId[item] + "*" + 0)
            : "";
        });
      }
      // console.log(this.qs.stringify( { RoleID: this.roleId, MenuID: menuId }, { indices: false }));
       setTimeout(() => {
        this.submitEditMenu(menuId);
      });
    },
    // 取消修改用户权限
    cancelRole() {
      this.$emit("edit-role", false);
      this.defaultList = [];
      this.oldChild = [];
      this.newChild = [];
    },
  },
  watch: {
    menuList() {
      if (this.roleId && this.menuList.length) {
        this.defaultCheacked();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.select {
  background-color: tan;
}
</style>