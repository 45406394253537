<template>
  <!-- 文件上传 -->
  <div class="file-upload">
    <SearchFile v-if="language == 'Chinese'" :versionData="versionData" @updata-version="getOldFileInfo" />
    <SearchFileEnglish v-else :versionData="versionData" @updata-version="getOldFileInfo" />
  </div>
</template>

<script>
import SearchFile from "../../components/system/Chinese/SearchFile.vue";
import SearchFileEnglish from "../../components/system/English/SearchFileEnglish.vue";
export default {
  props: ["language"],
  components: {
    SearchFile,
    SearchFileEnglish,
  },
  data() {
    return {
      versionData: null,
    };
  },
  created() {
    this.getOldFileInfo();
  },
  methods: {
    // 获取最新文件版本号
    getOldFileInfo() {
      this.$axios
        .get(this.apiUrl + "OTAUpdate/toUpload")
        .then((res) => {
          // console.log(res);
          if (res.data.code == "200") {
            this.versionData = res.data.data;
          }
        })
        .catch((err) => err);
    },
  },
};
</script>

<style lang="less" scoped>
.file-upload{
    width: 100%;
    height: calc(100% - 30px);
    background-color: #ecececa3;
}
</style>