<template>
  <div class="search-user">
    <label for="search-key">用户名：</label>
    <Input
      id="search-key"
      v-model.trim="valueUserName"
      placeholder="请输入用户名称"
      style="width: 200px"
      @keyup.native.enter="searchUser"
    ></Input>
    &nbsp;
    <label for="search-key">账号：</label>
    <Input
      id="search-key"
      v-model.trim="valueUserAccount"
      placeholder="请输入账号"
      style="width: 200px"
      @keyup.native.enter="searchUser"
    ></Input>
    &nbsp;
    <Button type="primary" icon="ios-search" @click="searchUser" shape="circle"
      >搜索</Button
    >
    &nbsp;
    <Button type="primary" @click="dialogFormVisible = true">添加</Button>
    &nbsp;
    <Button type="primary" @click="$emit('handle-delect')">删除</Button>
    <UserBox
      :dialogFormVisible="dialogFormVisible"
      userInfoTitle="add"
      @update-list="$emit('update-list')"
      >添加用户</UserBox
    >
  </div>
</template>

<script>
import UserBox from "./UserBox.vue";
export default {
  props:["index","pageLimit"],
  components: {
    UserBox,
  },
  data() {
    return {
      valueUserName: "",
      dialogFormVisible: false,
      valueUserAccount:""
    };
  },
  methods: {
    // 搜索用户信息
    searchUser() {
      this.$emit("search-user", {
        userName: this.valueUserName,
        userAccount:this.valueUserAccount
      });
     /*  this.$axios({
        url: this.apiUrl+"user/userList",
        method: "get",
        params: {
          page: 1,
          limit: 10,
          userMechanismFk:this.$root.userInfo.organizationId,
          organizationType:this.$root.userInfo.organizationType,
          userName: this.valueUserName,
          userAccount:this.valueUserAccount
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$emit("search-user", res.data.data);
          }else{
            this.$emit("search-user");
          }
        })
        .catch((err) => {
          console.log(err);
        }); */
    },
  },
};
</script>

<style lang="less" scoped>
.search-user {
  width: 100%;
  border: 1px solid #e7e7e7;
  padding: 10px 0;
  padding-left: 10px;
  border-radius: 5px;
}
</style>