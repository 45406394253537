<template>
  <!-- 权限管理列表 -->
  <div class="permissions-list">
    <template v-if="!show">
      <PermissionsListEnglish
        :roleList="rolePowerList"
        @setting-permissions="setting($event)"
        :count="count"
        :index="index"
        :pageLimit="pageLimit"
        :loadingShow="loadingShow"
      />
    </template>
    <template v-else>
      <!-- :defaultMenu="rolePowerList" -->
      <MenuListEnglish
        :showCheckbox="true"
        @edit-role="show = $event"
        :menuList="menuList"
        :roleId="roleId"
        @update-nav="$emit('update-nav')"
      />
    </template>
  </div>
</template>

<script>
import PermissionsListEnglish from "./PermissionsListEnglish.vue";
import MenuListEnglish from "./system-menu/MenuListEnglish.vue";
export default {
  components: {
    PermissionsListEnglish,
    MenuListEnglish,
  },
  data() {
    return {
      menuList: [], // 菜单列表
      show: false,
      rolePowerList: [], // 角色权限列表
      roleId: null,
      count: 0,
      index: 1,
      pageLimit: 10,
      loadingShow: true,
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {
    // 获取菜单列表
    getMeun() {
      this.loadingShow = true;
      this.$axios({
        url: this.apiUrl + "systemMenu/FindAllSystemMenuRoleList",
        method: "get",
        params: {
          RoleID: this.roleId,
        },
      })
        .then((res) => {
          this.loadingShow = false;
          this.menuList = res.data.data;
        })
        .catch((err) => {
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 获取权限列表
    getRoleList() {
      this.$axios
        .get(this.apiUrl + "systemMenu/FindAllSystemRoleList")
        .then((res) => {
          //   console.log(res);
          if (res.data.code == "200") {
            this.rolePowerList = res.data.data;
            this.count = this.rolePowerList.length;
          }else{
            this.rolePowerList = []
          }
          this.loadingShow = false;
        })
        .catch((err) => {
          this.rolePowerList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
    setting({ show, roleId }) {
      this.show = show;
      this.roleId = roleId;
      this.menuList = [];
      this.getMeun();
    },
  },
};
</script>

<style lang="less" scoped>
</style>