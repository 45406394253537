<template>
  <div class="role-list">
    <Table
      border
      :loading="loadingShow"
      :columns="roleTitle"
      :data="roleList"
      highlight-row
      :stripe="true"
    >
      <template slot-scope="{ row, index }" slot="action">
        <Button type="text" size="small" @click="setPermissions(row, index)"
          >设置权限</Button
        >
      </template>
    </Table>
    <Page
      ref="page"
      style="margin-top: 10px"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    />
  </div>
</template>

<script>
export default {
  props: ["roleList", "count", "index", "pageLimit", "loadingShow"],
  data() {
    return {
      roleTitle: [
        {
          title: "角色名称",
          key: "roleName",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.roleName))]);
          },
        },
        {
          title: "角色说明",
          key: "remake",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.remake))]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
    };
  },
  methods: {
    // 设置权限
    setPermissions(row, index) {
      let data = {
        show: true,
        roleId: row.id,
      };
      this.$emit("setting-permissions", data);
    },
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>