import Vue from 'vue'
var countryList = [
    {
        areaCode: "1",
        name: "东亚",
        nameEnglish: "East Asia",
        children: [
            { name: "中国", nameEnglish: "China", areaCode: "156" },
            { name: "朝鲜", nameEnglish: "North Korea", areaCode: "408" }, // 修改
            { name: "韩国", nameEnglish: "South Korea", areaCode: "410" }, // 修改
            { name: "日本", nameEnglish: "Japan", areaCode: "392" },
            { name: "蒙古", nameEnglish: "Mongolia", areaCode: "496" },
        ]
    }, {
        areaCode: "2",
        name: "东南亚",
        nameEnglish: "Southeast Asia",
        children: [
            { name: "越南", nameEnglish: "Vietnam", areaCode: "704" },
            { name: "老挝", nameEnglish: "Laos", areaCode: "418", },
            { name: "柬埔寨", nameEnglish: "Cambodia", areaCode: "116" },
            { name: "泰国", nameEnglish: "Thailand", areaCode: "764" },
            { name: "缅甸", nameEnglish: "Myanmar", areaCode: "104" },
            { name: "马来西亚", nameEnglish: "Malaysia", areaCode: "458" },
            { name: "新加坡", nameEnglish: "Singapore", areaCode: "702" },
            { name: "菲律宾", nameEnglish: "Philippines", areaCode: "608" },
            { name: "印度尼西亚", nameEnglish: "Indonesia", areaCode: "360" },
            { name: "东帝汶", nameEnglish: "Timor-Leste", areaCode: "626" },
            { name: "文莱达鲁萨兰国", nameEnglish: "Brunei Darussalam", areaCode: "096" },
        ]
    }, {
        areaCode: "3",
        name: "南亚",
        nameEnglish: "South Asia",
        children: [
            { name: "印度", nameEnglish: "India", areaCode: "356" },
            { name: "巴基斯坦", nameEnglish: "Pakistan", areaCode: "586" },
            { name: "孟加拉国", nameEnglish: "Bangladesh", areaCode: "050" },
            { name: "不丹", nameEnglish: "Bhutan", areaCode: "064" },
            { name: "尼泊尔", nameEnglish: "Nepal", areaCode: "524" },
            { name: "斯里兰卡", nameEnglish: "Sri Lanka", areaCode: "144" },
            { name: "马尔代夫", nameEnglish: "Maldives", areaCode: "462" },
        ]
    }, {
        areaCode: "4",
        name: "中亚",
        nameEnglish: "Central Asia",
        children: [
            { name: "哈萨克斯坦", nameEnglish: "Kazakhstan", areaCode: "398", },
            { name: "乌兹别克斯坦", nameEnglish: "Uzbekistan", areaCode: "860" },
            { name: "吉尔吉斯斯坦", nameEnglish: "Kyrgyzstan", areaCode: "417" },
            { name: "塔吉克斯坦", nameEnglish: "Tajikistan", areaCode: "762" },
            { name: "土库曼斯坦", nameEnglish: "Turkmenistan", areaCode: "795" },
        ]
    }, {
        areaCode: "5",
        name: "西亚",
        nameEnglish: "Josiah",
        children: [
            { name: "阿富汗", nameEnglish: "Afghanistan", areaCode: "004" },
            { name: "伊朗", nameEnglish: "Iran", areaCode: "364" },
            { name: "科威特", nameEnglish: "Kuwait", areaCode: "414" },
            { name: "沙特阿拉伯", nameEnglish: "Saudi Arabia", areaCode: "682" },
            { name: "巴林", nameEnglish: "Bahrain", areaCode: "048" },
            { name: "卡塔尔", nameEnglish: "Qatar", areaCode: "634" },
            { name: "阿联酋", nameEnglish: "United Arab Emirates", areaCode: "784" },
            { name: "阿曼", nameEnglish: "Oman", areaCode: "512" },
            { name: "也门", nameEnglish: "Yemen", areaCode: "887" },
            { name: "伊拉克", nameEnglish: "Iraq", areaCode: "368" },
            { name: "叙利亚", nameEnglish: "Syrian", areaCode: "760" },
            { name: "黎巴嫩", nameEnglish: "Lebanon", areaCode: "422" },
            { name: "巴勒斯坦", nameEnglish: "Palestine", areaCode: "275" },
            { name: "亚美尼亚", nameEnglish: "Armenia", areaCode: "051" },
            { name: "阿塞拜疆", nameEnglish: "Azerbaijan", areaCode: "031" },
            { name: "格鲁吉亚", nameEnglish: "Georgia", areaCode: "268" },
            { name: "以色列", nameEnglish: "Israel", areaCode: "376" },
            { name: "约旦", nameEnglish: "Jordan", areaCode: "400" },
            { name: "塞浦路斯", nameEnglish: "Cyprus", areaCode: "196" },
            { name: "土耳其", nameEnglish: "Turkey", areaCode: "792" },
        ]
    }, {
        areaCode: "6",
        name: "西欧",
        nameEnglish: "Western Europe",
        children: [
            { name: "罗马尼亚", nameEnglish: "Romania", areaCode: "642" },
            { name: "保加利亚", nameEnglish: "Bulgaria", areaCode: "100" },
            { name: "塞尔维亚", nameEnglish: "Serbia", areaCode: "688" },
            { name: "黑山共和国", nameEnglish: "Montenegro", areaCode: "499" },
            { name: "克罗地亚", nameEnglish: "Croatia", areaCode: "191" },
            { name: "斯洛文尼亚", nameEnglish: "Slovenia", areaCode: "705" },
            { name: "波黑", nameEnglish: "Bosna i Hercegovina", areaCode: "070" },
            { name: "马其顿", nameEnglish: "Macedonia", areaCode: "807" },
            { name: "希腊", nameEnglish: "Greece", areaCode: "300" },
            { name: "阿尔巴尼亚", nameEnglish: "Albania", areaCode: "008" },
            { name: "摩纳哥", nameEnglish: "Monaco", areaCode: "492" },
            { name: "意大利", nameEnglish: "Italy", areaCode: "380" },
            { name: "梵蒂冈", nameEnglish: "Holy See", areaCode: "336" },
            { name: "圣马力诺", nameEnglish: "San Marino", areaCode: "674" },
            { name: "西班牙", nameEnglish: "Spain", areaCode: "724" },
            { name: "安道尔", nameEnglish: "Andorra", areaCode: "020" },
            { name: "葡萄牙", nameEnglish: "Portugal", areaCode: "620" },
            { name: "马耳他", nameEnglish: "Malta", areaCode: "470" },
            { name: "英国", nameEnglish: "United Kingdom", areaCode: "860" },
            { name: "爱尔兰", nameEnglish: "Ireland", areaCode: "372" },
            { name: "荷兰", nameEnglish: "Netherlands", areaCode: "528" },
            { name: "比利时", nameEnglish: "Belgium", areaCode: "056" },
            { name: "卢森堡", nameEnglish: "Luxembourg", areaCode: "442" },
            { name: "法国", nameEnglish: "France", areaCode: "250" },
            { name: "芬兰", nameEnglish: "Finland", areaCode: "246" },
            { name: "瑞典", nameEnglish: "Sweden", areaCode: "752" },
            { name: "挪威", nameEnglish: "Norway", areaCode: "578" },
            { name: "丹麦", nameEnglish: "Denmark", areaCode: "208" },
            { name: "冰岛", nameEnglish: "Iceland", areaCode: "352" },
            { name: "德国", nameEnglish: "Germany", areaCode: "276" },
            { name: "奥地利", nameEnglish: "Austria", areaCode: "040" },
            { name: "瑞士", nameEnglish: "Switzerland", areaCode: "756" },
            { name: "列支敦士登", nameEnglish: "Liechtenstein", areaCode: "438" },
            { name: "波兰", nameEnglish: "Poland", areaCode: "616" },
            { name: "捷克", nameEnglish: "Czechia", areaCode: "203" },
            { name: "斯洛伐克", nameEnglish: "Slovakia", areaCode: "703" },
            { name: "匈牙利", nameEnglish: "Hungary", areaCode: "348" },
        ]
    }, {
        areaCode: "7",
        name: "东欧",
        nameEnglish: "Eastern Europe",
        children: [
            { name: "俄罗斯", nameEnglish: "Russian", areaCode: "643" },
            { name: "乌克兰", nameEnglish: "Ukraine", areaCode: "804" },
            { name: "白俄罗斯", nameEnglish: "Belarus", areaCode: "112" },
            { name: "摩尔多瓦", nameEnglish: "Moldova", areaCode: "498" },
            { name: "立陶宛", nameEnglish: "Lithuania", areaCode: "440" },
            { name: "爱沙尼亚", nameEnglish: "Estonia", areaCode: "233" },
            { name: "拉脱维亚", nameEnglish: "Latvia", areaCode: "428" },
        ]
    }, {
        areaCode: "8",
        name: "南非",
        nameEnglish: "South Africa",
        children: [
            { name: "苏丹", nameEnglish: "Sudan", areaCode: "729" },
            { name: "南苏丹", nameEnglish: "South Sudan", areaCode: "728" },
            { name: "毛里塔尼亚", nameEnglish: "Mauritania", areaCode: "478" },
            { name: "塞内加尔", nameEnglish: "Senegal", areaCode: "686" },
            { name: "冈比亚", nameEnglish: "Gambia", areaCode: "270" },
            { name: "马里", nameEnglish: "Mali", areaCode: "466" },
            { name: "几内亚", nameEnglish: "Guinea", areaCode: "324" },
            { name: "几内亚比绍", nameEnglish: "Guinea-Bissau", areaCode: "624" },
            { name: "塞拉利昂", nameEnglish: "Sierra Leone", areaCode: "694" },
            { name: "利比里亚", nameEnglish: "Liberia", areaCode: "430" },
            { name: "科特迪瓦", nameEnglish: "Côte d'Ivoire", areaCode: "384" },
            { name: "加纳", nameEnglish: "Ghana", areaCode: "288" },
            { name: "多哥", nameEnglish: "Togo", areaCode: "768" },
            { name: "贝宁", nameEnglish: "Benin", areaCode: "204" },
            { name: "布基纳法索", nameEnglish: "Burkina Faso", areaCode: "854" },
            { name: "尼日尔", nameEnglish: "Niger", areaCode: "562" },
            { name: "尼日利亚", nameEnglish: "Nigeria", areaCode: "566" },
            { name: "乍得", nameEnglish: "Chad", areaCode: "148" },
            { name: "喀麦隆", nameEnglish: "Cameroon", areaCode: "120" },
            { name: "赤道几内亚", nameEnglish: "Equatorial Guinea", areaCode: "226" },
            { name: "圣多美和普林西比", nameEnglish: "Sao Tome and Principe", areaCode: "678" },
            { name: "中非共和国", nameEnglish: "Central African Republic", areaCode: "140" },
            { name: "加蓬", nameEnglish: "Gabon", areaCode: "266" },
            { name: "安哥拉", nameEnglish: "Angola", areaCode: "024" },
            { name: "刚果（布）", nameEnglish: "Republic of the Congo", areaCode: "180" },
            { name: "刚果（金）", nameEnglish: "Democratic Republic of the Congo", areaCode: "178" },
            { name: "布隆迪", nameEnglish: "Burundi", areaCode: "108" },
            { name: "卢旺达", nameEnglish: "Rwanda", areaCode: "646" },
            { name: "乌干达", nameEnglish: "Uganda", areaCode: "800" },
            { name: "肯尼亚", nameEnglish: "Kenya", areaCode: "404" },
            { name: "埃塞俄比亚", nameEnglish: "Ethiopia", areaCode: "231" },
            { name: "厄立特里亚", nameEnglish: "Eritrea", areaCode: "232" },
            { name: "索马里", nameEnglish: "Somalia", areaCode: "706" },
            { name: "吉布提", nameEnglish: "Djibouti", areaCode: "262" },
            { name: "坦桑尼亚", nameEnglish: "Tanzania", areaCode: "834" },
            { name: "赞比亚", nameEnglish: "Zambia", areaCode: "894" },
            { name: "津巴布韦", nameEnglish: "Zimbabwe", areaCode: "716" },
            { name: "马拉维", nameEnglish: "Malawi", areaCode: "454" },
            { name: "莫桑比克", nameEnglish: "Mozambique", areaCode: "508" },
            { name: "博茨瓦纳", nameEnglish: "Botswana", areaCode: "072" },
            { name: "南非共和国", nameEnglish: "South Africa", areaCode: "710" },
            { name: "莱索托", nameEnglish: "Lesotho", areaCode: "426" },
            { name: "斯威士兰", nameEnglish: "Swaziland", areaCode: "748" },
            { name: "纳米比亚", nameEnglish: "Namibia", areaCode: "516" },
            { name: "马达加斯加", nameEnglish: "Madagascar", areaCode: "450" },
            { name: "塞舌尔", nameEnglish: "Seychelles", areaCode: "690" },
            { name: "科摩罗", nameEnglish: "Comoros", areaCode: "174" },
            { name: "毛里求斯", nameEnglish: "Mauritius", areaCode: "480" },
            { name: "佛得角", nameEnglish: "Cabo Verde", areaCode: "132" },
        ]
    }, {
        areaCode: "9",
        name: "北非",
        nameEnglish: "North Africa",
        children: [
            { name: "埃及", nameEnglish: "Egypt", areaCode: "818" },
            { name: "利比亚", nameEnglish: "Libya", areaCode: "434" },
            { name: "突尼斯", nameEnglish: "Tunisia", areaCode: "788" },
            { name: "阿尔及利亚", nameEnglish: "Algeria", areaCode: "012" },
            { name: "摩洛哥", nameEnglish: "Morocco", areaCode: "504" },
        ]
    }, {
        areaCode: "10",
        name: "北美",
        nameEnglish: "North America",
        children: [
            { name: "美国", nameEnglish: "USA", areaCode: "840" },
            { name: "加拿大", nameEnglish: "Canada", areaCode: "124" },
        ]
    }, {
        areaCode: "11",
        name: "拉丁美州",
        nameEnglish: "Latin America",
        children: [
            { name: "墨西哥", nameEnglish: "Mexico", areaCode: "484" },
            { name: "危地马拉", nameEnglish: "Guatemala", areaCode: "320" },
            { name: "洪都拉斯", nameEnglish: "Honduras", areaCode: "340" },
            { name: "伯利兹", nameEnglish: "Belize", areaCode: "084" },
            { name: "萨尔瓦多", nameEnglish: "El Salvador", areaCode: "222" },
            { name: "尼加拉瓜", nameEnglish: "Nicaragua", areaCode: "558" },
            { name: "哥斯达黎加", nameEnglish: "Costa Rica", areaCode: "188" },
            { name: "巴拿马", nameEnglish: "Panama", areaCode: "591" },
            { name: "古巴", nameEnglish: "Cuba", areaCode: "192" },
            { name: "海地", nameEnglish: "Haiti", areaCode: "332" },
            { name: "多米尼加共和国", nameEnglish: "Dominican Republic", areaCode: "214" },
            { name: "牙买加", nameEnglish: "Jamaica", areaCode: "388" },
            { name: "格林纳达", nameEnglish: "Grenada", areaCode: "308" },
            { name: "特立尼达和多巴哥", nameEnglish: "Trinidad", areaCode: "780" },
            { name: "巴巴多斯", nameEnglish: "Barbados", areaCode: "052" },
            { name: "安提瓜和巴布达", nameEnglish: "Antigua and Barbuda", areaCode: "028" },
            { name: "圣基茨和尼维斯", nameEnglish: "Saint Kitts and Nevis", areaCode: "659" },
            { name: "圣文森特和格林纳丁斯", nameEnglish: "Saint Vincent and the Grenadines", areaCode: "670" },
            { name: "圣卢西亚", nameEnglish: "St. Lucia", areaCode: "662" },
            { name: "多米尼克", nameEnglish: "Dominica", areaCode: "212" },
            { name: "巴哈马", nameEnglish: "Bahamas", areaCode: "044" },
            { name: "哥伦比亚", nameEnglish: "Colombia", areaCode: "170" },
            { name: "厄瓜多尔", nameEnglish: "Ecuador", areaCode: "218" },
            { name: "秘鲁", nameEnglish: "Peru", areaCode: "604" },
            { name: "智利", nameEnglish: "Chile", areaCode: "152" },
            { name: "圭亚那", nameEnglish: "French Guiana", areaCode: "328" },
            { name: "苏里南", nameEnglish: "Suriname", areaCode: "740" },
            { name: "委内瑞拉", nameEnglish: "Venezuela", areaCode: "862" },
            { name: "巴西", nameEnglish: "Brazil", areaCode: "076" },
            { name: "玻利维亚", nameEnglish: "Bolivia", areaCode: "068" },
            { name: "阿根廷", nameEnglish: "Argentina", areaCode: "032" },
            { name: "乌拉圭", nameEnglish: "Uruguay", areaCode: "858" },
            { name: "巴拉圭", nameEnglish: "Paraguay", areaCode: "600" },
        ]
    }, {
        areaCode: "12",
        name: "大洋洲",
        nameEnglish: "Oceania",
        children: [
            { name: "澳大利亚", nameEnglish: "Australia", areaCode: "036" },
            { name: "新西兰", nameEnglish: "New Zealand", areaCode: "554" },
            { name: "萨摩亚", nameEnglish: "American Samoa", areaCode: "016" },
            { name: "汤加", nameEnglish: "Tonga", areaCode: "776" },
            { name: "斐济", nameEnglish: "Fiji", areaCode: "242" },
            { name: "巴布亚新几内亚", nameEnglish: "Papua New Guinea", areaCode: "598" },
            { name: "所罗门群岛", nameEnglish: "Solomon Islands", areaCode: "090" },
            { name: "瓦努阿图", nameEnglish: "Vanuatu", areaCode: "548" },
            { name: "瑙鲁", nameEnglish: "Nauru", areaCode: "520" },
            { name: "基里巴斯", nameEnglish: "Kiribati", areaCode: "296" },
            { name: "密克罗尼西亚", nameEnglish: "Micronesia", areaCode: "583" },
            { name: "帕劳", nameEnglish: "Palau", areaCode: "585" },
            { name: "库克群岛", nameEnglish: "Cook Islands", areaCode: "184" },
            { name: "图瓦卢", nameEnglish: "Tuvalu", areaCode: "798" },
        ]
    }
]
Vue.prototype.$countryList = countryList