<template>
  <!-- 指令监控top搜索 -->
  <div class="search-instruction">
    <template v-if="!$route.path.includes('apollo')">
    <label for="equipment-id">设备ID：</label>
    <Input
      id="equipment-id"
      v-model.trim="euqipmentID"
      placeholder="请输入设备ID"
      style="width: 200px"
      @keyup.native.enter="searchInstruction"
    ></Input>
    &nbsp;
    </template>
    <label for="equipment-id">模块ID：</label>
    <Input
      id="equipment-id"
      v-model.trim="wifiID"
      placeholder="请输入模块ID"
      style="width: 200px"
      @keyup.native.enter="searchInstruction"
    ></Input>
    &nbsp;
    
    <label for="equipment-id">通讯IP：</label>
    <Input
      id="equipment-id"
      v-model.trim="communicationIP"
      placeholder="请输入通讯IP,如:192.168.0.1"
      style="width: 200px"
      @keyup.native.enter="searchInstruction"
    ></Input>
    &nbsp;
    <Button type="primary" @click="searchInstruction">查询</Button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wifiID: "",
      euqipmentID: "",
      communicationIP: "",
    };
  },
  methods: {
    // 搜索设备指令监控列表数据
    searchInstruction() {
      let param = {
        instructWifiid: this.wifiID,
        instructEquipmentid: this.euqipmentID,
        instructIp: this.communicationIP,
      };
      this.$emit("search-instruction", param);
    },
  },
};
</script>

<style lang="less" scoped>
.search-instruction {
  margin-bottom: 10px;
}
</style>