<template>
  <div class="edit-menu-box" v-if="mode">
    <Form
      ref="formItem"
      :model="formItem"
      :label-width="110"
      :rules="ruleValidate"
    >
      <FormItem label="上级菜单" v-if="disabledName" prop="parentName">
        <Input v-model="formItem.parentName" :disabled="disabledName"></Input>
      </FormItem>
      <FormItem label="功能名称" prop="name">
        <Input v-model.trim="formItem.name"></Input>
      </FormItem>
      <FormItem label="功能英文名称" prop="nameEnglish">
        <Input v-model.trim="formItem.nameEnglish"></Input>
      </FormItem>
      <FormItem label="菜单序号" prop="menuNumber">
        <Input v-model.trim="formItem.menuNumber" type="number"></Input>
      </FormItem>
      <FormItem label="菜单类型" prop="menuType">
        <Input v-model.trim="formItem.menuType" disabled></Input>
      </FormItem>
      <FormItem label="功能类型" prop="type">
        <RadioGroup v-model="formItem.type">
          <Radio label="module">功能模块</Radio>
          <Radio label="menu">功能菜单</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="功能控制器" prop="control">
        <Input v-model.trim="formItem.control"></Input>
      </FormItem>
      <FormItem label="功能执行器" prop="perform">
        <Input v-model.trim="formItem.perform"></Input>
      </FormItem>
      <!-- <FormItem label="图标名称" prop="iconName">
        <Input v-model.trim="formItem.iconName"></Input>
      </FormItem> -->
      <FormItem label="是否启用" prop="start">
        <RadioGroup v-model="formItem.start">
          <Radio label="enable">启用</Radio>
          <Radio label="stop">停用</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="备注说明" prop="note">
        <Input
          v-model.trim="formItem.note"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
        ></Input>
      </FormItem>
      <FormItem>
        <Button type="primary" @click="submit('formItem')">保存</Button>
        <Button style="margin-left: 8px" @click="cancel">返回</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  props: ["mode", "editList"],
  data() {
    return {
      formItem: {
        parentName: "",
        name: "", // 功能名称
        nameEnglish: "",
        type: "menu", // 功能类型
        control: "", // 功能控制器
        perform: "", // 功能执行器
        iconName: "", // 图标名称
        start: "enable", // 是否启用
        menuNumber: 1, // 菜单序号
        note: "", // 备注说明
        menuType: 1, // 菜单类型（一级、二级）
      },
      ruleValidate: {
        name: [
          {
            required: true,
            message: "请输入功能名称",
            trigger: "blur",
          },
        ],
        nameEnglish: [
          {
            required: true,
            message: "请输入功能英文名称",
            trigger: "blur",
          },
        ],
        control: [
          {
            required: true,
            message: "请输入功能控制器，如：AddSystemMenu",
            trigger: "blur",
          },
        ],
      },
      disabledName: false,
    };
  },
  created() {
    if (this.mode == "head") {
      this.formItem.menuType = 1;
    } else if (this.mode == "children") {
      this.formItem.menuType = 2;
      if (this.editList.parentId == "0") {
        this.formItem.parentName = this.editList.label;
      } else {
        this.$axios({
          url: this.apiUrl + "systemMenu/FindBySystemMenuId",
          method: "get",
          params: {
            id: this.editList.parentId,
          },
        })
          .then((res) => (this.formItem.parentName = res.data.data.menuName))
          .catch((err) => err);
      }
      this.disabledName = true;
    } else if (this.mode == "edit") {
      this.disabledName = Number(this.editList.parentId) == 0 ? false : true;
      this.formItem.name = this.editList.label;
      this.formItem.menuType = this.editList.value;
      if (this.disabledName) {
        this.getEditInfo(Number(this.editList.parentId));
      } else {
        this.getEditInfo(Number(this.editList.id));
      }
    }
  },
  methods: {
    submit(name) {
      let param = {
        menuName: this.formItem.name,
        menuType: this.formItem.menuType,
        menuEnglishname: this.formItem.nameEnglish,
        menuAction: this.formItem.perform,
        menuController: this.formItem.control,
        menuNum: this.formItem.menuNumber,
        remake: this.formItem.note,
        status: this.formItem.start == "enable",
      };
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.mode == "head") {
            param = {
              ...param,
              parentmenuId: "0",
            };
            this.addMenu(param);
          } else if (this.mode == "children") {
            if (this.editList.parentId == "0") {
              param = {
                ...param,
                parentmenuId: this.editList.id,
              };
            } else {
              param = {
                ...param,
                parentmenuId: this.editList.parentId, // 父级id
              };
            }
            // console.log(param);
            this.addMenu(param);
          } else if (this.mode == "edit") {
            //   this.disabledName=true
            // 修改菜单
            this.$axios({
              url: this.apiUrl + "systemMenu/EditSystemMenu",
              method: "post",
              params: {
                id: Number(this.editList.id),
                // ...param
                menuName: this.formItem.name,
                menuEnglishname: this.formItem.nameEnglish,
                menuController: this.formItem.control,
                menuAction: this.formItem.perform,
                status: this.formItem.start == "enable",
                menuNum: this.formItem.menuNumber,
                // parentmenuId:this.editList.parentId,
                remake: this.formItem.note,
                menuType: this.formItem.menuType,
              },
            })
              .then((res) => {
                // console.log(res);
                if (res.data.code == "203") {
                  this.$Message.success(res.data.msg);
                  this.$emit("shut-edit", false);
                } else {
                  this.$Message.error(res.data.msg);
                }
                // console.log(res);
              })
              .catch((err) => err);
          }
        } else {
          this.$Message.error(this.mode == "edit" ? "修改失败!" : "添加失败!");
        }
      });
    },
    // 添加菜单
    addMenu(param) {
      console.log(param);
      this.$axios({
        url: this.apiUrl + "systemMenu/AddSystemMenu",
        method: "post",
        params: param,
      }).then((res) => {
        // console.log(res);
        if (res.data.code == "201") {
          this.$Message.success(res.data.msg);
          this.$emit("shut-edit", false);
        } else {
          this.$Message.error(res.data.msg);
        }
      });
    },
    // 取消修改
    cancel() {
      this.$emit("shut-edit", false);
    },
    // 获取菜单数据接口
    getEditInfo(id) {
      this.$axios({
        url: this.apiUrl + "systemMenu/FindBySystemMenuId",
        method: "get",
        params: {
          id: id,
        },
      })
        .then((res) => {
          // console.log(res);
          let menuInfo = res.data.data;
          // console.log(menuInfo);
          this.formItem.name = menuInfo.menuName;
          this.formItem.nameEnglish = menuInfo.menuEnglishname;
          this.formItem.control = menuInfo.menuController;
          this.formItem.perform = menuInfo.menuAction;
          this.formItem.menuNumber = menuInfo.menuNum;
          this.formItem.menuType = menuInfo.menuType;
          this.formItem.note = menuInfo.remake;
          this.formItem.start = menuInfo.status ? "enable" : "stop";
          if (this.disabledName) {
            if (menuInfo.parentmenuId != 0) return;
            this.getEditInfo(this.editList.id);
            if (menuInfo.parentmenuId == 0) {
              this.formItem.parentName = menuInfo.menuName;
            }
          }
        })
        .catch((err) => err);
    },
  },
  watch: {
    // editList(newValue, oldValue) {
    //   if(newValue != oldValue){
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
</style>
<style lang="less">
.edit-menu-box {
  .ivu-input {
    width: auto;
  }
}
.ivu-form-item {
  margin-bottom: 12px;
}
</style>