<template>
  <div class="ms-login">
    <div class="ms-title">
      电源物联智能监控系统
      <br />
      <el-dropdown
        style="color: #fff; margin-left: 10px"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          简体中文<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="Chinese">简体中文</el-dropdown-item>
          <el-dropdown-item command="English">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-form :model="param" ref="login" label-width="0px" class="ms-content">
      <el-form-item prop="account">
        <el-input
          v-if="user == 'account'"
          v-model.trim="param.userAccount"
          placeholder="请输入账号"
        >
          <template #prepend>
            <el-button icon="el-icon-user" disabled></el-button>
          </template>
        </el-input>
        <el-input
          v-else-if="user == 'userphone'"
          v-model.trim="param.userPhone"
          placeholder="请输入手机号"
        >
          <template #prepend>
            <el-button icon="el-icon-mobile-phone" disabled></el-button>
          </template>
        </el-input>
        <el-input
          v-else-if="user == 'email'"
          v-model.trim="param.userEmail"
          placeholder="请输入邮箱"
        >
          <template #prepend>
            <el-button icon="el-icon-message" disabled></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model.trim="param.userPassword"
          show-password
          @keyup.native.enter="submitForm"
          placeholder="请输入密码"
          autocomplete="new-password"
        >
          <template #prepend>
            <el-button icon="el-icon-lock" disabled></el-button>
          </template>
        </el-input>
      </el-form-item>
      <div class="login-btn">
        <el-button type="primary" @click="submitForm">登录</el-button>
      </div>
      <p class="login-tips">
        Tips : 请输入正确的{{
          userLogin == "手机号"
            ? "账号"
            : userLogin == "邮箱"
            ? "手机号"
            : "邮箱"
        }}和密码。<el-link
          type="primary"
          style="color: #0f38a4"
          @click="userToggle"
          >使用{{ userLogin }}登录</el-link
        >
      </p>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["user", "userName"],
  data() {
    return {
      param: {
        userPassword: "",
        userPhone: "",
        userAccount: "",
        userEmail: "",
      },
    };
  },
  computed: {
    userLogin() {
      if (this.user == "userphone") return "邮箱";
      if (this.user == "account") return "手机号";
      if (this.user == "email") return "账号";
    },
  },

  methods: {
    // 提交登录信息
    submitForm() {
      if (this.user == "userphone") {
        if (this.param.userPassword == "" || this.param.userPhone == "") {
          return this.$alert("手机号或密码不能为空");
        } else {
          if (
            this.param.userPhone.length != 11 ||
            this.param.userPhone.search(/[^\d]/g) != -1
          ) {
            this.param = {
              userPassword: "",
              userPhone: "",
              userAccount: "",
              userEmail:""
            };
            return this.$alert("输入的手机号码不符合规范");
          }
        }
      } else if (this.user == "account") {
        if (this.param.userPassword == "" || this.param.userAccount == "")
          return this.$alert("账号或密码不能为空");
      } else if (this.user == "email") {
        // let reg=new RegExp(/^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/);
        let reg=new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/);
        // let reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (this.param.userPassword == "" || this.param.userEmail == "") {
          return this.$alert("邮箱或密码不能为空");
        }else {
          if (!reg.test(this.param.userEmail)) {
            this.param = {
              userPassword: "",
              userPhone: "",
              userAccount: "",
              userEmail:""
            };
            return this.$alert("输入的邮箱不符合规范");
          }
        }
      }
      let data = {};
      Object.values(this.param).forEach((item, index) => {
        if (item != "") {
          data[Object.keys(this.param)[index]] = item;
        }
      });
      
      console.log(this.apiUrl + "index/doLogin");
      this.$axios({
        url: this.apiUrl + "index/doLogin",
        method: "post",
        params: data,
        withCredentials: true,
        // param,
      })
        .then((res) => {
          console.log(res.headers.sessionid);
          console.log(res);
          if (res.data.status == 200) {
            if (res.data.data.organizationType == "1004") {
              return this.$message("账号无权限登录");
            }
            document.cookie = "Cookie="+res.headers.sessionId;
            this.$message.success(res.data.msg);
            this.$emit("login-name", res.data.data.name);
            this.$router.push("/home");
            window.sessionStorage.setItem(
              "userInfo",
              JSON.stringify(res.data.data)
            );
            this.$root.userInfo = res.data.data;
          } else {
            this.$alert(res.data.msg);
          }
          // console.log(this.$axios.header);
        })
        .catch((err) => this.$alert("服务器暂未开启"));
      // console.log(this.$route.path);
    },
    // 判断当前使用的是账号密码登录还是手机号密码登录
    userToggle() {
      if (this.user == "userphone") return (this.$parent.user = "email");
      if (this.user == "account") return (this.$parent.user = "userphone");
      if (this.user == "email") return (this.$parent.user = "account");
    },
    // 切换中英文界面
    handleCommand(command) {
      this.$emit("toggle-lang", command);
    },
  },
  watch: {
    user(newValue, oldValue) {
      if (newValue != oldValue) {
        this.param.userPassword = "";
        this.param.userPhone = "";
        this.param.userAccount = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ms-title {
  padding: 10px 0;
  width: 100%;
  //   line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 380px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>