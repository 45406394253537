<template>
  <!-- 经销商机构管理 -->
  <div class="dealer" v-if="language == 'Chinese'">
    <SearchInstitutionInfo
      :selectList="selectList"
      @update-list="institutionDealer == '' ? getDealer() : getDealerTerminal()"
      :addTitle="addTitleChinese"
      :institutionManufacturers="institutionDealer"
      :institutionsType="institutionsTypeChinese"
      :addUrl="institutionDealer == '' ? addDealerUrl : addDealerTerminalUrl"
      @search-list="
        institutionDealer == '' ? getDealer($event) : getDealerTerminal($event)
      "
      @renturn-list="renturnToList"
      :type="institutionsType"
      @delete-select="deleteDealer($event)"
      >机构名称：</SearchInstitutionInfo
    >
    <InstitutionsList
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :institutionList="dealerList"
      :loadingShow="loadingShow"
      :editUrl="institutionDealer == '' ?editDealeUrl:editDealeTerminaUrl"
      @select-list="selectList = $event"
      :institutionsType="institutionsTypeChinese"
      :type="institutionsType"
      @institutions-terminal="
        institutionDealer = $event;
        addTitleChinese='终端';
        getDealerTerminal();
      "
      :institutionManufacturers="institutionDealer"
      :institutionName="addTitleChinese"
      @delete-list="deleteDealer($event)"
      @updata-list="institutionDealer == '' ? getDealer() : getDealerTerminal()"
    />
  </div>
  <div class="dealer" v-else>
    <SearchInstitutionInfoEnglish
      :selectList="selectList"
      @update-list="institutionDealer == '' ? getDealer() : getDealerTerminal()"
      :addTitle="addTitleEnglish"
      :type="institutionsType"
      :addUrl="institutionDealer == '' ? addDealerUrl : addDealerTerminalUrl"
      :institutionManufacturers="institutionDealer"
      :institutionsType="institutionsTypeEnglish"
      @search-list="
        institutionDealer == '' ? getDealer($event) : getDealerTerminal($event)
      "
      @delete-select="deleteDealer($event)"
      >Institution Name：</SearchInstitutionInfoEnglish
    >
    <InstitutionsListEnglish
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :institutionName="addTitleEnglish"
      :institutionList="dealerList"
      :loadingShow="loadingShow"
      :editUrl="institutionDealer == '' ?editDealeUrl:editDealeTerminaUrl"
      @select-list="selectList = $event"
      :institutionsType="institutionsTypeEnglish"
      :type="institutionsType"
      :institutionManufacturers="institutionDealer"
      @institutions-terminal="
        institutionDealer = $event;
        addTitleChinese='Terminal';
        getDealerTerminal();
      "
      @delete-list="deleteDealer($event)"
      @updata-list="institutionDealer == '' ? getDealer() : getDealerTerminal()"
    />
  </div>
</template>

<script>
import SearchInstitutionInfo from "../../components/system/Chinese/institutions/SearchInstitutionInfo.vue";
import InstitutionsList from "../../components/system/Chinese/institutions/InstitutionsList.vue";
import SearchInstitutionInfoEnglish from "../../components/system/English/institutions/SearchInstitutionInfoEnglish.vue";
import InstitutionsListEnglish from "../../components/system/English/institutions/InstitutionsListEnglish.vue";
export default {
  components: {
    SearchInstitutionInfo,
    InstitutionsList,
    SearchInstitutionInfoEnglish,
    InstitutionsListEnglish,
  },
  props: ["language"],
  data() {
    return {
      dealerList: [],
      count: 0,
      index: 1,
      pageLimit: 10,
      loadingShow: true,
      selectList: [],
      // 机构列表查询
      // institutionsUrl:"mechanism/mechanismList",
      dealerUrl: "mechanismDirectly/mechanismDistributorList",
      addDealerUrl: "mechanismDirectly/SaveMechanismDistributor",
      editDealeUrl: "mechanismDirectly/updateMechanismDistributor",
      dealerTerminalUrl: "mechanismDirectly/mechanismSubordinateTerminalList",
      addDealerTerminalUrl: "mechanismDirectly/SaveMechanismSubordinateTermina",
      editDealeTerminaUrl:"mechanism/updateMechanism",
      addTitleChinese: "经销商",
      institutionsType: "1003",
      addTitleEnglish: "Dealer",
      institutionDealer: "",
      institutionsTypeChinese: "经销商",
      institutionsTypeEnglish: "Dealer",
      searchParam: {},
    };
  },
  created() {
    this.getDealer();
  },
  methods: {
    // 获取经销商机构列表数据
    getDealer(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.searchParam = param?param:this.searchParam;
      this.$axios({
        /* url: this.apiUrl + this.institutionsUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismType: this.institutionsType,
          id: this.$root.userInfo.userMechanismFk,
          ...this.searchParam,
        }, */
        url: this.apiUrl + this.dealerUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismParentFk: this.$root.userInfo.userMechanismFk,
          mechanismType: "1003",
          userMechanismType:this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          // console.log(res);
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.dealerList = res.data.data;
            this.count = res.data.count;
          }else{
            this.dealerList = []
          }
        })
        .catch((err) => {
          this.dealerList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 查询从属终端
    getDealerTerminal(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.institutionsType = "1004";
      this.searchParam = param?param:this.searchParam;
      this.$axios({
        /* url: this.apiUrl + this.institutionsUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismType: this.institutionsType,
          id: this.institutionDealer.id,
          ...this.searchParam,
        }, */
        url: this.apiUrl + this.dealerTerminalUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismSubordinateDistrFk: this.institutionDealer.id,
          userMechanismType:this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.dealerList = res.data.data;
            this.count = res.data.count;
          }else{
            this.dealerList = []
          }
        })
        .catch((err) => {
          this.dealerList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 删除直属机构经销商
    deleteDealer(row) {
      let url; 
      if(this.institutionDealer != ""){
        url = "mechanismDirectly/delMechanismTerminalById"
      }else{
        url = "mechanismDirectly/delMechanismDistributorById"
      }
      this.$axios({
        url: this.apiUrl + url,
        method: "post",
        params: {
          id: row.id,
        },
      })
        .then((res) => {
          if (res.data.status == 200) {
            // this.$message.success(res.data.msg);
            this.institutionDealer == ""
              ? this.getDealer()
              : this.getDealerTerminal();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 返回上一级
    renturnToList() {
      this.institutionDealer = "";
      this.institutionsType = "1003";
      this.addTitleChinese="经销商";
      this.addTitleEnglish="Dealer"
      this.getDealer();
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        if(this.institutionDealer!=''){
          this.getDealerTerminal()
          }else{
            this.getDealer();
        }
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        if(this.institutionDealer!=''){
          this.getDealerTerminal()
          }else{
            this.getDealer();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>