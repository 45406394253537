<template>
  <!-- 机构管理列表 -->
  <div class="institutions-box">
    <el-dialog
      title="Agency Information"
      :visible.sync="$parent.dialogFormVisible"
    >
      <div slot="title" style="fon-size: 18px">
        <slot></slot>
      </div>
      <el-form :model="form">
        <el-form-item label="AgencyName" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            v-model.trim="form.name"
            autocomplete="off"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="Contact" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            v-model.trim="form.contacts"
            autocomplete="off"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="Email" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            type="email"
            v-model.trim="form.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Phone" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            type="tel"
            v-model.trim="form.phone"
            autocomplete="off"
            required
            :maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="Remark" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model.trim="form.notes"
            autocomplete="off"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="Address" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model.trim="form.address"
            autocomplete="off"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="AgencyType" :label-width="formLabelWidth">
          <el-select
            style="width: 200px"
            v-model="form.type"
            placeholder="Agency Type"
            disabled
            :value="form.type"
          >
            <el-option label="Manufacturer" value="1000"></el-option>
            <el-option label="Junior Administrator" value="1002"></el-option>
            <el-option label="Dealers" value="1003"></el-option>
            <el-option label="Terminal" value="1004"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$parent.dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submit">Sure</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialogFormVisible", "editList", "message", "typeNum", "userList"],
  data() {
    return {
      form: {
        name: "",
        type: this.typeNum,
        contacts: "",
        phone: "",
        email: "",
        notes: "",
        address: "",
      },
      param: {
        mechanismName:"",
        mechanismType:"",
        mechanismContacts:"",
        mechanismPhone:"",
        mechanismEmail:"",
        mechanismNotes:"",
        address:""
      },
      formLabelWidth: "100px",
      addUrl: "mechanism/saveMechanism",
      editUrl: "mechanism/updateMechanism",
    };
  },
  created() {
    if (this.message != "add") {
      this.setInstitutionInfo();
    }
  },
  methods: {
    // 设置编辑机构的默认信息
    setInstitutionInfo() {
      if (this.editList) {
        this.from = {
          name: this.editList.mechanismName,
          type:
            this.editList.mechanismType == "1001"
              ? "1000"
              : this.editList.mechanismType,
          contacts: this.editList.mechanismContacts,
          phone: this.editList.mechanismPhone,
          email: this.editList.mechanismEmail,
          notes: this.editList.mechanismNotes,
          address: this.editList.address,
        };
      }
    },
    // 编辑机构信息或添加机构信息提交后调用的方法
    submit() {
      if (
        this.form.name == "" ||
        this.form.phone == "" ||
        this.form.contacts == ""
      )
        return this.$alert(
          "Agency name, contact, and contact number cannot be empty "
        );
      if (
        this.form.phone.length != 11 ||
        this.form.phone.search(/[^\d]/g) != -1
      ) {
        this.setInstitutionInfo();
        return this.$alert(
          "The entered mobile phone number does not meet the specification"
        );
      }
      if (this.form.email != "") {
        var re =
          /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if (!re.test(this.form.email)) {
          return this.$alert("The mailbox format entered is incorrect");
        }
      }
      this.param = {
        ...this.param,
        mechanismName: this.form.name,
        mechanismType: this.form.type == "1000" ? "1001" : this.form.type,
        mechanismContacts: this.form.contacts,
        mechanismPhone: this.form.phone,
        mechanismEmail: this.form.email,
        mechanismNotes: this.form.notes,
      };
      // 判断是添加机构信息还是编辑机构信息
      let url;
      if (this.message == "add") {
        url = this.apiUrl + this.addUrl;
        this.param = {
          ...this.param,
        };
      } else {
        url = this.apiUrl + this.editUrl;
        this.param = {
          id: this.editList ? this.editList.id : this.id,
          ...this.param,
          mechanismAddress: this.form.address?this.form.address:"",
        };
      }
      // 向后台传输数据，添加或编辑机构信息
      this.$axios({
        url: url,
        method: "post",
        params: this.param,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.updateList();
          } else {
            this.$message.error(res.data.msg);
          }
          this.form={
            name: "",
            type: this.typeNum,
            contacts: "",
            phone: "",
            email: "",
            notes: "",
            address: "",
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 更新机构信息列表
    updateList() {
      this.$emit("update-list");
      this.$parent.dialogFormVisible = false;
    },
    // 更新参数和url
    updateAddUrl(newValue, oldValue) {
      if (this.typeNum == "1000" || newValue == "1000") {
        this.addUrl = "mechanism/saveMechanism";
        this.param = {
          ...this.param,
          mechanismParentFk: this.id ? this.id : this.$root.userInfo.userId,
        };
        return;
      }
      // 向下层级
      if (oldValue == "1000") {
        // 上级为厂家
        if (newValue == "1002") {
          // 初级管理员
          this.addUrl = "mechanismDirectly/SaveMechanismmPrimaryAdmin";
          this.param = {
            ...this.param,
            mechanismParentFk: this.id,
            address: this.form.address,
          };
        } else if (newValue == "1003") {
          // 经销商
          this.addUrl = "mechanismDirectly/SaveMechanismDistributor";
          this.param = {
            ...this.param,
            mechanismParentFk: this.id,
            address: this.form.address,
          };
        } else if (newValue == "1004") {
          // 终端
          this.addUrl = "mechanismDirectly/SaveMechanismmTerminal";
          this.param = {
            ...this.param,
            mechanismParentFk: this.id,
            address: this.form.address,
          };
        }
      } else if (oldValue == "1002") {
        // 上级为初级管理员
        if (newValue == "1003") {
          // 经销商
          this.addUrl = "mechanismDirectly/SaveMechanismDistributor";
          this.param = {
            ...this.param,
            mechanismSubordinateAdminFk: this.id,
            address: this.form.address,
          };
        } else if (newValue == "1004") {
          // 终端
          this.addUrl = "mechanismDirectly/SaveMechanismmTerminal";
          this.param = {
            ...this.param,
            mechanismParentFk: this.id,
            address: this.form.address,
          };
        }
      } else if (oldValue == "1003") {
        // 上级为经销商
        if (newValue == "1004") {
          // 终端
          if (this.userList.every((item) => item.mechanismSubordinateAdminFk)) {
            this.addUrl = "mechanismDirectly/SaveMechanismmTerminal";
            this.param = {
              ...this.param,
              mechanismSubordinateAdminFk: this.id,
              address: this.form.address,
            };
          } else {
            this.addUrl = "mechanismDirectly/SaveMechanismSubordinateTermina";
            this.param = {
              ...this.param,
              mechanismSubordinateDistrFk: this.id,
              address: this.form.address,
            };
          }
        } else if (newValue == "1002") {
          this.addUrl = "mechanismDirectly/SaveMechanismmPrimaryAdmin";
          this.param = {
            ...this.param,
            mechanismParentFk: this.id,
            address: this.form.address,
          };
        }
      } // 向上层级
      else if (oldValue == "1004") {
        if (newValue == "1003") {
          this.addUrl = "mechanismDirectly/SaveMechanismDistributor";
          // 上级是初级管理员或厂家（默认为初级管理员）
          if (this.userList.every((item) => item.mechanismParentFk)) {
            this.param = {
              ...this.param,
              mechanismSubordinateDistrFk: this.id,
              address: this.form.address,
            };
          } else {
            this.param = {
              ...this.param,
              mechanismSubordinateAdminFk: this.id,
              address: this.form.address,
            };
          }
        } else if (newValue == "1002") {
          this.addUrl = "mechanismDirectly/SaveMechanismmPrimaryAdmin";
          this.param = {
            ...this.param,
            mechanismParentFk: this.id,
            address: this.form.address,
          };
        }
      }
    },
  },
  watch: {
    editList(newValue, oldValue) {
      // console.log(newValue != oldValue);
      if (newValue != oldValue && newValue) {
        this.form = {
          name: newValue.mechanismName,
          type:
            newValue.mechanismType == "1001" ? "1000" : newValue.mechanismType,
          // parentFk: newValue.mechanismParentFk,
          contacts: newValue.mechanismContacts,
          phone: newValue.mechanismPhone,
          email: newValue.mechanismEmail,
          notes: newValue.mechanismNotes,
          address: newValue.address,
        };
      } else if (newValue && newValue.id != oldValue.id) {
        console.log(newValue.id != oldValue.id);
      }
    },
    dialogFormVisible(newValue, oldValue) {
      if (newValue) {
        this.form.type = this.typeNum;
        if (this.editList) {
          this.form = {
            name: this.editList.mechanismName,
            type:
              this.editList.mechanismType == "1001"
                ? "1000"
                : this.editList.mechanismType,
            // parentFk: this.editList.mechanismParentFk,
            contacts: this.editList.mechanismContacts,
            phone: this.editList.mechanismPhone,
            email: this.editList.mechanismEmail,
            notes: this.editList.mechanismNotes,
            address: this.editList.address,
          };
        }
      }
    },
    typeNum(newValue, oldValue) {
      this.param = {
        mechanismName: "",
        mechanismType: "",
        mechanismContacts: "",
        mechanismPhone: "",
        mechanismEmail: "",
        mechanismNotes: "",
        address:""
      };
      if (this.message == "add") {
        this.updateAddUrl(newValue, oldValue);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-form-item__content {
    width: 50%;
  }
}
</style>