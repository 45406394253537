<template>
  <!-- 机构管理 -->
  <div class="institutions" v-if="language == 'Chinese'">
    <SearchInstitutions
      @update-list="newUserList"
      @handle-delete="handleDelete(multipleSelection)"
      @search-list="newUserList($event)"
      :typeNum="typeNum"
      :id="id"
      :hierarchy="hierarchy"
      :userList="userList"
    />
    <InstitutionsList
      :institutionList="userList"
      :typeNum="typeNum"
      :hierarchy="hierarchy"
      :id="id"
      :loadingShow="loadingShow"
      :show="show"
      @select-change="handleSelectionChange($event)"
      @update-child="newUserList($event)"
      @update-list="newUserList"
      @delete-list="handleDelete($event)"
      @edit-list="handleEdit($event)"
    />
    <Page
      ref="page"
      :total="count"
      :current.sync="index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="pageLimit = $event"
    />
    <InstitutionsBox
      :dialogFormVisible="dialogFormVisible"
      @update-list="newUserList"
      :editList="editList"
      :typeNum="typeNum"
      :id="id"
      message="amend"
      :userList="userList"
      >编辑机构信息</InstitutionsBox
    >
  </div>
  <div class="institutions" v-else>
    <SearchInstitutionsEnglish
      @update-list="newUserList"
      @handle-delete="handleDelete(multipleSelection)"
      @search-list="newUserList($event)"
      :typeNum="typeNum"
      :id="id"
      :hierarchy="hierarchy"
      :userList="userList"
    />
    <InstitutionsListEnglish
      :institutionList="userList"
      :typeNum="typeNum"
      :hierarchy="hierarchy"
      :id="id"
      :loadingShow="loadingShow"
      :show="show"
      @select-change="handleSelectionChange($event)"
      @update-child="newUserList($event)"
      @update-list="newUserList"
      @delete-list="handleDelete($event)"
      @edit-list="handleEdit($event)"
    />
    <!-- <Page
      ref="page"
      :total="count"
      :current.sync="index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="pageLimit = $event"
    ><slot>
      {{count}} in total
    </slot>
    </Page> -->
    <div style="display:flex;margin-top: 10px;align-content: center">
    <Page
      ref="page"
      :total="count"
      :current.sync="index"
      show-total
    ><slot>
      {{count}} in total
    </slot>
    </Page>
     <el-pagination layout="slot">
      <p class="ivu-select" style="font-weight:500">
        <Select v-model="pageLimit" style="width:auto;">
          <Option :value="10">10 pieces/page</Option>
          <Option :value="20">20 pieces/page</Option>
          <Option :value="30">30 pieces/page</Option>
          <Option :value="40">40 pieces/page</Option>
        </Select>
      </p>
    </el-pagination>
     <el-pagination layout="slot">
      <p class="ivu-page-options-elevator" style="font-weight:500">
        Jump to 
        <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
        pages
      </p>
    </el-pagination>
    </div>
    <InstitutionsBoxEnglish
      :dialogFormVisible="dialogFormVisible"
      @update-list="newUserList"
      :editList="editList"
      :typeNum="typeNum"
      :id="id"
      message="amend"
      :userList="userList"
      >Edit Institution Info</InstitutionsBoxEnglish
    >
  </div>
</template>

<script>
import SearchInstitutions from "../../components/system/Chinese/SearchInstitutions.vue";
import InstitutionsBox from "../../components/system/Chinese/institutions/InstitutionsBox.vue";
import InstitutionsList from "../../components/system/Chinese/InstitutionsList.vue";
import SearchInstitutionsEnglish from "../../components/system/English/SearchInstitutionsEnglish.vue";
import InstitutionsBoxEnglish from "../../components/system/English/institutions/InstitutionsBoxEnglish.vue";
import InstitutionsListEnglish from "../../components/system/English/InstitutionsListEnglish.vue";

export default {
  props: ["language"],
  data() {
    return {
      index: 1,
      userList: [],
      count: 0,
      pageLimit: 10,
      typeNum: this.$root.userInfo.organizationType,
      dialogFormVisible: false,
      multipleSelection: [],
      editList: null,
      id: "",
      hierarchy: 0,
      loadingShow: true,
      show: [false, false, false],
      // institutionUrl: "mechanism/subordinateList",
      institutionUrl: "mechanism/mechanismList",
      searchParam: {},
      currentPage:this.index,
    };
  },
  components: {
    SearchInstitutions,
    InstitutionsBox,
    InstitutionsList,
    SearchInstitutionsEnglish,
    InstitutionsBoxEnglish,
    InstitutionsListEnglish,
  },
  created() {
    this.newUserList();
    if (this.typeNum == "1002") {
      this.show = [false, true, true];
    } else if (this.typeNum == "1003") {
      this.show = [false, false, true];
    } else if (this.typeNum == "1004") {
      this.show = [false, false, false];
    } else {
      this.show = [true, true, true];
    }
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.index = this.index;
        this.currentPage = this.index;
      }else{
        this.index = this.currentPage
      }
    },
    // 编辑机构信息
    handleEdit(row) {
      // console.log(row);
      // 打开编辑机构信息面板
      this.dialogFormVisible = true;
      // 获取需要编辑的机构信息
      this.editList = row;
    },
    // 删除机构信息
    handleDelete(row) {
      // console.log(row);
      this.$confirm("是否确定删除以及下级机构信息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 判断需要删除的机构信息是多个或单个
          if (Array.isArray(row)) {
            row.forEach((item) => this.deleteInstitutions(item));
          } else {
            this.deleteInstitutions(row);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 向后台传输所需删除的机构信息
    deleteInstitutions(row) {
      let url;
      if (this.typeNum == "1000") {
        url = "mechanism/delMechanismById";
      } else if (this.typeNum == "1002") {
        url = "mechanismDirectly/delMechanismPrimaryAdminById";
      } else if (this.typeNum == "1003") {
        url = "mechanismDirectly/delMechanismDistributorById";
      } else if (this.typeNum == "1004") {
        url = "mechanismDirectly/delMechanismTerminalById";
      }
      this.$axios({
        url: this.apiUrl + url,
        method: "post",
        params: {
          id: row.id,
        },
      }).then((res) => {
        // console.log(res);
        if (res.data.status == 200) {
          this.newUserList();
        }
      });
    },
    // 获取页码数，并根据页码数获取该页码的页面数据
    handleCurrentChange(val) {
      this.index = val;
      this.newUserList();
    },
    // 更新机构信息列表
    newUserList(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.searchParam = param ? param : this.searchParam;
      this.loadingShow = true;
      this.$axios({
        url: this.apiUrl + this.institutionUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismType: this.typeNum,
          id: this.id,
          ...this.searchParam,
        },
      })
        .then((res) => {
          // console.log(res);
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.userList = res.data.data;
            this.count = res.data.count;
          }else{
            this.userList = []
          }
        })
        .catch((err) => {
          this.userList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 获取多选的列表数据
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    // 返回上一级
    returnGo() {
      if (this.typeNum != "1004") {
        this.show.splice(2, 1, true);
        if (this.typeNum != "1002" && this.typeNum != "1003") {
          this.show.splice(1, 1, true);
          if (this.typeNum == "1000") {
            this.show.splice(0, 1, true);
          }
        } else if (this.typeNum == "1002") {
          this.show.splice(1, 1, true);
        }
      }
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.newUserList();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.newUserList();
      }
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
    dialogFormVisible(newValue) {
      if (!newValue) return (this.editList = null);
    },
    typeNum(newValue, oldValue) {
      if (newValue != oldValue) {
        this.searchParam.id = this.id;
        this.searchParam.mechanismType = newValue;
        if (this.id == "") {
          this.newUserList();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>