<template>
  <!-- 类型码、基础码搜索 -->
  <div class="search-code">
    <label for="search-code-type"><slot></slot></label>
    <Input id="search-code-type" v-model.trim="codeType" style="width: auto" @keyup.native.enter="searchCode" />
    &nbsp;&nbsp;
    <Button type="primary" icon="ios-search" @click="searchCode">Inquire</Button
    >&nbsp; <Button type="primary" @click="addCode">Add</Button>&nbsp;
    <Button type="primary" @click="deleteCode">Delete</Button>
    <Modal
      v-model="addCodeShow"
      title="Add"
      ok-text="Confirm"
      cancel-text="Cancel"
      @on-ok="addSubmit"
      @on-cancel="addCancel"
    >
      <Form :model="addFrom" :label-width="100" label-colon>
        <FormItem label="Type Number">
          <Input v-model.trim="addFrom.type" style="width:auto"></Input>
        </FormItem>
        <FormItem :label="code == 'Type Code' ? 'Type Name' : 'Code Name'">
          <Input v-model.trim="addFrom.name" style="width:auto"></Input>
        </FormItem>
        <FormItem
          :label="code == 'Type Code' ? 'Type English Name' : 'Code English Name'"
        >
          <Input v-model.trim="addFrom.nameEnglish" style="width:auto"></Input>
        </FormItem>
        <template v-if="code == 'Basic Code'">
          <FormItem label="Number">
            <Input v-model.trim="addFrom.barCode" style="width:auto"></Input>
          </FormItem>
          <FormItem label="Serial Number">
            <Input v-model.trim="addFrom.serialNumber" style="width:auto"></Input>
          </FormItem>
          <FormItem label="Classification">
            <Input v-model.trim="addFrom.classify" style="width:auto"></Input>
          </FormItem>
        </template>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: ["selectCode", "code"],
  data() {
    return {
      codeType: "",
      addCodeShow: false,
      addFrom: {
        type: "",
        name: "",
        nameEnglish: "",
        barCode: "",
        serialNumber: "",
        classify: "",
      },
    };
  },
  methods: {
    // 搜索类型码或基础码
    searchCode() {
      let param = {
        type: this.codeType,
      };
      this.$emit("search-code", param);
    },
    // 添加类型码或基础码
    addCode() {
      this.addCodeShow = true;
    },
    // 删除类型码或基础码
    deleteCode() {
      this.$confirm("Are you sure to delete").then(()=>{
        // this.selectCode.forEach(item=>{
        //   this.axios({
        //     url:this.apiUrl+"",
        //     methods:"post",
        //     params:{
        //       id:item.id
        //     }
        //   }).then(res=>{
        //     console.log(res);
        //     this.$emit("updata-code")
        //   }).catch(err=>err)
        // })
      }).catch(()=>{
        this.$message.info("Cancel Delete")
      })
    },
    // 添加提交类型码或基础码
    addSubmit() {},
    // 取消提交
    addCancel() {},
  },
};
</script>

<style lang="less" scoped>
.search-code {
  padding-bottom: 10px;
}
</style>