<template>
  <!-- 设备分配top搜索 -->
  <div class="search-distribution">
    <template v-if="!$route.path.includes('apollo')">
    <label for="search-equipmentID">DeviceID：</label>
    <Input
      id="search-equipmentID"
      v-model.trim="equipmentID"
      placeholder="Please enter deviceID"
      style="width: 200px"
      @keyup.native.enter="searchDistribution"
    ></Input>
    &nbsp;
    </template>
    <label for="search-wifiID">ModuleID：</label>
    <Input
      id="search-wifiID"
      v-model.trim="WifiID"
      placeholder="Please enter ModuleID"
      style="width: 200px"
      @keyup.native.enter="searchDistribution"
    ></Input>
    &nbsp;
    
    <label for="search-manufacturer" v-if="$root.userInfo.organizationType=='1000'">NameManufacturer：</label>
    <Select id="search-manufacturer" v-if="$root.userInfo.organizationType=='1000'" v-model="supplier" style="width: 200px">
      <Option value="All">All</Option>
      <Option v-for="item in supplierList" :value="item.id" :key="item.id">{{
        item.mechanismName
      }}</Option>
    </Select>
    &nbsp;
    <Button type="primary" icon="ios-search" @click="searchDistribution"
      >Inquiry</Button
    >
    &nbsp;
    <Button slot="trigger" type="primary" @click="distributionTemplate" v-if="$root.userInfo.organizationType=='1000'||$root.userInfo.organizationType=='1001'"
      >Allocation Template Download</Button
    >
    &nbsp;
    <el-upload
      class="upload-demo"
      ref="uploaddistribution"
      v-if="$root.userInfo.organizationType=='1000'||$root.userInfo.organizationType=='1001'"
      :action="apiUrl + fileUploadUrl"
      :file-list="fileList"
      :auto-upload="false"
      :on-change="submitUpload"
      :limit="1"
      list-type="xcl"
      :on-success="successFile"
    >
      <!-- :on-preview="handlePreview"
      :on-remove="handleRemove" -->
      <Button slot="trigger" type="primary">Batch allocation</Button>
      <span slot="tip" class="el-upload__tip"> Only excel can be uploaded </span>
    </el-upload>
    &nbsp;
    <el-upload
      class="upload-demo"
      ref="uploadadministrator"
      v-if="$root.userInfo.organizationType=='1000'||$root.userInfo.organizationType=='1001'"
      :action="apiUrl + administratorUrl"
      :file-list="administratorList"
      :auto-upload="false"
      :on-change="submitAdministratorUpload"
      :limit="1"
      list-type="xcl"
      :on-success="successAdministratorFile"
    >
      <!-- :on-preview="handlePreview"
      :on-remove="handleRemove" -->
      <Button slot="trigger" type="primary">Batch allocation manager</Button>
      <span slot="tip" class="el-upload__tip">  Only excel can be uploaded </span>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: ["fileUploadUrl", "templateUrl","administratorUrl"],
  data() {
    return {
      WifiID: "",
      equipmentID: "",
      supplier: "All",
      fileList: [],
      supplierList: [],
      administratorList:[]
    };
  },
  created() {
    // 获取厂家名称列表
    this.$axios({
      url: this.apiUrl + "mechanism/mechanismChildrenByEquipId",
      method: "get",
      params: {
        mechanismType: this.$root.userInfo.organizationType,
      },
    })
      .then((res) => {
        // console.log(res);
        this.supplierList = res.data.data;
      })
      .catch((err) => err);
  },
  methods: {
    // 搜索注册设备列表数据
    searchDistribution() {
      let param = {
        wifiId: this.WifiID,
        equipmentId: this.equipmentID,
        equipmentFactoryFk: this.supplier=="All"?this.$root.userInfo.organizationId:this.supplier,
      };
      this.$emit("search-distribution", param);
    },
    // 上传文件成功后的回调
    successFile(res, file, fileList) {
      console.log(res);
      if (res.code == 200) {
        this.$message.success(res.msg)
        this.$emit("updata-list");
        this.fileList = [];
      }else{
        this.$message.error(res.msg)
        this.fileList = [];
      }
    },
    // 上传文件成功后的回调
    successAdministratorFile(res, file, fileList) {
      console.log(res);
      if (res.code == 200) {
        this.$message.success(res.msg)
        this.$emit("updata-list");
        this.administratorList = [];
      }else{
        this.$message.error(res.msg)
        this.administratorList = [];
      }
    },
    // 提交上传
    submitUpload() {
      this.$refs.uploaddistribution.submit();
    },
     // 提交上传
     submitAdministratorUpload() {
      this.$refs.uploadadministrator.submit();
    },
    // 批量分配模板下载
    distributionTemplate() {
      this.$axios({
        url: this.apiUrl + this.templateUrl,
        method: "post",
        params: {
          type: "fp",
        },
        withCredentials: true,
        responseType: "blob",
      })
        .then((res) => {
          // console.log(res);
          const link = document.createElement("a");
          let blob = new Blob([res.data], {
            type: "application/x-excel",
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "Batch-allocation-template.xls";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          this.$Notice.error({
            title: "Error",
            desc: "Network connection error",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.search-distribution {
  margin-bottom: 10px;
  .upload-demo {
    display: inline-block;
    .el-upload-list {
      &.el-upload-list--xcl {
        display: none;
      }
    }
  }
}
</style>
<style lang="less">
.search-distribution {
  .upload-demo {
    .el-upload-list {
      &.el-upload-list--xcl {
        // display: none;
        display: inline-block;
        li {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
}
</style>