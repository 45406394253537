<template>
  <!-- 系统菜单 -->
  <div v-if="language == 'Chinese'" class="system-menu">
    <template v-if="!showEdit">
      <EditMenuList
        @edit-list="
          showEdit = $event.show;
          mode = $event.mode;
        "
        @delect-list="$emit('update-nav');getMenu()"
        :editList="editList"
      /><br />
      <MenuList :menuList="menuList" @select-menu="selectObj($event)" />
    </template>
    <template v-else>
      <EditMenuBox
        @shut-edit="shutEdit($event)"
        :mode="mode"
        :editList="editList"
      />
    </template>
  </div>
  <div v-else class="system-menu">
    <template v-if="!showEdit">
      <EditMenuListEnglish
        @edit-list="
          showEdit = $event.show;
          mode = $event.mode;
        "
        @delect-list="$emit('update-nav');getMenu()"
        :editList="editList"
      /><br />
      <MenuListEnglish :menuList="menuList" @select-menu="selectObj($event)" />
    </template>
    <template v-else>
      <EditMenuBoxEnglish
        @shut-edit="shutEdit($event)"
        :mode="mode"
        :editList="editList"
      />
    </template>
  </div>
</template>

<script>
import MenuList from "../../components/system/Chinese/system-menu/MenuList.vue";
import EditMenuList from "../../components/system/Chinese/system-menu/EditMenuList.vue";
import EditMenuBox from "../../components/system/Chinese/system-menu/EditMenuBox.vue";
import MenuListEnglish from "../../components/system/English/system-menu/MenuListEnglish.vue";
import EditMenuListEnglish from "../../components/system/English/system-menu/EditMenuListEnglish.vue";
import EditMenuBoxEnglish from "../../components/system/English/system-menu/EditMenuBoxEnglish.vue";
export default {
  props: ["language"],
  components: {
    MenuList,
    EditMenuList,
    EditMenuBox,
    MenuListEnglish,
    EditMenuListEnglish,
    EditMenuBoxEnglish,
  },
  data() {
    return {
      menuList: [], // 树形菜单
      showEdit: false,
      mode: "", // 新增首级菜单|新增子级菜单 | 编辑菜单
      // parent: "",
      editList: null,
    };
  },
  created() {
    this.getMenu();
  },
  methods: {
    // 获取菜单数据
    getMenu() {
      let roleId = this.$root.userInfo.userId;
      this.$axios({
        url: this.apiUrl + "systemMenu/FindAllSystemMenuRoleList",
        method: "get",
        params: {
          RoleID: roleId,
        },
      })
        .then((res) => {
          // console.log(res);
          this.menuList = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    // 多选返回
    selectObj(obj) {
      this.editList = obj;
      // this.parent = obj.parent;
    },
    // 添加修改返回
    shutEdit(show) {
      this.showEdit = show;
      this.editList = null;
      this.getMenu();
      this.$emit("update-nav");
    },
  },
};
</script>

<style lang="less" scoped>
</style>