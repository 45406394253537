<template>
  <div class="code-list">
    <Table
      border
      :loading="loadingShow"
      :columns="codeListTitle"
      :no-data-text="'No Data'"
      :data="codeList"
      @on-selection-change="selectChangeCode"
    >
      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" @click="editCode(row, index)"
          >Edit</Button
        >
      </template>
    </Table>
    <!-- <Page
      ref="page"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    ><slot>
      {{count}} in total
    </slot>
    </Page> -->
    <div style="display:flex;margin-top: 10px;align-content: center">
    <Page
      ref="page"
      :total="count"
      :current.sync="$parent.index"
      show-total
    ><slot>
      {{count}} in total
    </slot>
    </Page>
     <el-pagination layout="slot">
      <p class="ivu-select" style="font-weight:500">
        <Select v-model="$parent.pageLimit" style="width:auto;">
          <Option :value="10">10 pieces/page</Option>
          <Option :value="20">20 pieces/page</Option>
          <Option :value="30">30 pieces/page</Option>
          <Option :value="40">40 pieces/page</Option>
        </Select>
      </p>
    </el-pagination>
     <el-pagination layout="slot">
      <p class="ivu-page-options-elevator" style="font-weight:500">
        Jump to 
        <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
        pages
      </p>
    </el-pagination>
    </div>
    <Modal
      v-model="addCodeShow"
      title="Edit"
      ok-text="Confirm"
      cancel-text="Cancel"
      @on-ok="addSubmit"
      @on-cancel="addCancel"
    >
      <Form :model="addFrom" :label-width="100" label-colon>
        <FormItem label="Type Number">
          <Input v-model.trim="addFrom.type" style="width: auto"></Input>
        </FormItem>
        <FormItem :label="code == 'Type Code' ? 'Type Name' : 'Code Name'">
          <Input v-model.trim="addFrom.name" style="width: auto"></Input>
        </FormItem>
        <FormItem
          :label="
            code == 'Type Code' ? 'Type English Name' : 'Code English Name'
          "
        >
          <Input v-model.trim="addFrom.nameEnglish" style="width: auto"></Input>
        </FormItem>
        <template v-if="code == 'Basic Code'">
          <FormItem label="Number">
            <Input v-model.trim="addFrom.barCode" style="width: auto"></Input>
          </FormItem>
          <FormItem label="Serial Number">
            <Input
              v-model.trim="addFrom.serialNumber"
              style="width: auto"
            ></Input>
          </FormItem>
          <FormItem label="Classification">
            <Input v-model.trim="addFrom.classify" style="width: auto"></Input>
          </FormItem>
        </template>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "codeListTitle",
    "codeList",
    "index",
    "count",
    "pageLimit",
    "loadingShow",
    "code",
  ],
  data() {
    return {
      addCodeShow: false,
      addFrom: {
        type: "",
        name: "",
        nameEnglish: "",
        barCode: "",
        serialNumber: "",
        classify: "",
        currentPage:this.index,
      },
    };
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.$parent.index = this.index;
        this.currentPage = this.index;
      }else{
        this.$parent.index = this.currentPage
      }
    },
    // 多选列表数据
    selectChangeCode(selection) {
      this.$emit("select-code", selection);
    },
    // 修改基础码或类型码数据
    editCode(row, index) {
      this.addCodeShow = true;
      if (this.code == "Basic Code") {
        this.addFrom = {
          type: row.type,
          name: row.name,
          nameEnglish: row.nameEnglish,
          barCode: row.barCode,
          serialNumber: row.serialNumber,
          classify: row.classify,
        };
      } else {
        this.addFrom.type = row.type;
        this.addFrom.name = row.name;
        this.addFrom.nameEnglish = row.nameEnglish;
      }
    },
    // 提交修改
    addSubmit() {},
    // 取消修改
    addCancel() {},
  },
  watch: {
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ivu-table-wrapper-with-border {
  margin-bottom: 10px;
}
</style>