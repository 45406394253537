<template>
    <div>
        <template v-if="language == 'Chinese'">
            <SearchAlarm
            @search-list="getFaultInfo($event)"
            :faultShow="true"
            @return-superior="$emit('return-superior', false)"
            />
        </template>
        <template v-else>
            <SearchAlarmEnglish
            @search-list="getFaultInfo($event)"
            :faultShow="true"
            @return-superior="$emit('return-superior', false)"
            />
        </template>
        <ApolloAlarmList
        @update-alarm="getFaultInfo"
        :faultShow="true"
        :count="count"
        :index="index"
        :pageLimit="pageLimit"
        :alarmList="falutInfoList"
        :loadingShow="loadingShow"
        :language="language"
        />
    </div>
</template>

<script>
import SearchAlarm from "../../components/equipment/Chinese/SearchAlarm.vue"
import SearchAlarmEnglish from "../../components/equipment/English/SearchAlarmEnglish.vue"
import ApolloAlarmList from "../../components/equipment/ApolloAlarmList.vue"
    export default {
        props: ["faultUrl", "faultList","loadingShow","language"],
        components: {
            SearchAlarm,
            SearchAlarmEnglish,
            ApolloAlarmList,
        },
        data() {
            return {
            count: 0,
            index: 1,
            pageLimit: 10,
            falutInfoList: [],
            };
        },
        created() {},
        methods: {
            // 获取设备故障信息
            getFaultInfo(param = {}) {
            this.$axios({
                url: this.apiUrl + this.faultUrl,
                method: "get",
                params: {
                id: this.faultList.id,
                ...param
                },
            })
                .then((res) => {
                // console.log(res);
                if (res.data.status == 200) {
                    this.falutInfoList = [res.data.data];
                    this.count = res.data.count;
                }
                })
                .catch((err) => err);
            },
        },
    }
</script>

<style lang="less" scoped>

</style>