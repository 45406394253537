<template>
  <!-- Apollo分配管理 -->
  <div class="apollo-distribution">
    <template v-if="language == 'Chinese'">
    <SearchDistribution
      @search-distribution="getApolloDistributionList($event)"
      :fileUploadUrl="fileApolloUploadUrl"
      @updata-list="getApolloDistributionList"
      :templateUrl="templateApolloUrl"
      :administratorUrl="administratorUrl"
    />
    </template>
    <template v-else>
    <SearchDistributionEnglish
      @search-distribution="getApolloDistributionList($event)"
      :fileUploadUrl="fileApolloUploadUrl"
      @updata-list="getApolloDistributionList"
      :templateUrl="templateApolloUrl"
      :administratorUrl="administratorUrl"
    />
  </template>
    <ApolloDistributionList
      :distributionList="distributionApolloList"
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :factorUrl="factorApolloUrl"
      :loadingShow="loadingShow"
      @update-list="getApolloDistributionList"
      :language="language"
    />
  </div>
</template>

<script>
import SearchDistribution from "../../components/equipment/Chinese/SearchDistribution.vue";
import ApolloDistributionList from "../../components/equipment/ApolloDistributionList.vue";
import SearchDistributionEnglish from "../../components/equipment/English/SearchDistributionEnglish.vue";
export default {
  components: {
    SearchDistribution,
    ApolloDistributionList,
    SearchDistributionEnglish,
  },
  props: ["language"],
  data() {
    return {
      distributionApolloUrl: "equipmentApollo/equipmentApolloList", // 分配设备列表
      distributionApolloList: [],
      count: 0,
      index: 1,
      pageLimit: 10,
      fileApolloUploadUrl: "equipmentApollo/importApolloUpData", // 批量分配
      factorApolloUrl: "equipmentApollo/updateEquipmentApollo", // 厂家分配
      templateApolloUrl: "equipmentApollo/importApolloModel", // 模板下载
      loadingShow: true,
      searchParam: {},
      administratorUrl:"equipmentApollo/importApolloUpDatagly"
    };
  },
  created() {
    this.getApolloDistributionList();
  },
  methods: {
    // 获取Apollo分配管理设备列表
    getApolloDistributionList(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.searchParam = param?param:this.searchParam;
      this.loadingShow = true;
      this.$axios({
        url: this.apiUrl + this.distributionApolloUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          equipmentFactoryFk:this.$root.userInfo.organizationId,
          MechanismType:this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          // console.log(res);
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.distributionApolloList = res.data.data;
            this.count = res.data.count;
          }else{
            this.distributionApolloList = []
          }
        })
        .catch((err) => {
          this.distributionApolloList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getApolloDistributionList();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getApolloDistributionList();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>