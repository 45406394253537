"use strict";

import Vue from 'vue';
import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
axios.defaults.headers.common['Authorization'] = "AUTH_TOKEN";
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.common['Access-Control-Expose-Headers'] = 'Authorization';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
let config = {
  baseURL: process.env.baseURL || process.env.apiUrl || "",
  timeout: 180 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  crossDomain: true
};
const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    let cookie = document.cookie.split(";");
    cookie = cookie.filter(item=>item.indexOf("Cookie=")!=-1)[0];
    // cookie = cookie.filter(item=>item.indexOf("JSESSIONID=")!=-1)[0];
    cookie = cookie.length?cookie.substring(cookie.indexOf("=")+1):""
    config.headers['sessionId'] = cookie;
    // console.log(config);
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // console.log(response);
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
