<template>
  <div class="equipment-state-pie" style="width:200px;height:200px"></div>
</template>

<script>
export default {
  props: ["index","dataTitle","data"],
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChartPie();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChartPie() {
        console.log(this.dataTitle);
      this.chart = this.$echarts.init(
        document.getElementsByClassName("equipment-state-pie")[this.index]
      );
      this.chart.setOption({
        tooltip: {
          trigger: "item", // 鼠标移上，数据提示
          backgroundColor: "rgba(57,64,86,0.5)",
          textStyle: {
            fontWegiht: 700,
            color: "#fff",
          },
        },
        legend: {
          top: 20,
          orient: "vertical", // 数据名称竖向排列
          left: "right",
        },
        series: {
          name: this.dataTitle,
          type: "pie",
          radius: "55%",
          data: this.data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0,0,0,0.5)",
            },
          },
          label: {
            normal: {
              position: "inner",
              show: true,
              formatter: "{d}%",
            },
          },
        },
      });
      window.onresize = this.chart.resize;
    },
  },
};
</script>

<style lang="less" scoped>
</style>