<template>
  <!-- 初级管理员部门管理 -->
  <div class="junior-administrator" v-if="language == 'Chinese'">
    <SearchInstitutionInfo
      :selectList="selectList"
      @update-list="
        institutionJuniorDealer != ''
          ? getJuniorTerminal()
          : institutionJunior != ''
          ? getJuniorDealer()
          : getJuniorAdmininstrator()
      "
      :addTitle="addTitleChinese"
      :institutionManufacturers="
        institutionJuniorDealer != ''
          ? institutionJuniorDealer
          : institutionJunior
      "
      :institutionsType="institutionsTypeChinese"
      :addUrl="
        institutionJuniorDealer != ''
          ? addJuniorTerminalUrl
          : institutionJunior != ''
          ? addJuniorDealerUrl
          : addJuniorUrl
      "
      @delete-select="deleteJunior($event)"
      @renturn-list="renturnToList"
      @search-list="
        institutionJuniorDealer != ''
          ? getJuniorTerminal($event)
          : institutionJunior != ''
          ? getJuniorDealer($event)
          : getJuniorAdmininstrator($event)
      "
      :type="institutionsType"
      >机构名称：</SearchInstitutionInfo
    >
    <InstitutionsList
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :institutionList="juniorAdministratorList"
      :loadingShow="loadingShow"
      :editUrl="
        institutionJuniorDealer != ''
          ? editJuniorTerminalUrl
          : institutionJunior != ''
          ? editJuniorDealerUrl
          : editJuniorUrl
      "
      :institutionManufacturers="
        institutionJuniorDealer != ''
          ? institutionJuniorDealer
          : institutionJunior
      "
      @select-list="selectList = $event"
      :institutionsType="institutionsTypeChinese"
      @institutions-dealer="
        institutionJunior = $event;
        addTitleChinese = '经销商';
        getJuniorDealer();
      "
      @institutions-terminal="
        institutionJuniorDealer = $event;
        addTitleChinese = '终端';
        getJuniorTerminal();
      "
      :institutionName="addTitleChinese"
      @delete-list="deleteJunior($event)"
      @updata-list="
        institutionJuniorDealer != ''
          ? getJuniorTerminal()
          : institutionJunior != ''
          ? getJuniorDealer()
          : getJuniorAdmininstrator()
      "
      :type="institutionsType"
    />
  </div>
  <div class="junior-administrator" v-else>
    <SearchInstitutionInfoEnglish
      :selectList="selectList"
      @update-list="
        institutionJuniorDealer != ''
          ? getJuniorTerminal()
          : institutionJunior != ''
          ? getJuniorDealer()
          : getJuniorAdmininstrator()
      "
      :addTitle="addTitleEnglish"
      :institutionManufacturers="
        institutionJuniorDealer != ''
          ? institutionJuniorDealer
          : institutionJunior
      "
      :addUrl="
        institutionJuniorDealer != ''
          ? addJuniorTerminalUrl
          : institutionJunior != ''
          ? addJuniorDealerUrl
          : addJuniorUrl
      "
      :institutionsType="institutionsTypeEnglish"
      @search-list="
        institutionJuniorDealer != ''
          ? getJuniorTerminal($event)
          : institutionJunior != ''
          ? getJuniorDealer($event)
          : getJuniorAdmininstrator($event)
      "
      @renturn-list="renturnToList"
      @delete-select="deleteJunior($event)"
      :type="institutionsType"
      >Institution Name：</SearchInstitutionInfoEnglish
    >
    <InstitutionsListEnglish
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      :institutionList="juniorAdministratorList"
      :editUrl="
        institutionJuniorDealer != ''
          ? editJuniorTerminalUrl
          : institutionJunior != ''
          ? editJuniorDealerUrl
          : editJuniorUrl
      "
      :loadingShow="loadingShow"
      @select-list="selectList = $event"
      :institutionsType="institutionsTypeEnglish"
      :institutionManufacturers="
        institutionJuniorDealer != ''
          ? institutionJuniorDealer
          : institutionJunior
      "
      @institutions-dealer="
        institutionJunior = $event;
        addTitleEnglish = 'Dealer';
        getJuniorDealer();
      "
      @institutions-terminal="
        institutionJuniorDealer = $event;
        addTitleEnglish = 'Terminal';
        getJuniorTerminal();
      "
      :institutionName="addTitleEnglish"
      @delete-list="deleteJunior($event)"
      @updata-list="
        institutionJuniorDealer != ''
          ? getJuniorTerminal()
          : institutionJunior != ''
          ? getJuniorDealer()
          : getJuniorAdmininstrator()
      "
      :type="institutionsType"
    />
  </div>
</template>

<script>
import SearchInstitutionInfo from "../../components/system/Chinese/institutions/SearchInstitutionInfo.vue";
import InstitutionsList from "../../components/system/Chinese/institutions/InstitutionsList.vue";
import SearchInstitutionInfoEnglish from "../../components/system/English/institutions/SearchInstitutionInfoEnglish.vue";
import InstitutionsListEnglish from "../../components/system/English/institutions/InstitutionsListEnglish.vue";
export default {
  components: {
    SearchInstitutionInfo,
    InstitutionsList,
    InstitutionsListEnglish,
    SearchInstitutionInfoEnglish,
  },
  props: ["language"],
  data() {
    return {
      juniorAdministratorList: [],
      count: 0,
      index: 1,
      pageLimit: 10,
      loadingShow: true,
      selectList: [],
      // 机构列表查询
      // institutionsUrl:"mechanism/mechanismList",
      juniorUrl: "mechanismDirectly/mechanismPrimaryAdminList", // 初级管理员
      addJuniorUrl: "mechanismDirectly/SaveMechanismmPrimaryAdmin",
      editJuniorUrl: "mechanismDirectly/updateMechanismPrimaryAdmin",
      juniorDealerUrl: "mechanismDirectly/mechanismSubordinateDistributorList", // 经销商
      addJuniorDealerUrl: "mechanismDirectly/SaveMechanismDistributor",
      editJuniorDealerUrl: "mechanism/updateMechanism",
      juniorTerminalUrl: "mechanismDirectly/mechanismTerminalList", // 终端
      addJuniorTerminalUrl: "mechanismDirectly/SaveMechanismSubordinateTermina",
      editJuniorTerminalUrl: "mechanism/updateMechanism",
      addTitleChinese: "初级管理员部门",
      institutionsType: "1002",
      institutionsTypeChinese: "初级管理员",
      addTitleEnglish: "Junior Administrator Department",
      institutionsTypeEnglish: "Junior administrator",
      institutionJunior: "", // 初级管理员中的经销商
      institutionJuniorDealer: "", // 初级管理员中的终端
      searchParam: {},
    };
  },
  created() {
    this.getJuniorAdmininstrator();
  },
  methods: {
    // 获取初级管理员机构列表
    getJuniorAdmininstrator(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.searchParam = param ? param : this.searchParam;
      this.$axios({
        /* url: this.apiUrl + this.institutionsUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismType: this.institutionsType,
          id: this.$root.userInfo.userMechanismFk,
          ...this.searchParam,
        }, */
        url: this.apiUrl + this.juniorUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismParentFk: this.$root.userInfo.userMechanismFk,
          mechanismType: this.institutionsType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          this.loadingShow = false;
          // console.log(res);
          if (res.data.status == 200) {
            this.juniorAdministratorList = res.data.data;
            this.count = res.data.count;
          } else {
            this.juniorAdministratorList = [];
          }
        })
        .catch((err) => {
          this.juniorAdministratorList = [];
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 初级管理员中的经销商
    getJuniorDealer(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.institutionsType = "1003";
      this.loadingShow = true;
      this.searchParam = param ? param : this.searchParam;
      this.$axios({
        /*  url: this.apiUrl + this.institutionsUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismType: this.institutionsType,
          id: this.institutionJunior.id,
          ...this.searchParam,
        }, */
        url: this.apiUrl + this.juniorDealerUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismParentFk: this.institutionJunior.id,
          userMechanismType: this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
      })
        .then((res) => {
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.juniorAdministratorList = res.data.data;
            this.count = res.data.count;
          } else {
            this.juniorAdministratorList = [];
          }
        })
        .catch((err) => {
          this.juniorAdministratorList = [];
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 初级管理员中的终端
    getJuniorTerminal(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.institutionsType = "1004";
      this.loadingShow = true;
      this.searchParam = param ? param : this.searchParam;
      let obj =this.institutionJunior != "" && this.institutionJuniorDealer != ""?{
          mechanismSubordinateDistrFk: this.institutionJuniorDealer.id,
      }: {
          mechanismSubordinateAdminFk: this.institutionJuniorDealer.id,
      }
      this.$axios({
        /* url: this.apiUrl + this.institutionsUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          mechanismType: this.institutionsType,
          id: this.institutionJuniorDealer.id,
          ...this.searchParam,
        }, */
        url: this.apiUrl + this.juniorTerminalUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          userMechanismType: this.$root.userInfo.organizationType,
          ...obj,
          ...this.searchParam,
        },
      })
        .then((res) => {
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.juniorAdministratorList = res.data.data;
            this.count = res.data.count;
          } else {
            this.juniorAdministratorList = [];
          }
        })
        .catch((err) => {
          this.juniorAdministratorList = [];
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 删除机构列表（判断直属机构或从属机构）
    deleteJunior(row) {
      let url;
      if (this.institutionJunior != "" && this.institutionJuniorDealer != "") {
        url = "mechanismDirectly/delMechanismTerminalById";
      } else if (this.institutionJunior != "") {
        url = "mechanismDirectly/delMechanismDistributorById";
      } else if (this.institutionJuniorDealer != "") {
        url = "mechanismDirectly/delMechanismTerminalById";
      } else {
        url = "mechanismDirectly/delMechanismPrimaryAdminById";
      }
      this.$axios({
        url: this.apiUrl + url,
        method: "post",
        params: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.status == 200) {
          if (this.institutionsType == "1002") {
            this.getJuniorAdmininstrator();
          } else if (this.institutionsType == "1003") {
            this.getJuniorDealer();
          } else {
            this.getJuniorTerminal();
          }
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    // 返回上级
    renturnToList() {
      if (this.institutionJunior != "" && this.institutionJuniorDealer != "") {
        this.institutionJuniorDealer = "";
        this.institutionsType = "1003";
        this.addTitleChinese = "经销商";
        this.addTitleEnglish = "Dealer";
        this.getJuniorDealer();
      } else if (
        this.institutionJunior != "" ||
        this.institutionJuniorDealer != ""
      ) {
        this.institutionJunior = "";
        this.institutionJuniorDealer = "";
        this.institutionsType = "1002";
        this.addTitleChinese = "初级管理员部门";
        this.addTitleEnglish = "Junior Administrator Department";
        this.getJuniorAdmininstrator();
      }
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        if (
          this.institutionJunior != "" &&
          this.institutionJuniorDealer != ""
        ) {
          this.juniorTerminalUrl =
            "mechanismDirectly/mechanismSubordinateTerminalList";
          this.getJuniorTerminal();
        } else if (this.institutionJunior != "") {
          this.juniorTerminalUrl = "mechanismDirectly/mechanismTerminalList";
          this.getJuniorDealer();
        } else if (this.institutionJuniorDealer != "") {
          this.juniorTerminalUrl = "mechanismDirectly/mechanismTerminalList";
          this.getJuniorTerminal();
        } else {
          this.juniorTerminalUrl = "mechanismDirectly/mechanismTerminalList";
          this.getJuniorAdmininstrator();
        }
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue != oldValue) {
          if (
            this.institutionJunior != "" &&
            this.institutionJuniorDealer != ""
          ) {
            this.getJuniorTerminal();
          } else if (this.institutionJunior != "") {
            this.getJuniorDealer();
          } else if (this.institutionJuniorDealer != "") {
            this.getJuniorTerminal();
          } else {
            this.getJuniorAdmininstrator();
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>