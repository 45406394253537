<template>
  <div class="liveness">
    <div id="home-liveness" style="width: 100%; height: 350px"></div>
  </div>
</template>

<script>
export default {
  props:["livenessData"],
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.dataLine();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    dataLine() {
      this.chart = this.$echarts.init(document.getElementById("home-liveness"));
      this.chart.setOption({
        title: {
          top: 20,
          text: "近一周活跃度统计",
          textStyle: {
            fontWeight: "normal",
            fontSize: 15,
          },
          left: "1%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              // color: "#57617B",
            },
          },
        },
        legend: {},
        xAxis: {
          axisTick: {
            alignWithLabel: true,
            inside: true,
            length: 3,
          },
          data: this.livenessData? this.livenessData.xAxis.data.reverse():[19, 20, 21, 22, 23, 24, 25],
        },
        yAxis: {
          // y轴
          type: "value",
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            inside: true,
            length: 3,
          },
          axisLabel: {
            formatter: "{value}",
          },
        },
        series: this.livenessData? this.livenessData.series.map(item=>{
          return {
            ...item,
            type:"line",
            smooth: 0.6,
            data:item.data.reverse().map(item=>item=="null"?"0":item)
          }
        }):[
          {
            type: "line",
            smooth: 0.6,
            name: "PV设备活跃度",
            data:  [0, 0, 0, 0, 0, 0, 0],
          },
          {
            type: "line",
            smooth: 0.6,
            name: "MPPT一体机活跃度",
            data:  [0, 0, 0, 0, 0, 0, 0],
          },
          {
            type: "line",
            smooth: 0.6,
            name: "逆变器设备活跃度",
            data:  [0, 0, 0, 0, 0, 0, 0],
          },
        ],
      });
      window.onresize = this.chart.resize;
    },
  },
};
</script>

<style lang="less" scoped>
.liveness {
  font-size: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: aliceblue;
  margin-bottom: 5px;
  // flex: 0.6;
  width: 100%;
}
</style>