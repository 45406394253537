<template>
  <div class="search-role">
    <label for="role-search">Role：</label>
    <Input
      id="role-search"
      v-model.trim="roleInput"
      placeholder=""
      style="width: auto"
      @keyup.native.enter="searchRole"
    />&nbsp;&nbsp;
    <Button type="primary" shape="circle" icon="ios-search" @click="searchRole"
      >Inquire</Button
    >&nbsp; <Button type="primary" @click="addRole">Add</Button>&nbsp;
    <Button type="primary" @click="delectRole">Delete</Button>
    <Modal
      v-model="dialogFormVisible"
      title="Add Role"
      ok-text="Confirm"
      cancel-text="Cancel"
      @on-ok="addSubmit"
      @on-cancel="addCancel"
    >
      <Form :model="roleFrom" :label-width="80" label-colon>
        <FormItem label="Role Name">
          <Input style="width:auto"
            v-model.trim="roleFrom.name"
          ></Input>
        </FormItem>
        <FormItem label="Character Level">
          <Input style="width:auto"
            v-model.trim="roleFrom.rank"
          ></Input>
        </FormItem>
        <FormItem label="Role Number">
          <Input style="width:auto"
            v-model.trim="roleFrom.serialNumber"
          ></Input>
        </FormItem>
        <FormItem label="Instruction Manual">
          <Input
            v-model.trim="roleFrom.remark" style="width:auto"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
          ></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: ["selectList"],
  data() {
    return {
      dialogFormVisible: false,
      roleInput: "",
      roleFrom: {
        name: "",
        rank: "",
        serialNumber: "",
        remark: "",
      },
    };
  },
  methods: {
    // 搜索角色
    searchRole() {
      let param = {};
      this.$emit("search-list", param);
    },
    // 添加角色
    addRole() {
      this.dialogFormVisible = true;
    },
    // 删除
    delectRole() {
      this.$confirm("Are you sure to delete").then(()=>{
         this.selectList.forEach((item) => {
            this.$emit("delete-role", item);
          });
      }).catch(()=>{
        this.$message("Cancel Delete")
      })
    },
    // 添加提交
    addSubmit() {},
    // 取消添加
    addCancel() {},
  },
};
</script>

<style lang="less" scoped>
.search-role {
  margin-bottom: 10px;
}
</style>