import { render, staticRenderFns } from "./EditMenuBoxEnglish.vue?vue&type=template&id=4c1b1292&scoped=true&"
import script from "./EditMenuBoxEnglish.vue?vue&type=script&lang=js&"
export * from "./EditMenuBoxEnglish.vue?vue&type=script&lang=js&"
import style1 from "./EditMenuBoxEnglish.vue?vue&type=style&index=1&id=4c1b1292&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1b1292",
  null
  
)

export default component.exports