<template>
  <!-- 初级管理员、经销商、终端管理列表 -->
  <div>
    <Table
      border
      :loading="loadingShow"
      :columns="institutionTitle"
      :data="institutionList"
      highlight-rows
      @on-selection-change="selectInstitutions"
    >
      <template slot-scope="{ row, index }" slot="institution">
        <Button
          type="primary"
          size="small"
          style="margin-left: 5px"
          @click="dealerManagement(row, index)"
          v-if="type == '1002'"
          >经销商管理</Button
        >
        <Button
          type="primary"
          size="small"
          style="margin-left: 5px"
          @click="terminalManage(row, index)"
          v-if="type == '1002' || type == '1003'"
          >终端管理</Button
        >
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="primary"
          size="small"
          style="margin-right: 5px"
          @click="editInstitutionInfo(row, index)"
          >编辑</Button
        >
        <Button type="error" size="small" @click="remove(row, index)"
          >删除</Button
        >
      </template>
    </Table>
    <Page
      ref="page"
      style="margin-top: 10px"
      :total="count"
      :current.sync="$parent.index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="$parent.pageLimit = $event"
    />
    <Modal
      v-model="showEditInfo"
      title="编辑机构信息"
      @on-ok="editSubmit"
      @on-cancel="editCancel"
    >
      <Form :model="editFrom" :label-width="80" label-colon>
        <FormItem label="机构类型">
          <Input v-model.trim="editFrom.institutionType" disabled></Input>
        </FormItem>
        <FormItem label="所属机构" v-if="institutionManufacturers != ''">
          <Input
            v-model.trim="editFrom.institutionManufacturers"
            disabled
          ></Input>
        </FormItem>
        <FormItem label="机构名称">
          <Input v-model.trim="editFrom.institutionName"></Input>
        </FormItem>
        <FormItem label="联系人">
          <Input v-model.trim="editFrom.name"></Input>
        </FormItem>
        <FormItem label="联系电话">
          <Input
            type="tel"
            v-model.trim="editFrom.phone"
            :maxlength="11"
          ></Input>
        </FormItem>
        <FormItem label="Email">
          <Input type="email" v-model.trim="editFrom.email"></Input>
        </FormItem>
        <FormItem label="备注">
          <Input v-model.trim="editFrom.note" type="textarea"></Input>
        </FormItem>
        <FormItem label="地址">
          <Input v-model.trim="editFrom.address" type="textarea"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [
    "institutionList",
    "count",
    "index",
    "pageLimit",
    "institutionsType",
    "loadingShow",
    "editUrl",
    "institutionManufacturers",
    "institutionName",
    "type",
  ],
  data() {
    return {
      institutionTitle: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "机构名称",
          key: "mechanismName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismName)),
            ]);
          },
        },
        {
          title: "联系人",
          key: "mechanismContacts",
          width: 100,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismContacts)),
            ]);
          },
        },
        {
          title: "联系电话",
          key: "mechanismPhone",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismPhone)),
            ]);
          },
        },
        {
          title: "邮箱",
          key: "mechanismEmail",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismEmail)),
            ]);
          },
        },
        {
          title: "备注",
          key: "mechanismNotes",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismNotes)),
            ]);
          },
        },
        {
          title: "地址",
          key: "address",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.judgeValue(params.row.address))]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      showEditInfo: false,
      institutionId: null,
      institutionParent: null,
      editFrom: {
        institutionType: this.institutionsType,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        institutionName: "",
        name: "",
        phone: "",
        email: "",
        note: "",
        address: "",
      },
    };
  },
  created() {
    // 判断当前页面是否属于终端页面
    if (this.type != "1004" && this.type != "1005") {
      this.institutionTitle.splice(-1, 0, {
        title: "从属机构",
        slot: "institution",
        align: "center",
        width: 210,
      });
    }
    if (this.type != "1002") {
      this.institutionTitle.splice(7, 0, {
        title: "直属机构",
        key: "mechanismParentName",
        align: "center",
        render: (h, params) => {
          return h("div", [
            h("span", this.judgeValue(params.row.mechanismParentName)),
          ]);
        },
      });
    }
  },
  methods: {
    // 编辑终端
    editInstitutionInfo(row, index) {
      this.showEditInfo = true;
      this.institutionId = row.id;
      this.institutionParent = row.mechanismParentFk;
      this.editFrom = {
        institutionType:
          row.mechanismType == "1002"
            ? "初级管理员"
            : row.mechanismType == "1003"
            ? "经销商"
            : "终端用户",
        institutionName: row.mechanismName,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        name: row.mechanismContacts,
        phone: row.mechanismPhone,
        email: row.mechanismEmail,
        note: row.mechanismNotes,
        address: row.address,
      };
    },
    // 弹窗保存
    editSubmit() {
      if (
        this.editFrom.institutionName == "" ||
        this.editFrom.name == "" ||
        this.editFrom.phone == ""
      )
        return this.$alert("机构名称、联系人、联系电话不能为空");
      if (
        this.editFrom.phone.length != 11 ||
        this.editFrom.phone.search(/[^\d]/g) != -1
      ) {
        this.addCancel();
        return this.$alert("输入的手机号码不符合规范");
      }
      if (this.editFrom.email != "") {
        var re =
          /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if (!re.test(this.editFrom.email)) {
          return this.$alert("输入的邮箱格式不正确");
        }
      }
      let param = {};
      if (this.institutionManufacturers != "") {
        if (this.institutionsType == "初级管理员") {
          if (this.type == "1002") {
            param = {
              mechanismAddress: this.editFrom.address,
              mechanismSubordinateAdminFk: this.institutionManufacturers.id,
            };
          } else {
            param = {
              mechanismAddress: this.editFrom.address,
            };
          }
        } else {
          param = {
            mechanismAddress: this.editFrom.address,
            mechanismSubordinateDistrFk: this.institutionManufacturers.id,
          };
        }
      } else {
        param = {
          address: this.editFrom.address,
          mechanismParentFk: this.institutionParent,
          // mechanismParentFk: this.$root.userInfo.userMechanismFk,
        };
      }
      console.log(param);
      this.$axios({
        url: this.apiUrl + this.editUrl,
        method: "post",
        params: {
          id: this.institutionId,
          mechanismName: this.editFrom.institutionName,
          mechanismType: this.type,
          // mechanismParentFk: this.institutionParent,
          mechanismContacts: this.editFrom.name,
          mechanismPhone: this.editFrom.phone,
          mechanismEmail: this.editFrom.email,
          mechanismNotes: this.editFrom.note,
          ...param,
        },
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.$emit("updata-list");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 弹窗取消
    editCancel() {
      this.editFrom = {
        institutionType: this.institutionsType,
        institutionManufacturers:
          this.institutionManufacturers != ""
            ? this.institutionManufacturers.mechanismName
            : "",
        institutionName: "",
        name: "",
        phone: "",
        email: "",
        note: "",
        address: "",
      };
    },
    // 终端管理
    terminalManage(row, index) {
      this.$parent.index = 1;
      this.$parent.pageLimit = 10;
      this.$emit("institutions-terminal", row);
    },
    // 经销商管理
    dealerManagement(row, index) {
      this.$parent.index = 1;
      this.$parent.pageLimit = 10;
      this.$emit("institutions-dealer", row);
    },
    // 删除
    remove(row, index) {
      this.$confirm("是否确定删除")
        .then(() => {
          this.$emit("delete-list", row);
        })
        .catch(() => {
          this.$message.info("取消删除");
        });
    },
    // 多选
    selectInstitutions(select) {
      this.$emit("select-list", select);
    },
  },
  watch: {
    institutionManufacturers(newValue, oldValue) {
      if (newValue != "") {
        this.editFrom.institutionManufacturers = newValue.mechanismName;
      }
    },
    type(newValue, oldValue) {
      if (newValue != "1004") {
        if (
          this.institutionTitle.map((item) => item.title).includes("从属机构")
        )
          return;
        this.institutionTitle.splice(-1, 0, {
          title: "从属机构",
          slot: "institution",
          align: "center",
          width: 210,
        });
      } else {
        if (this.institutionName != "终端用户") {
          this.institutionTitle.splice(-2, 1);
        }
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != this.$refs.page.currentPageSize) {
        this.$refs.page.currentPageSize = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>