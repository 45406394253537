<template>
  <div class="search-thirduser">
    <label for="search-key">关键字：</label>
    <Input
      id="search-name"
      v-model.trim="otherUserName"
      placeholder="请输入用户名称"
      style="width: 200px"
      @keyup.native.enter="searchThirdUser"
    ></Input>
    &nbsp;
    <label for="search-type">登录类型：</label>
    <Select id="search-type" v-model="otherUserType" style="width: 200px">
      <Option v-for="item in cityList" :value="item.value" :key="item.value">{{
        item.label
      }}</Option>
    </Select>
    &nbsp;
    <label for="search-time">登录时间：</label>
    <el-date-picker
      id="search-time"
      v-model="loginTime"
      type="date"
      placeholder="选择日期"
    >
    </el-date-picker>
    &nbsp;
    <Button
      type="primary"
      icon="ios-search"
      @click="searchThirdUser"
      shape="circle"
      >搜索</Button
    >
    &nbsp;
    <Button type="primary" v-if="$root.userInfo.organizationType=='1000'||$root.userInfo.organizationType=='1001'" @click="deleteThirdUser">删除</Button>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["deleteAllThirdUser"],
  data() {
    return {
      otherUserName: "",
      otherUserType: "All",
      loginTime: new Date(),
      cityList: [
        {
          label: "微信",
          value: "WX",
        },
        {
          label: "支付宝",
          value: "ZFB",
        },
        {
          label: "QQ",
          value: "QQ",
        },
        {
          label: "Apple",
          value: "Apple",
        },
        {
          label: "全部",
          value: "All",
        },
      ],
    };
  },
  methods: {
    // 搜索用户信息
    searchThirdUser() {
      let param = {
        otherUserName: this.otherUserName,
        otherUserType: this.otherUserType != "All" ? this.otherUserType : "",
        date:this.loginTime?this.loginTime.toLocaleDateString():"",
      };
      this.$emit("search-third-user", param);
    },
    // 删除第三方用户信息
    deleteThirdUser() {
      this.$confirm("是否确定删除", '提示').then(() => {
          // 判断所选信息是否为多条用户信息组成的数组
          if (Array.isArray(this.deleteAllThirdUser)) {
            this.deleteAllThirdUser.forEach((item) => this.handleThirdUser(item.id));
          } else {
            this.handleThirdUser(this.deleteAllThirdUser.id);
          }
        })
        .catch(() => {
          this.$message("取消删除");
        });
    },
    // 向后台传输需要删除的第三方数据
    handleThirdUser(id) {
      this.$axios({
        url: this.apiUrl + "otherUser/delOtherUserById",
        method: "post",
        params: {
          id: id,
        },
      }).then((res) => {
        if (res.data.status == 200) {
          this.$emit("update-list");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search-thirduser {
  width: 100%;
  border: 1px solid #e7e7e7;
  padding: 5px 0;
  padding-left: 10px;
}
</style>