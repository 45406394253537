<template>
  <div id="app">
    <router-view v-if="isRouterAlive"
      :userName="userName"
      @login-name="userName = $event"
      @toggle-lang="language = $event"
      :language="language"
      @update-name="userName = $event"
    />
  </div>
</template>

<script>
export default {
  name: "app",
  provide(){
    return {reload:this.reload}
  },
  data() {
    return {
      userName: null,
      language: "Chinese",
      isRouterAlive:true
    };
  },
  created() {
    this.language = window.sessionStorage.getItem("language")
      ? window.sessionStorage.getItem("language")
      : "Chinese";
      document.cookie = "Cookie="
    this.getUserName();
    // this.$router.push("/home")
  },
  mounted() {
    this.getUserName();
    if (!this.userName) {
      // console.log(!this.userName);
      return this.logout();
    } else {
      this.getUserName();
      if (this.$route.path == "/login") return this.$router.push("/home");
    }
  },
  methods: {
    // 本地获取登录用户姓名
    getUserName() {
      // this.userName = window.sessionStorage.getItem("userInfo").name;
      // console.log(this.$root.userInfo);
      if (!this.$root.userInfo) return this.logout();
      this.userName = this.$root.userInfo.name||this.$root.userInfo.userName;
    },
    // 页面重新加载
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true;
      })
    }
  },
  watch: {
    $route(to, from) {
      if (to.path != "/login") {
        this.getUserName();
        if (!this.userName) return this.logout();
        if (to.path == "/home") {
          this.$router.push(this.$router.options.routes[1].children[0].path);
        }
      } else {
        this.userName = null;
      }
    },
    language(newValue, oldValue) {
      window.sessionStorage.setItem("language", this.language);
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  font-size: 14px !important;
}
.ivu-table-tip {
  overflow-x: hidden !important;
}
</style>
