<template>
  <!-- 角色管理 -->
  <div class="role" v-if="language == 'Chinese'">
    <SearchRole
      :selectList="selectList"
      @delete-role="deleteRole($event)"
      @search-list="getRole($event)"
    ></SearchRole>
    <RoleList
      :roleList="roleList"
      :pageLimit="pageLimit"
      :count="count"
      :index="index"
      :loadingShow="loadingShow"
      @delete-role="deleteRole($event)"
      @select-list="selectList = $event"
    />
    <!-- @setting-permissions="settingPermiddiond($event)" -->
  </div>
  <div class="role" v-else>
    <SearchRoleEnglish
      :selectList="selectList"
      @delete-role="deleteRole($event)"
      @search-list="getRole($event)"
    ></SearchRoleEnglish>
    <RoleListEnglish
      :roleList="roleList"
      :pageLimit="pageLimit"
      :count="count"
      :index="index"
      :loadingShow="loadingShow"
      @delete-role="deleteRole($event)"
      @select-list="selectList = $event"
    />
    <!-- @setting-permissions="settingPermiddiond($event)" -->
  </div>
</template>

<script>
import RoleList from "../../components/system/Chinese/RoleList.vue";
import SearchRole from "../../components/system/Chinese/SearchRole.vue";
import RoleListEnglish from "../../components/system/English/RoleListEnglish.vue";
import SearchRoleEnglish from "../../components/system/English/SearchRoleEnglish.vue";
export default {
  components: {
    RoleList,
    SearchRole,
    RoleListEnglish,
    SearchRoleEnglish,
  },
  props: ["language"],
  data() {
    return {
      roleList: [],
      count: 0,
      index: 1,
      pageLimit: 10,
      loadingShow: true,
      selectList: [],
      roleUrl: "systemMenu/FindAllSystemRoleList",
      searchParam:{},
    };
  },
  created() {
    this.getRole();
  },
  methods: {
    // 获取角色列表数据
    getRole(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.searchParam = param?param:this.searchParam;
      this.$axios({
        url:this.apiUrl + this.roleUrl,
        method:"get",
       /*  params:{
          page:this.index,
          limit:this.pageLimit,
          ...this.searchParam,
        } */
      }).then(res=>{
            if(res.data.code==200){
                this.roleList = res.data.data;
                this.count = res.data.count;
            }
            this.loadingShow=false
      }).catch(err=>{
            this.loadingShow=false;
            console.log(err)
      })
      this.loadingShow=false;
    },
    // 设置权限
    settingPermiddiond({ show, roleId }) {},
    // 删除角色
    deleteRole(row){
      // this.$axios({
      //   url:this.apiUrl+"",
      //   method:"post",
      //   params:{
      //     id:row.id
      //   }
      // }).then(res=>{
      //   if(res.data.status==200){
      //     this.getRole()
      //   }
      // }).catch(err=>err)
    }
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getRole();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getRole();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>