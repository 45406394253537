<template>
  <!-- 第三方用户管理 -->
  <div class="third-party-users" v-if="language == 'Chinese'">
    <SearchThirdUser
      @search-third-user="newThirdUser($event)"
      @update-list="newThirdUser"
      :deleteAllThirdUser="deleteAllThirdUser"
    />
    <Table
      border
      stripe
      :loading="loadingShow"
      :columns="thirdList"
      :data="thirdUser"
      @on-selection-change="selectThirdList"
    >
    </Table>
    <Page
    ref="page"
      style="margin-top: 10px"
      :total="count"
      :current.sync="index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="pageLimit = $event"
    />
  </div>
  <div class="third-party-users" v-else>
    <SearchThirdUserEnglish
      @search-third-user="newThirdUser($event)"
      @update-list="newThirdUser"
      :deleteAllThirdUser="deleteAllThirdUser"
    />
    <Table
      border
      stripe
      :loading="loadingShow"
      :columns="thirdListEnglish"
      :data="thirdUser"
      :no-data-text="'No Data'"
      @on-selection-change="selectThirdList"
    >
    </Table>
    <!-- <Page
    ref="page"
      style="margin-top: 10px"
      :total="count"
      :current.sync="index"
      show-total
      show-elevator
      show-sizer
      @on-page-size-change="pageLimit = $event"
    ><slot>
      {{count}} in total
    </slot>
    </Page> -->
    <div style="display:flex;margin-top: 10px;align-content: center">
    <Page
      ref="page"
      :total="count"
      :current.sync="index"
      show-total
    ><slot>
      {{count}} in total
    </slot>
    </Page>
     <el-pagination layout="slot">
      <p class="ivu-select" style="font-weight:500">
        <Select v-model="pageLimit" style="width:auto;">
          <Option :value="10">10 pieces/page</Option>
          <Option :value="20">20 pieces/page</Option>
          <Option :value="30">30 pieces/page</Option>
          <Option :value="40">40 pieces/page</Option>
        </Select>
      </p>
    </el-pagination>
     <el-pagination layout="slot">
      <p class="ivu-page-options-elevator" style="font-weight:500">
        Jump to 
        <input type="text" v-model.number="currentPage" autocomplete="off" spellcheck="false" @blur="handlePage" @keydown.enter="handlePage">
        pages
      </p>
    </el-pagination>
    </div>
  </div>
</template>

<script>
import SearchThirdUser from "../../components/system/Chinese/SearchThirdUser.vue";
import SearchThirdUserEnglish from "../../components/system/English/SearchThirdUserEnglish.vue";
export default {
  components: {
    SearchThirdUser,
    SearchThirdUserEnglish,
  },
  props: ["language"],
  data() {
    return {
      thirdList: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "登录类型",
          key: "otherUserType",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "用户名称",
          key: "otherUserName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserName)),
            ]);
          },
        },
        {
          title: "性别",
          key: "otherUserSex",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserSex)),
            ]);
          },
        },
        {
          title: "登录IP",
          key: "otherUserOppid",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserOppid)),
            ]);
          },
        },
        {
          title: "所在城市",
          key: "otherUserCity",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserCity)),
            ]);
          },
        },
        {
          title: "所在省份",
          key: "otherUserProvince",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserProvince)),
            ]);
          },
        },
        {
          title: "登陆时间",
          key: "otherUserLandtime",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.timeString(params.row.otherUserLandtime))]);
          },
        },
      ],
      thirdListEnglish: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "Login Type",
          key: "otherUserType",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "User Name",
          key: "otherUserName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserName)),
            ]);
          },
        },
        {
          title: "Gender",
          key: "otherUserSex",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserSex)),
            ]);
          },
        },
        {
          title: "Login IP",
          key: "otherUserOppid",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserOppid)),
            ]);
          },
        },
        {
          title: "City",
          key: "otherUserCity",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserCity)),
            ]);
          },
        },
        {
          title: "Province",
          key: "otherUserProvince",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserProvince)),
            ]);
          },
        },
        {
          title: "Login Time",
          key: "otherUserLandtime",
          align: "center",
          render: (h, params) => {
            return h("div", [h("span", this.timeString(params.row.otherUserLandtime))]);
          },
        },
      ],
      loadingShow: true,
      thirdUser: [],
      index: 1,
      deleteAllThirdUser: [],
      count: 0,
      pageLimit: 10,
      searchParam:{},
      currentPage:1,
    };
  },
  created() {
    this.newThirdUser();
  },
  methods: {
    // 处理页码数
    handlePage(){
      if(this.index*this.pageLimit>=this.count){
        this.index = this.index;
        this.currentPage = this.index;
      }else{
        this.index = this.currentPage
      }
    },
    // 获取第三方用户的数据列表
    newThirdUser(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.searchParam = param?param:this.searchParam;
      this.$axios({
        url: this.apiUrl + "otherUser/otherUserList",
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          ...this.searchParam,
        },
      })
        .then((res) => {
          //   console.log(res);
          if (res.data.status == 200) {
            // console.log(res);
            this.thirdUser = res.data.data;
            this.count = res.data.count;
            // this.timeFormat();
          }else{
            this.thirdUser = []
          }
          this.loadingShow = false;
        })
        .catch((err) => {
          this.thirdUser = []
          this.loadingShow = false;
          console.log(err);
        });
    },
    // 多选第三方用户数据
    selectThirdList(val) {
      this.deleteAllThirdUser = val;
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.newThirdUser();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.newThirdUser();
      } 
      if(newValue!=this.$refs.page.currentPageSize){
        this.$refs.page.currentPageSize = newValue
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ivu-table-header th {
  color: #ffd3b4;
  font-weight: bold;
  background-color: #212c31;
  border: none;
}
</style>
<style lang="less">
.ivu-table-tip {
  overflow-x: hidden;
}
</style>