<template>
  <!-- 碳排放 -->
  <Card class="carbon-emissions" style="background-color: #74c187">
    <img src="../../../assets/img/carbon-emissions.png" alt="" />
      <span style="margin: 0 20px">Carbon emissions</span> {{ userCarbon.series.data[4] == "null" ? 0 : (userCarbon.series.data[4] * 0.997).toFixed(3) }}KG
  </Card>
</template>

<script>
export default {
  props: ["userCarbon"],
};
</script>

<style lang="less" scoped>
.carbon-emissions {
  margin-right: 20px;
  background-color: #74c187;
  font-size: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  color: aliceblue;
  margin-bottom: 5px;
  letter-spacing: 2px;
  img {
    width: 40px;
  }
  .ivu-card-body {
    padding: 16px 50px;
  }
}
</style>
<style lang="less">
.carbon-emissions {
  .ivu-card-body {
    padding: 16px 30px;
  }
}
</style>