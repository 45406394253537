import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/charts.js'
import './plugins/iview.js'
import qs from "qs"
import jquery from "jquery"
import qrcode from "qrcodejs2"
import "./assets/fonts/iconfont.css"
import "./plugins/countries.js"

Vue.prototype.qs = qs;
Vue.prototype.$ = jquery;
Vue.prototype.$qrcode = qrcode;
Vue.config.productionTip = false
Vue.prototype.apiUrl = "http://apolloaws.iotnewenergy.com:8082/" /* "http://50.18.203.57:8082/" */ /* "http://apollo.iotnewenergy.com:8082/" */ // "http://47.106.191.15:8082/"
// Vue.prototype.apiUrl = "http://192.168.1.6:8082/"
// Vue.prototype.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));  // 原型链不支持修改，页面一创建就定义了改变量，不可修改

Vue.prototype.logout = function () {
  this.$router.push("/");
}
Vue.prototype.timeString = (data) => {
  if (data) {
    let date = new Date(data);
    if (date == 'Invalid Date') return data
    let year = date.getFullYear();
    let month = date.getMonth()+1 >= 10 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    let day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
    let hours = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
    let min = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
    let second = date.getSeconds() >= 10 ? date.getSeconds() : "0" + date.getSeconds();
    return year + "-" + month + "-" + day + " " + hours + ":" + min + ":" + second
  }
  return "--"
}
Vue.prototype.judgeValue = (data) => {
  if (data) return data
  return "--"
}
Vue.prototype.searchEndDate = ({ starDate, endDate }) => {
  let param = {
    star: "",
    end: ''
  }
  param.star = starDate.toLocaleString()
  param.end = endDate.toLocaleString()
  return param
}
Vue.filter("pathRouter", function (pathName, routerList) {
  for (let i = 0; i < routerList.length; i++) {
    if (pathName == routerList[i].meta.title) {
      return routerList[i].path
    }
  }
});

new Vue({
  router,
  store,
  data:function(){
    return {
      userInfo:JSON.parse(window.sessionStorage.getItem("userInfo"))||null
    }
  },
  render: function (h) { return h(App) }
}).$mount('#app')
