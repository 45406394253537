<template>
  <Card class="user-data" style="background-color: skyblue">
    <!-- <i class="el-icon-user-solid"></i> -->
    <img src="../../../assets/img/user-statistics.png" alt="">
    <span style="margin:0 10px"><slot></slot></span> {{userData.length!=0?(Array.isArray(userData)?userData[0].series:userData)+" 人":" 0 人"}}
  </Card>
</template>

<script>
export default {
  props: ["userData"],
};
</script>

<style lang="less" scoped>
.user-data {
  margin-right:60px;
  background-color: skyblue;
  font-size: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  color: aliceblue;
  margin-bottom: 5px;
  letter-spacing:2px;
  img{
    width: 40px;
    margin-right: 10px;
  }
  .el-icon-user-solid {
    font-size: 50px;
  }
  .ivu-card-body{
    padding: 16px 30px;
  }
}
</style>
<style lang="less">
.user-data {
  .ivu-card-body {
   padding: 16px 30px;
  }
}
</style>