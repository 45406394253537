<template>
  <div class="equipment-state-list">
    <Row
      style="background: #eee; padding: 20px; justify-content: space-between"
    >
      <Col span="6" v-for="(item, index) in equipmentData" :key="index">
        <Card bordered>
          <p slot="title">{{ item.xAxis.type == '逆变器'? 'Inverter':item.xAxis.type }} Survey</p>
          <div class="left">
            <p>TotalDeviceNum：{{ item.series.data[3]=="null"?"0":item.series.data[3] }}</p>
            <p>NumDevicesOnline：{{ item.series.data[0]=="null"?"0":item.series.data[0] }}</p>
            <p>NumDevicesOffline：{{ item.series.data[1]=="null"?"0":item.series.data[1] }}</p>
            <template v-if="item.xAxis.type == 'PV'">
              <p>DailyGeneration：{{ item.series.data[4]=="null"?"0":item.series.data[4] }}KW.H</p>
              <p>TotalGeneration：{{ item.series.data[5]=="null"?"0":item.series.data[5] }}KW.H</p>
            </template>
            <template v-if="item.xAxis.type == 'MPPT'">
              <p>
                PVGeneration：{{ item.series.data[4]=="null"?"0":item.series.data[4] }}KW.H
              </p>
            </template>
          </div>
          <div class="right">
            <EquipmentStatePieEnglish
              :index="index"
              :dataTitle="setEquipmentName(item.series.name) + 'Online Status'"
              :data="[
                { value: item.series.data[0], name: 'online' },
                { value: item.series.data[1], name: 'offline' },
              ]"
            />
          </div>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import EquipmentStatePieEnglish from "./EquipmentStatePieEnglish.vue";
export default {
  props: ["equipmentData"],
  components: {
    EquipmentStatePieEnglish,
  },
  data() {
    return {};
  },
  methods:{
    setEquipmentName(val){
      return val.includes("MPPT")?"MPPT Equipment ":val.includes("PV")?"PV Equipment ":val.includes("Apollo")?"Apollo Equipment ":"Inverter Equipment "
    }
  }
};
</script>

<style lang="less" >
.equipment-state-list {
  .ivu-card-body {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    p{
      font-size: 15px;
      line-height: 25px;
    }
  }
}
</style>