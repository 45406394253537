<template>
  <!-- 类型码 -->
  <div class="type-code" v-if="language == 'Chinese'">
    <SearchCode
      :selectCode="selectTypeCode"
      @updata-code="getTypeCode"
      @search-code="getTypeCode($event)"
      :code="codeChinese"
      >类型码：</SearchCode
    >
    <CodeList
      :codeListTitle="typeCodeTitle"
      :codeList="typeCodeList"
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      @select-code="selectTypeCode = $event"
      :loadingShow="loadingShow"
      :code="codeChinese"
    />
    <!-- 弹出框 -->
  </div>
  <div class="type-code" v-else>
    <SearchCodeEnglish
      :selectCode="selectTypeCode"
      @updata-code="getTypeCode"
      @search-code="getTypeCode($event)"
      :code="codeEnglish"
      >Type Code：</SearchCodeEnglish
    >
    <CodeListEnglish
      :codeListTitle="typeCodeTitleEnglish"
      :codeList="typeCodeList"
      :count="count"
      :index="index"
      :pageLimit="pageLimit"
      @select-code="selectTypeCode = $event"
      :loadingShow="loadingShow"
      :code="codeEnglish"
    />
    <!-- 弹出框 -->
  </div>
</template>

<script>
import SearchCode from "../../components/system/Chinese/SearchCode.vue";
import CodeList from "../../components/system/Chinese/CodeList.vue";
import SearchCodeEnglish from "../../components/system/English/SearchCodeEnglish.vue";
import CodeListEnglish from "../../components/system/English/CodeListEnglish.vue";
export default {
  components: {
    SearchCode,
    CodeList,
    SearchCodeEnglish,
    CodeListEnglish,
  },
  props: ["language"],
  data() {
    return {
      typeCodeTitle: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "类型号",
          key: "type",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "类型名称",
          key: "type",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "类型英文名称",
          key: "type",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
      ],
      typeCodeTitleEnglish: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "Type Number",
          key: "type",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "Type Name",
          key: "type",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "English Name Of Type",
          key: "type",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
        {
          title: "Operation",
          slot: "action",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.otherUserType)),
            ]);
          },
        },
      ],
      typeCodeList: [],
      count: 0,
      index: 1,
      pageLimit: 10,
      selectTypeCode: [],
      loadingShow: true,
      codeChinese: "类型码",
      codeEnglish: "Type Code",
      searchParam:{},
    };
  },
  created() {
    this.getTypeCode();
  },
  methods: {
    // 获取类型码数据列表
    getTypeCode(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.loadingShow = true;
      this.searchParam = param?param:this.searchParam;
      //   this.$axios({
      //       url:"",
      //       methods:"get",
      //       params:{
      //           page:this.index,
      //           limit:this.pageLimit,
          // ...this.searchParam,
      //       }
      //   }).then(res=>{
      //       console.log(res);
      //       this.typeCodeList = res.data.data;
      //       this.count = res.data.count;
      // this.loadingShow=false
      //   }).catch(err=>{
      //    this.loadingShow=false;
      //   console.log(err);
      // })
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getTypeCode();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getTypeCode();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>