<template>
  <div class="nav">
    <el-container>
      <el-header
        class="unfold"
        style="
          width: 100%;
          line-height: 60px;
          color: #fff;
          background-color: #327df4;
        "
      >
        <div class="header" style="font-size: 20px">
          <i
            v-if="isCollapse"
            class="el-icon-s-unfold"
            @click="isCollapse = false"
          ></i>
          <i v-else class="el-icon-s-fold" @click="isCollapse = true"></i>
          <p class="d-none d-md-inline">电源物联智能监控系统</p>
          <!-- 需加判断，判断屏幕大小，决定是否显示文字 -->
        </div>
        <!-- 大屏显示 -->
       <!--  <div class="btn-fullscreen" v-if="$root.userInfo.organizationType=='1000'||$root.userInfo.organizationType=='1001'" @click="handleFullScreen">
          <i class="el-icon-rank"></i>
        </div> -->
        <Tooltip content="刷新当前页面" placement="left">
          <i
            class="el-icon-refresh-right refresh-page"
            @click="openFullScreen1"
            v-loading.fullscreen.lock="fullscreenLoading"
          >
          </i>
        </Tooltip>
        <el-dropdown
          style="color: #fff; margin-right: 10px"
          @command="handleCommand"
        >
          <span class="el-dropdown-link">
            简体中文<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="Chinese">简体中文</el-dropdown-item>
            <el-dropdown-item command="English">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <Tooltip content="可点击编辑用户信息" placement="bottom">
          <img style="margin-right: 10px" src="../assets/img/user.png" />
          <!-- alt="用户" -->
          <span @click="editLoginInfo">你好，{{ userName }}</span>
        </Tooltip>
        <Tooltip content="可点击退出登录" placement="bottom">
          <span @click="loginOut">
            <img style="margin-left: 20px" src="../assets/img/loginOut.png" />
            <!-- alt="退出登录" -->
            退出登录</span
          >
        </Tooltip>
      </el-header>
      <el-container>
        <NavList
          v-if="!fullscreenLoading"
          :isCollapse="isCollapse"
          :asideList="asideList"
        />
        <!-- <NavList :userInfo="$root.userInfo" :isCollapse="isCollapse" /> -->
        <el-main :style="{ 'margin-left': !isCollapse ? '220px' : '65px' }">
          <NavTag
            :fullscreenLoading="fullscreenLoading"
            :language="language"
            @update-nav="getNavList"
          />
        </el-main>
      </el-container>
    </el-container>
    <Modal
      v-model="loginInfoShow"
      title="修改查看登录信息"
      @on-ok="loginInfoOk"
      @on-cancel="loginInfoCancel"
    >
      <Form :model="loginInfoFrom" :label-width="90" label-colon>
        <FormItem label="用户名">
          <Input v-model="loginInfoFrom.name" style="width: auto"></Input>
        </FormItem>
        <FormItem label="手机号">
          <Input
            type="tel"
            v-model="loginInfoFrom.phone"
            style="width: auto"
            :maxlength="11"
          ></Input>
        </FormItem>
        <FormItem label="账号">
          <Input v-model="loginInfoFrom.account" style="width: auto"></Input>
        </FormItem>
        <FormItem label="用户密码">
          <Input v-model="loginInfoFrom.password" style="width: auto"></Input>
        </FormItem>
        <FormItem label="Email">
          <Input
            type="email"
            v-model="loginInfoFrom.email"
            style="width: auto"
          ></Input>
        </FormItem>
        <FormItem label="地址">
          <Input
            type="textarea"
            v-model="loginInfoFrom.address"
            style="width: auto"
          ></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import NavList from "@/components/NavList.vue";
import NavTag from "@/components/NavTag.vue";
export default {
  props: ["userName", "language"],
  components: {
    NavTag,
    NavList,
  },
  data() {
    return {
      isCollapse: false,
      loginInfoShow: false,
      fullscreenLoading: false,
      loginInfoFrom: {
        name: "",
        password: "",
        phone: "",
        account: "",
        email: "",
        address: "",
      },
      asideList: [],
      loginUserInfo: null,
    };
  },
  created() {
    this.getUserInfo();
    this.getNavList();
  },
  methods: {
    // 退出登录（清除登录信息）
    loginOut() {
      // this.$router.push("/");
      this.$axios({
        url: this.apiUrl + "index/loginOut",
        method: "post",
      })
        .then((res) => {
          if (res.data.status == 200) {
            window.sessionStorage.removeItem("userInfo");
            this.logout();
            this.$message("成功退出");
            this.$root.userInfo = null;
          }
        })
        .catch((err) => console.log(err));
      // }
    },
    // 页面内容刷新
    openFullScreen1() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.getNavList();
        this.fullscreenLoading = false;
      }, 2000);
    },
    // 修改用户信息并保存
    editLoginInfo() {
      this.loginInfoShow = true;
    },
    // 修改保存
    loginInfoOk() {
      if (
        this.loginInfoFrom.phone.length != 11 ||
        this.loginInfoFrom.phone.search(/[^\d]/g) != -1
      ) {
        this.getDefultInfo();
        return this.$alert("输入的手机号码不符合规范");
      }
      if (this.loginInfoFrom.password.length < 6) {
        return this.$alert("输入的密码不得小于6位");
      }
      if (this.loginInfoFrom.email != "") {
        var re =
          /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if (!re.test(this.loginInfoFrom.email)) {
          return this.$alert("输入的邮箱格式不正确");
        }
      }
      this.$axios({
        url: this.apiUrl + "user/updateUser",
        method: "post",
        params: {
          id: this.$root.userInfo.userId,
          userName: this.loginInfoFrom.name,
          userAccount: this.loginInfoFrom.account,
          userPassword: this.loginInfoFrom.password,
          userPhone: this.loginInfoFrom.phone,
          userEmail: this.loginInfoFrom.email,
          userAddress: this.loginInfoFrom.address,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            if (
              this.loginUserInfo.account != this.loginInfoFrom.account ||
              this.loginUserInfo.userInfo.password !=
                this.loginInfoFrom.password ||
              this.loginUserInfo.userPhone != this.loginInfoFrom.phone
            ) {
              return this.loginOut();
            }
            this.getUserInfo();
          } else {
            this.getDefultInfo();
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 获取登录用户最新信息
    getUserInfo() {
      this.$axios({
        url: this.apiUrl + "user/userInfo",
        method: "get",
        params: {
          id: this.$root.userInfo.userId,
        },
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.loginUserInfo = res.data.data;
            this.$emit("update-name", res.data.data.userName);
            this.getDefultInfo();
          }
        })
        .catch((err) => err);
    },
    // 设备默认值
    getDefultInfo() {
      this.loginInfoFrom.name = this.loginUserInfo.userName;
      this.loginInfoFrom.password = this.loginUserInfo.userPassword;
      this.loginInfoFrom.phone = this.loginUserInfo.userPhone;
      this.loginInfoFrom.account = this.loginUserInfo.userAccount;
      this.loginInfoFrom.email = this.loginUserInfo.userEmail;
      this.loginInfoFrom.address = this.loginUserInfo.userAddress;
    },
    // 获取右侧导航栏信息
    getNavList() {
      this.$axios({
        url: this.apiUrl + "systemMenu/MenuRoleList",
        method: "post",
        params: {
          id: this.$root.userInfo.userId,
        },
      })
        .then((res) => {
          // console.log(res);
          this.asideList = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消修改
    loginInfoCancel() {
      this.getDefultInfo();
    },
    // 中英文切换
    handleCommand(command) {
      // this.language = command;
      this.$emit("toggle-lang", command);
    },
    // 全屏显示
     handleFullScreen () {
      // this.$parent.showStatics = true;
      this.$router.push("/statics");
    }
  },
  watch: {
    loginInfoShow(newValue, oldValue) {
      if (newValue) {
        this.getUserInfo();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  height: 100%;
  display: flex;
  //   flex-wrap: wrap;
  .el-menu-vertical-demo {
    height: 100%;
    transition: width 0.5s linear;
  }
  .el-aside {
    position: fixed;
    top: 60px;
    height: calc(100% - 50px);
    text-align: left;
    line-height: 200px;
    transition: width 0.5s linear;
    overflow: hidden;
    .el-menu {
      border-right: none;
      //   height: (100% - 60px);
      height: 100%;
      background-color: #324157;
      .el-menu-item {
        background-color: #324157;
      }
    }
    svg {
      text-align: center;
      margin-right: 5px;
      width: 24px;
    }
  }
  .el-header {
    position: fixed;
    top: 0;
    background-color: rgb(50, 65, 87);
    color: #333;
    line-height: 60px;
    color: #bfcbd9;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 99;
    .header {
      flex: 1;
    }
    .refresh-page {
      line-height: 60px;
      font-size: 18px;
      margin-right: 10px;
    }
    .btn-fullscreen{
      margin-right: 10px;
      font-size: 16px;
    }
  }
  .el-main {
    margin-top: 61px;
    transition: margin-left 0.5s linear;
    padding-top: 0;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
a {
  color: #bfcbd9;
  &.router-link-exact-active {
    color: #2d8cf0;
  }
}
</style>
<style lang="less">
.ivu-table-overflowX {
  overflow-x: hidden !important;
}
</style>