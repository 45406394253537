<template>
  <!-- 设备概览 -->
  <div v-if="language == 'Chinese'" class="home">
    <div class="head" style="margin-left: 30px">
      <!-- <LoginInfo /> -->
      <UserData :userData="homeUserData" v-if="display" @click="$router.push('/user-control')">用户统计</UserData>
      <!-- <CarbonEmissions v-if="homeCarbonDate" :userCarbon="homeCarbonDate" /> -->
    </div>
    <div class="content">
      <Row
        style="
          width: 100%;
          background: #eee;
          padding: 20px 20px 0 20px;
          justify-content: space-between;
        "
      >
        <Col span="14">
          <Card style="width: 100%; display: block">
            <Liveness
              v-if="homeLivenessData.xAxis && homeLivenessData.series.length"
              :livenessData="homeLivenessData"
            />
          </Card>
        </Col>
        <Col span="9">
          <Card style="width: 100%; display: block">
            <EquipmentAlarmState
              v-if="homeAlarmDate.data.length > 0"
              :alarmDate="homeAlarmDate"
            />
          </Card>
        </Col>
      </Row>
    </div>
    <EquipmentStateList :equipmentData="homeEquipmentData" />
  </div>
  <div v-else class="home">
    <div class="head">
      <!-- <LoginInfoEnglish /> -->
      <UserDataEnglish :userData="homeUserData" v-if="display" @click="$router.push('/user-control')"
        >User statistics</UserDataEnglish
      >
      <!-- <CarbonEmissionsEnglish
        v-if="homeCarbonDate"
        :userCarbon="homeCarbonDate"
      /> -->
    </div>
    <div class="content">
      <Row
        style="
          width: 100%;
          background: #eee;
          padding: 20px 20px 0 20px;
          justify-content: space-between;
        "
      >
        <Col span="14">
          <Card style="width: 100%; display: block">
            <LivenessEnglish
              v-if="homeLivenessData.xAxis && homeLivenessData.series.length"
              :livenessData="homeLivenessData"
            />
          </Card>
        </Col>
        <Col span="9">
          <Card style="width: 100%; display: block">
            <EquipmentAlarmStateEnglish
              v-if="homeAlarmDate.data.length > 0"
              :alarmDate="homeAlarmDate"
            />
          </Card>
        </Col>
      </Row>
    </div>
    <EquipmentStateListEnglish :equipmentData="homeEquipmentData" />
  </div>
</template>
<script>
import EquipmentAlarmState from "../components/home/Chinese/EquipmentAlarmState.vue";
import EquipmentStateList from "../components/home/Chinese/EquipmentStateList.vue";
import UserData from "../components/home/Chinese/UserData.vue";
import Liveness from "../components/home/Chinese/Liveness.vue";
import LoginInfo from "../components/home/Chinese/LoginInfo.vue";
import CarbonEmissions from "../components/home/Chinese/CarbonEmissions.vue";
import EquipmentAlarmStateEnglish from "../components/home/English/EquipmentAlarmStateEnglish.vue";
import EquipmentStateListEnglish from "../components/home/English/EquipmentStateListEnglish.vue";
import UserDataEnglish from "../components/home/English/UserDataEnglish.vue";
import LivenessEnglish from "../components/home/English/LivenessEnglish.vue";
import CarbonEmissionsEnglish from "../components/home/English/CarbonEmissionsEnglish.vue";
import LoginInfoEnglish from "../components/home/English/LoginInfoEnglish.vue";
export default {
  components: {
    EquipmentAlarmState,
    EquipmentStateList,
    UserData,
    Liveness,
    LoginInfo,
    EquipmentAlarmStateEnglish,
    EquipmentStateListEnglish,
    UserDataEnglish,
    LivenessEnglish,
    CarbonEmissions,
    CarbonEmissionsEnglish,
    LoginInfoEnglish,
  },
  props: ["language"],
  data() {
    return {
      homeUserData: [],
      homeEquipmentData: [],
      homeCarbonDate: null,
      homeLivenessData: {
        xAxis: null,
        series: [],
      },
      homeAlarmDate: {
        name: [],
        nameEnglish: [],
        data: [],
      },
      display: false,
    };
  },
  created() {
    this.getUserData();
    this.getEquipmentData();
    this.getLivenessData();
    this.display =
      this.$root.userInfo.organizationType == "1000" ||
      this.$root.userInfo.organizationType == "1001";
  },
  methods: {
    // 获取用户统计数据
    getUserData() {
      // console.log(this.$root.userInfo.userMechanismFk);
      this.$axios({
        url: this.apiUrl + "reportNumberFrom/userNumReport",
        method: "get",
        params: {
          userMechanismFk: this.$root.userInfo.userMechanismFk,
          organizationType: this.$root.userInfo.organizationType,
        },
      })
        .then((res) => {
        //   console.log(res);
          this.homeUserData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取设备数据
    getEquipmentData() {
      this.$axios({
        url: this.apiUrl + "reportNumberFrom/equipmentReportNumFrom",
        method: "get",
        params: {
          organizationType: this.$root.userInfo.organizationType,
          userMechanismFk: this.$root.userInfo.userMechanismFk,
        },
      })
        .then((res) => {
        //   console.log(res);
        if(res.data.code==200){
            this.homeEquipmentData = res.data.data;
            // 告警数据
            this.homeEquipmentData.forEach((item) => {
            //   if (item.series.name.includes("管理")) {
                this.homeAlarmDate.name.push(
                  item.series.name.substring(0, item.series.name.length - 2)
                );
                if(item.series.name.includes("Apollo")) {
                  this.homeAlarmDate.nameEnglish.push("Apollo equipment");
                }
            //   } else {
            //     this.homeCarbonDate = item;
            //     this.homeAlarmDate.name.push(item.series.name);
            //     this.homeAlarmDate.nameEnglish.push("MPPT all-in-one");
            //   }
              this.homeAlarmDate.data.push(item.series.data[2]);
            });
        }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取设备活跃度数据
    getLivenessData() {
      this.$axios({
        url: this.apiUrl + "reportNumberFrom/ativationReportNumFrom",
        method: "get",
        params: {
          userMechanismFk: this.$root.userInfo.organizationId,
          organizationType: this.$root.userInfo.organizationType,
        },
      })
        .then((res) => {
          let data = res.data;
        //   console.log(data);
          // 处理数据
          data.forEach((item) => {
            // console.log(item);
            // x坐标轴数据
            this.homeLivenessData.xAxis = {
              ...item.xAxis,
            };
            // 显示数据
            this.homeLivenessData.series.push(item.series);
          });
        })
        .catch((err) => err);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  .head {
    display: flex;
  }
  .content {
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>