<template>
  <div class="equipment-data">
    <div class="table-top">
      <Button
        type="primary"
        style="margin-right: 10px"
        @click="getEquipmentData"
        >{{language=="Chinese"?"刷新当前页面":"Refresh current data"}}</Button
      >
      <Button
        type="primary"
        style="margin-right: 10px"
        @click="realTime"
        :disabled="equipmentData.equipmentConnectstate == '1'?disabledShow:true"
        >{{language=="Chinese"?"实时刷新":"Real-time refresh"}}</Button
      >
      <Button v-if="$root.userInfo.organizationType==1000"
        type="primary" @click="instructionShow = true"
        style="margin-right: 10px"
        >{{language=="Chinese"?"透传指令":"Transparent instruction"}}</Button
      >
      <Button type="primary" @click="$emit('return-to')">{{language=="Chinese"?"返回上一级":"Return to superior"}}</Button>
    </div>
    <Row style="background-color: #f5f5f5; padding: 20px">
      <Col span="24">
        <Row style="margin-bottom: 15px; background-color: #fff">
          <Col span="4">{{language=="Chinese"?"模块":"Module"}}ID：{{ equipmentData.wifiId?equipmentData.wifiId:"--" }}</Col>
          <!-- <Col span="6">设备类型：{{ equipmentData.equipmentType }}</Col> -->
          <Col span="8"
            >{{language=="Chinese"?"最新数据时间":"Latest data time"}}：{{
              equipmentDataList.length
                ? timeString(equipmentDataList[0].createTime)
                : "--"
            }}</Col
          >
          <Col span="5"
            >{{language=="Chinese"?"运行状态":"RunStatus"}}：{{ equipmentData.equipmentConnectstate=="0"?language=="Chinese"?"离线":"Offline":language=="Chinese"?"在线":"Online" }}
            <img
              v-if="equipmentData.equipmentConnectstate == '0'"
              src="../../assets/img/offline-equipment.png"
              alt=""
            />
            <img v-else src="../../assets/img/online-equipment-1.png" alt="" />
          </Col>
           <Col span="5"
            >{{language=="Chinese"?"电池状态":"BatteryStatus"}}：{{
              equipmentDataList.length
                ? judgeValue(equipmentDataList[0].batteryStatus): "--"
            }}</Col
          >
          <!-- <Col span="5"
            >信号强度：{{
              equipmentDataList.length
                ? judgeValue(equipmentDataList[0].SignalIntensity)
                : "--"
            }}</Col
          > -->
        </Row>
      </Col>
      <Col span="6">
        <Table
          style="margin-bottom: 10px"
          v-for="item in spliceDataTitle1" :key="item.key"
          :columns="item"
          :data="equipmentDataList"
          border
          stripe
          :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
          :loading="loadingShow"
        ></Table>
      </Col>
      <Col span="6">
        <Table
          style="margin-bottom: 10px"
          v-for="item in spliceDataTitle2" :key="item.key"
          :columns="item"
          :data="equipmentDataList"
          border
          stripe
          :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
          :loading="loadingShow"
        ></Table>
      </Col>
      <Col span="11" push="1" style="text-align: center">
        <Row>
          <Col span="24">
            <Table
              style="margin-bottom: 10px"
              :columns="dataSwitchTitle"
              :data="equipmentDataList"
              border
              stripe
              :no-data-text="language=='Chinese'?'暂无数据':'No Data'"
              :loading="loadingShow"
            >
              <template slot-scope="{ row, index }" slot="acOutputSwitch">
                <el-switch :disabled="handleSwitch()"
                  v-model="ACOutputValue" @change="handleBeforeChange('acOutputSwitch',$event)">
                </el-switch>
              </template>
              <template slot-scope="{ row, index }" slot="dcOutputSwitch">
                <el-switch :disabled="handleSwitch()"
                  v-model="DCOutputValue" @change="handleBeforeChange('dcOutputSwitch',$event)">
                </el-switch>
              </template>
              <template slot-scope="{ row, index }" slot="pureInverterRestartSwitch">
                <el-switch :disabled="handleSwitch()"
                  v-model="PureOutputValue" @change="handleBeforeChange('pureInverterRestartSwitch',$event)">
                </el-switch>
              </template>
              <template slot-scope="{ row, index }" slot="energySavingModeSwitch">
                <el-switch :disabled="handleSwitch()"
                  v-model="EnergyOutputValue" @change="handleBeforeChange('energySavingModeSwitch',$event)">
                </el-switch>
              </template>
            </Table>
          </Col>
          <Col span="24" class="state-box" style="text-align: center">
            <div
              class="state-bg-off"
              v-if="equipmentData.equipmentConnectstate == '0'"
            ></div>
            <div
              class="state-bg-on"
              v-else
            ></div>
          </Col>
        </Row>
      </Col>
    </Row>
    <!-- 指令下发弹窗 -->
    <Modal
        v-model="instructionShow"
        :title="language=='Chinese'?'透传指令下发':'Transparent instruction delivery'"
        @on-ok="submitInstruction"
        @on-cancel="cancelInstruction">
        <Input v-model.trim="instructionForm.content" type="textarea" :autosize="{minRows: 2,maxRows: 5}" :placeholder="language=='Chinese'?'透传指令':'Transparent instruction'"></Input>
    </Modal>
  </div>
</template>

<script>
export default {
  props: ["equipmentData", "loadingShow","language"],
  data() {
    return {
      dataTitle:[],
      dataSwitchTitle:[],
      dataTitleName:[
        {
          key:"inverterID",
          name:"逆变器ID",
          nameEnglish:"InverterID"
        },
        {
          key:"batteryVoltage",
          name:"电池电压",
          nameEnglish:"BatteryVoltage"
        },
        {
          key:"remainingBatteryCapacity",
          name:"电池剩余容量",
          nameEnglish:"RemainingBatteryCapacity"
        },
        {
          key:"batteryLevel",
          name:"电池电量",
          nameEnglish:"BatteryLevel"
        },
        {
          key:"batteryTemperature",
          name:"电池温度",
          nameEnglish:"BatteryTemperature"
        },
        {
          key:"bmstotalCurrent",
          name:"BMS总电流",
          nameEnglish:"BMSTotalCurrent"
        },
        /* {
          key:"batteryStatus",
          name:"电池状态",
          nameEnglish:"BatteryStatus"
        }, */
        {
          key:"pvinputVoltage",
          name:"PV输入电压",
          nameEnglish:"PVInputVoltage"
        },
        {
          key:"pvinputCurrent",
          name:"PV输入电流",
          nameEnglish:"PVInputCurrent"
        },
        {
          key:"pvinputPower",
          name:"PV输入功率",
          nameEnglish:"PVInputPower"
        },
        {
          key:"pvchargingPower",
          name:"PV充电功率",
          nameEnglish:"PVChargingPower"
        },
        {
          key:"mainsInputPower",
          name:"市电输入功率",
          nameEnglish:"MainsInputPower"
        },
        {
          key:"mainsFrequency",
          name:"市电频率",
          nameEnglish:"MainsFrequency"
        },
        {
          key:"mainsChargingPower",
          name:"市电充电功率",
          nameEnglish:"MainsChargingPower"
        },
        {
          key:"mainsVoltage",
          name:"市电电压",
          nameEnglish:"MainsVoltage"
        },
        {
          key:"mainsInputCurrent",
          name:"市电输入电流",
          nameEnglish:"MainsInputCurrent"
        },
        {
          key:"acoutputVoltage",
          name:"AC输出电压",
          nameEnglish:"ACOutputVoltage"
        },
        {
          key:"acoutputCurrent",
          name:"AC输出电流",
          nameEnglish:"ACOutputCurrent"
        },
        {
          key:"acoutputFrequency",
          name:"AC输出频率",
          nameEnglish:"ACOutputFrequency"
        },
        {
          key:"acoutputPower",
          name:"AC输出功率",
          nameEnglish:"ACOutputPower"
        },
        {
          key:"acloadRate",
          name:"AC负载率",
          nameEnglish:"ACLoadRate"
        },
        {
          key:"dcoutputPower",
          name:"DC输出功率",
          nameEnglish:"DCOutputPower"
        },
        {
          key:"dcoutputVoltage",
          name:"DC输出电压",
          nameEnglish:"DCOutputVoltage"
        },
        {
          key:"dcoutputCurrent",
          name:"DC输出电流",
          nameEnglish:"DCOutputCurrent"
        },
        {
          key:"vehicleChargingPower",
          name:"车充功率",
          nameEnglish:"VehicleChargingPower"
        },
        {
          key:"vehicleChargingVoltage",
          name:"车充电压",
          nameEnglish:"VehicleChargingVoltage"
        },
        {
          key:"vehicleChargingStream",
          name:"车充电流",
          nameEnglish:"VehicleChargingCurrent"
        },
        {
          key:"canFlow",
          name:"能流",
          nameEnglish:"CanFlow"
        },
        {
          key:"inverterOutputMode",
          name:"逆变器输出模式",
          nameEnglish:"InverterOutputMode"
        },
        {
          key:"operatingMode",
          name:"工作模式",
          nameEnglish:"OperatingMode"
        },
        {
          key:"mainsMaximumChargingCurrentPower",
          name:"市电最大充电电流(功率)",
          nameEnglish:"MainsMaxChargingCurrent(Power)"
        },
      ],
      dataSwitch:[
        {
          key:"acOutputSwitch",
          name:"交流输出开关",
          nameEnglish:"AcOutputSwitch"
        },
        {
          key:"dcOutputSwitch",
          name:"直流输出开关",
          nameEnglish:"DcOutputSwitch"
        },
        {
          key:"pureInverterRestartSwitch",
          name:"纯逆变重启开关",
          nameEnglish:"PureInverterRestartSwitch"
        },
        {
          key:"energySavingModeSwitch",
          name:"节能模式开关",
          nameEnglish:"EnergySavingModeSwitch"
        }],
      disabledShow:false,
      equipmentDataList: [],
      ACOutputValue:false,
      DCOutputValue:false,
      PureOutputValue:false,
      EnergyOutputValue:false,
      instructionShow:false,
      instructionForm:{
        content:''
      }
    };
  },
  created() {
    this.getEquipmentData();
  },
  computed: {
    spliceDataTitle1() {
      return this.dataTitle.slice(0,(this.dataTitle.length/2)+1);
    },
    spliceDataTitle2() {
      return this.dataTitle.slice((this.dataTitle.length/2)+1);
    },
  },
  methods: {
    // 请求设备数据
    getEquipmentData() {
      this.equipmentDataList = [];
      this.$parent.loadingShow = true;
      this.$axios({
        url: this.apiUrl + "equipmentApollo/findByReleData",
        method: "get",
        params: {
          wifiId: this.equipmentData.wifiId,
        },
      })
        .then((res) => {
          //   console.log(res);
          // loadingShow 表格loading效果
          this.$parent.loadingShow = false;
          if (res.data.code == "200") {
            this.handleCycle(res.data.data)
            this.equipmentDataList.push(res.data.data);
          }
        })
        .catch((err) => {
          this.handleCycle({})
          this.$parent.loadingShow = false;
          return err;
        });
    },
    // 实时状态
    realTime(){
      this.disabledShow = true;
      setTimeout(()=>{
        this.disabledShow = false;
      },5000)
      this.$axios({
          url: this.apiUrl + "equipmentApollo/ApolloMsgSend",
          method: "post",
          params: {
            wifiId: this.equipmentData.wifiId,
            commandVal:"1183*1",
          },
        })
          .then((res) => {
            if (res.data.code == 207) {
              setTimeout(()=>{
                this.getEquipmentData()
              },1500)
              // this.$Message.info(res.data.msg);
            } else {
              this.$Message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // 循环table
    handleCycle(data){
      let dataKey = [];
      this.dataTitle = [];
      this.dataSwitchTitle = [];
      this.ACOutputValue = data.acOutputSwitch;
      this.DCOutputValue = data.dcOutputSwitch;
      this.PureOutputValue = data.pureInverterRestartSwitch;
      this.EnergyOutputValue = data.energySavingModeSwitch;
      for(let key in data){
        dataKey.push(key)
      }
      this.dataTitleName.forEach(item=>{
        if(dataKey.includes(item.key)){
          if(this.dataTitle.length&&this.dataTitle[this.dataTitle.length-1].length&&this.dataTitle[this.dataTitle.length-1].length<2){
              this.dataTitle[this.dataTitle.length-1].push( 
                {
                  title: this.language=="Chinese"?item.name:item.nameEnglish,
                  key: item.key,
                  align:"center",
                  render: (h, params) => {
                    return h("div", [
                      h(
                        "span", this.judgeValue(params.row[item.key])
                      ),
                    ]);
                  },
                }
              )
            }else if(!this.dataTitle.length||this.dataTitle[this.dataTitle.length-1].length==2){
              this.dataTitle.push([ {
                title: this.language=="Chinese"?item.name:item.nameEnglish,
                key: item.key,
                align:"center",
                render: (h, params) => {
                  return h("div", [
                    h(
                      "span", this.judgeValue(params.row[item.key])
                    ),
                  ]);
                },
              }])
            }
        }else{
          if(this.dataTitle.length&&this.dataTitle[this.dataTitle.length-1].length&&this.dataTitle[this.dataTitle.length-1].length<2){
              this.dataTitle[this.dataTitle.length-1].push( 
                {
                  title: this.language=="Chinese"?item.name:item.nameEnglish,
                  key: item.key,
                  align:"center",
                  render: (h, params) => {
                    return h("div", [
                      h(
                        "span", this.judgeValue(params.row[item.key])
                      ),
                    ]);
                  },
                }
              )
            }else if(!this.dataTitle.length||this.dataTitle[this.dataTitle.length-1].length==2){
              this.dataTitle.push([ {
                title: this.language=="Chinese"?item.name:item.nameEnglish,
                key: item.key,
                align:"center",
                render: (h, params) => {
                  return h("div", [
                    h(
                      "span", this.judgeValue(params.row[item.key])
                    ),
                  ]);
                },
              }])
            }
        }
      })
      this.dataSwitch.forEach(item=>{
        if(dataKey.includes(item.key)){
          this.dataSwitchTitle.push( 
                {
                  title: this.language=="Chinese"?item.name:item.nameEnglish,
                  slot: item.key,
                  align:"center",
                 /*  render: (h, params) => {
                    return h("div", [
                      h(
                        "span", this.judgeValue(params.row[item.key])
                      ),
                    ]);
                  }, */
                }
              )
        }else{
          this.dataSwitchTitle.push( 
            {
              title: this.language=="Chinese"?item.name:item.nameEnglish,
              slot: item.key,
              align:"center",
             /*  render: (h, params) => {
                return h("div", [
                  h(
                    "span", this.judgeValue(params.row[item.key])
                  ),
                ]);
              }, */
            }
          )
        }
      })
    },
    // 开关状态改变确认
    handleBeforeChange (key,val) {
        return new Promise((resolve) => {
            this.$Modal.confirm({
                title: this.language=="Chinese"?'切换确认':"Toggle Confirmation",
                content: this.language=="Chinese"?'您确认要切换开关状态吗？':"Are you sure you want to switch the status?",
                okText:this.language=="Chinese"?"确认":"Confirm",
                cancelText:this.language=="Chinese"?"取消":"Cancel",
                onOk: () => {
                  this.switchToggele(key,val)
                  resolve();
                },
                onCancel:()=>{
                  console.log(key);
                   if(key=="acOutputSwitch"){
                      this.ACOutputValue =!val
                    }else if(key=="dcOutputSwitch"){
                       this.DCOutputValue  =!val
                    }else if(key=="pureInverterRestartSwitch"){
                       this.PureOutputValue  =!val
                    }else if(key=="energySavingModeSwitch"){
                       this.EnergyOutputValue =!val
                    }
                }
            });
        });
    },
    // 开关状态改变发送指令
    switchToggele(key,val){
        let commandVal;
        if(key=="acOutputSwitch"){
            commandVal = "1172*" + (Number(val))
        }else if(key=="dcOutputSwitch"){
            commandVal = "1173*" + (Number(val))
        }else if(key=="pureInverterRestartSwitch"){
            commandVal = "1174*" + (Number(val))
        }else if(key=="energySavingModeSwitch"){
            commandVal = "1175*" + (Number(val))
        }
        this.$axios({
            url: this.apiUrl + "equipmentApollo/ApolloMsgSend",
            method: "post",
            params: {
                wifiId: this.equipmentData.wifiId,
                commandVal,
            },
        }).then((res) => {
            if (res.data.code == 207) {
            setTimeout(()=>{
                this.getEquipmentData()
            },1500)
                this.$Message.info(res.data.msg);
            } else {
                this.$Message.error(res.data.msg);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    },
    // 判断按钮是否可用
    handleSwitch(){
      if(this.equipmentData.equipmentConnectstate == 0){
        return true
      }else if(this.equipmentDataList[0].inverterID == 0){
        return true
      }else{
        return false
      }
    },
    // 提交指令
    submitInstruction(){
        if(this.equipmentData.equipmentConnectstate==0){
            this.cancelInstruction();
            return this.$message.info(this.language=='Chinese'?"离线状态不可设置":"The offline state cannot be set")
        }
        if(this.instructionForm.content==''){
            return this.$message.wanring(this.language=='Chinese'?"指令内容不能为空":"The instruction content cannot be empty")
        }
        this.$axios({
          url: this.apiUrl + "equipmentApollo/ApolloDiyMsgSend",
          method: "post",
          params: {
            wifiId: this.equipmentData.wifiId,
            commandVal:this.instructionForm.content,
          },
        })
          .then((res) => {
            if (res.data.code == 207) {
              setTimeout(()=>{
                this.getEquipmentData()
              },1500)
              this.$Message.info(res.data.msg);
            } else {
              this.$Message.error(res.data.msg);
            }
            this.cancelInstruction();
          })
          .catch((err) => {
            console.log(err);
            this.cancelInstruction();
          });
    },
    // 取消发送指令
    cancelInstruction(){
        this.instructionForm.content = '';
    }
  },
};
</script>

<style lang="less" scoped>
.equipment-data {
  width: 100%;
  height: 100%;
  .ivu-col-span-24 {
    font-size: 16px;
    line-height: 60px;
    text-align: center;
    font-weight: bold;
    color: #606060;
  }
  .state-box {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    .state-bg-off {
      width: 75%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("../../static/images/offline.png");
    }
    .state-bg-on {
      width: 75%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("../../static/images/online.gif");
    }
  }
}
</style>