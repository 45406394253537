<template>
  <!-- 权限管理 -->
  <div class="jurisdiction">
    <Permissions
      v-if="language == 'Chinese'"
      @update-nav="$emit('update-nav')"
    />
    <PermissionsEnglish v-else @update-nav="$emit('update-nav')" />
  </div>
</template>

<script>
import Permissions from "../../components/system/Chinese/Permissions.vue";
import PermissionsEnglish from "../../components/system/English/PermissionsEnglish.vue";
export default {
  components: {
    Permissions,
    PermissionsEnglish,
  },
  props: ["language"],
};
</script>

<style lang="less" scoped>
</style>