<template>
  <!-- 机构管理 -->
  <div class="institutions-list">
    <Table
      border
      stripe
      :loading="loadingShow"
      :columns="institutionTitle"
      :data="institutionList"
      @on-selection-change="$emit('select-change', $event)"
    >
      <template slot-scope="{ row }" slot="action">
        <el-link
          type="primary"
          style="margin-right: 10px"
          @click="$emit('edit-list', row)"
          >编辑</el-link
        >
        <el-link
          v-if="show[0]"
          style="margin-right: 10px"
          type="primary"
          @click="institution('1002', row)"
          >初级管理员机构管理</el-link
        >
        <el-link
          v-if="show[1]"
          style="margin-right: 10px"
          type="primary"
          @click="institution('1003', row)"
          >经销商机构管理</el-link
        >
        <el-link
          v-if="show[2]"
          style="margin-right: 10px"
          type="primary"
          @click="institution('1004', row)"
          >终端机构管理</el-link
        >
        <el-link
          type="danger"
          @click="$emit('delete-list', row)"
          >删除</el-link
        >
      </template>
    </Table>
  </div>
</template>

<script>
export default {
  props: [
    "institutionList",
    "typeNum",
    "hierarchy",
    "id",
    "show",
    "loadingShow",
  ],
  data() {
    return {
      institutionTitle: [
        {
          type: "selection",
          width: 55,
          align: "center",
        },
        {
          title: "机构名称",
          key: "mechanismName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismName)),
            ]);
          },
        },
        {
          title: "Email",
          key: "mechanismEmail",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismEmail)),
            ]);
          },
        },
        {
          title: "联系人",
          key: "mechanismContacts",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismContacts)),
            ]);
          },
        },
        {
          title: "联系电话",
          key: "mechanismPhone",
          align: "center",
          width: 150,
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismPhone)),
            ]);
          },
        },
        {
          title: "备注",
          key: "mechanismNotes",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.mechanismNotes)),
            ]);
          },
        },
        {
          title: "地址",
          key: "address",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("span", this.judgeValue(params.row.address)),
            ]);
          },
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
    };
  },
  methods: {
    // 判断当前层级，并进入下一级
    institution(type, row) {
      if (type == "1002") {
        this.$parent.show.splice(0, 1, false);
      } else if (type == "1003") {
        this.$parent.show.splice(0, 2, false, false);
      } else if (type == "1004") {
        this.$parent.show.splice(0, 3, false, false, false);
      }
      this.$parent.id = row.id;
      this.$parent.typeNum = type;
      this.$parent.hierarchy++;
    },
  },
  watch: {
    typeNum(newValue, oldValue) {
      if (newValue != oldValue) {
        let param = {};
        if (this.id != "0" && this.id != "") {
          param = {
            mechanismType: newValue,
            id: this.id,
          };
          this.$emit("update-child", param);
        }/*  else {
          this.$emit("update-list");
        } */
      }
    },
  },
};
</script>

<style lang="less" scoped>
.institutions-list {
  width: 100%;
  margin-bottom: 10px;
}
</style>