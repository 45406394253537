<template>
  <div class="home">
    <Nav v-if="language=='Chinese'" :userName="userName" @update-name="$emit('update-name',$event)" @toggle-lang="$emit('toggle-lang',$event)" :language="language" />
    <NavEnglish v-else :userName="userName" @update-name="$emit('update-name',$event)" @toggle-lang="$emit('toggle-lang',$event)" :language="language" />
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue'
import NavEnglish from '@/components/NavEnglish.vue'

export default {
  name: "Home",
  components: {
    Nav,
    NavEnglish
  },
  props: ["userName","language"],
  data() {
    return {
     
    }
  },
  created() {
    
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 100%;
  
}

</style>
