<template>
  <!-- 大屏显示 -->
  <div>大屏显示</div>
</template>

<script>
export default {
    data() {
        return {
            fullscreen:false,
        }
    },
    created () {
        // this.handleFullScreen();
    },
    mounted () {
      this.exitFullscreen();

    },
    methods: {
        handleFullScreen() {
            let element = document.documentElement;
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                    // IE11
                        element.msRequestFullscreen();
                    }
                }
            this.fullscreen = !this.fullscreen;
        },
        exitFullscreen(){
            // 打开全屏
            this.handleFullScreen();
            document.addEventListener("fullscreenchange",v=>{
                if(this.fullscreen==true){
                    this.fullscreen = false;
                }else{
                    this.$router.go(-1)
                    this.fullscreen = true
                }
            })
        },
  },
  watch: {
      fullscreen(newValue, oldValue) {
          console.log(newValue);
      }
  },
};
</script>

<style lang="less" scoped>
</style>