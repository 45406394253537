<template>
  <div class="user-box">
    <el-dialog title="用户信息" :visible.sync="$parent.dialogFormVisible">
      <div slot="title" style="font-size: 18px">
        <slot></slot>
      </div>
      <el-form :model="form">
        <el-form-item label="用户名称" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            v-model.trim="form.name"
            autocomplete="off"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="用户账户" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            v-model.trim="form.account"
            autocomplete="off"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="用户密码" :label-width="formLabelWidth">
          <el-input
            v-if="userInfoTitle != 'undate'"
            style="width: 200px"
            type="password"
            v-model.trim="form.password"
            autocomplete="new-password"
            required
            show-password
          ></el-input>
          <div style="width: 200px" v-if="userInfoTitle == 'undate'">
            <Button
              type="primary"
              @click="
                form.password = '12345678';
                $alert('重置密码为：12345678');
              "
              >重置</Button
            >
          </div>
        </el-form-item>
        <el-form-item label="手机号码" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            type="tel"
            v-model.trim="form.phone"
            autocomplete="off"
            :maxlength="11"
            required
          ></el-input>
        </el-form-item>
        <el-form-item label="用户邮箱" :label-width="formLabelWidth">
          <el-input
            style="width: 200px"
            type="email"
            v-model.trim="form.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户地址" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model.trim="form.address"
            autocomplete="off"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户机构" :label-width="formLabelWidth">
          <el-cascader
            style="width: 200px"
            :options="userInstitution"
            :props="getProp"
            v-model="form.value"
            clearable
            :disabled="
              editUser
                ? editUser.userMechanismFk == $root.userInfo.userMechanismFk
                : false
            "
          ></el-cascader>
        </el-form-item>
        <el-form-item label="用户角色" :label-width="formLabelWidth">
          <el-select
            class="role"
            style="width: 200px"
            v-model="form.role"
            placeholder="请选择角色"
            :value="form.role"
            :disabled="
              editUser
                ? editUser.userMechanismFk == $root.userInfo.userMechanismFk
                : false
            "
          >
            <el-option
              v-for="item in roleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$parent.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialogFormVisible", "editUser", "userInfoTitle"],
  data() {
    return {
      dialogTableVisible: false,
      form: {
        name: "",
        account: "",
        password: "",
        phone: "",
        email: "",
        address: "",
        // mechanism: "",
        role: "",
        value: "",
      },
      roleList: [
        {
          label: "超级管理员",
          value: "1",
        },
        {
          label: "高级管理员",
          value: "2",
        },
        {
          label: "初级管理员",
          value: "3",
        },
        {
          label: "经销商",
          value: "4",
        },
        {
          label: "终端用户",
          value: "5",
        },
      ],
      formLabelWidth: "115px",
      userInstitution: null,
      getProp: {
        checkStrictly: true,
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level } = node;
          // console.log(node.data);
          if (!node.data) return;
          setTimeout(() => {
            this.$axios({
              url: this.apiUrl + "mechanism/mechanismChildren",
              method: "get",
              params: {
                ID: node.data.id,
                mechanismType:
                  node.data.type == "1001"
                    ? "1002"
                    : node.data.type == "1002"
                    ? "1003"
                    : "1004",
              },
            })
              .then((res) => {
                resolve(res.data.data);
              })
              .catch((err) => err);
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          }, 1000);
        },
      },
    };
  },
  created() {
    this.roleList =
      this.$root.userInfo.organizationType == "1000"
        ? this.roleList
        : this.$root.userInfo.organizationType == "1001"
        ? [
            {
              label: "高级管理员",
              value: "2",
              disabled: true,
            },
            {
              label: "初级管理员",
              value: "3",
              disabled: false,
            },
            {
              label: "经销商",
              value: "4",
              disabled: false,
            },
            {
              label: "终端用户",
              value: "5",
              disabled: false,
            },
          ]
        : this.$root.userInfo.organizationType == "1002"
        ? [
            {
              label: "初级管理员",
              value: "3",
              disabled: true,
            },
            {
              label: "经销商",
              value: "4",
              disabled: false,
            },
            {
              label: "终端用户",
              value: "5",
              disabled: false,
            },
          ]
        : [];
    if (this.userInfoTitle == "undate") {
      this.setUserInfo();
    }
    if (this.$root.userInfo.organizationType == "1000") {
      // 页面创建时获取用户机构的信息
      this.$axios({
        url: this.apiUrl + "mechanism/mechanismTreeList",
        method: "post",
        params: {
          id: this.$root.userInfo.organizationId,
          mechanismType: this.$root.userInfo.organizationType,
        },
      })
        .then((res) => {
          this.userInstitution = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.$axios({
        url: this.apiUrl + "mechanism/mechanismChildren",
        method: "get",
        params: {
          ID: this.$root.userInfo.organizationId,
          mechanismType:
            this.$root.userInfo.organizationType == "1001"
              ? "1002"
              : this.$root.userInfo.organizationType == "1002"
              ? "1003"
              : "1004",
          // mechanismType:this.$root.userInfo.organizationType,
        },
      })
        .then((res) => {
          this.userInstitution = res.data.data;
        })
        .catch((err) => err);
    }
  },
  methods: {
    // 设置默认用户信息（编辑）
    setUserInfo() {
      if (this.editUser) {
        this.form = {
          name: this.editUser.userName, // 必传
          account: this.editUser.userAccount, // 必传
          password: this.editUser.userPassword, // 必传
          phone: this.editUser.userPhone, // 必传
          email: this.editUser.userEmail,
          address: this.editUser.userAddress,
          // mechanism: this.editUser.userMechanismFk, // 必传
          role: this.editUser.userRoleFk, // 必传
          value: this.editUser.userMechanismFk,
        };
      }
    },
    // 填写信息后提交调用的方法
    submitUser() {
      if (
        this.form.name == "" ||
        this.form.account == "" ||
        this.form.password == "" ||
        this.form.phone == "" ||
        this.form.role == "" ||
        this.form.value == ""
      )
        return this.$alert(
          "用户名称、账号、密码、手机号、机构、角色均不能为空"
        );
      if (
        this.form.phone.length != 11 ||
        this.form.phone.search(/[^\d]/g) != -1
      ) {
        this.setUserInfo();
        return this.$alert("输入的手机号码不符合规范");
      }
      if (this.form.password.length < 6) {
        this.setUserInfo();
        return this.$alert("输入的密码不得小于6位");
      }
      if (this.form.email != "") {
        var re =
          /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if (!re.test(this.form.email)) {
          return this.$alert("输入的邮箱格式不正确");
        }
      }
      let param = {
        userName: this.form.name,
        userAccount: this.form.account,
        userPhone: this.form.phone,
        userEmail: this.form.email,
        userAddress: this.form.address,
        userMechanismFk: Array.isArray(this.form.value)
          ? this.form.value[this.form.value.length - 1]
          : this.form.value,
        userRoleFk: this.form.role,
        mechanismType: "100" + (Number(this.form.role) - 1),
      };
      let url;
      if (this.userInfoTitle == "undate") {
        url = this.apiUrl + "user/updateUser";
        param = {
          id: this.editUser.id,
          ...param,
        };
        if (this.form.password == "12345678") {
          param = {
            ...param,
            userPassword: this.form.password,
          };
        }
        this.$confirm("是否确认修改用户信息")
          .then(() => {
            this.submit(url, param);
          })
          .catch(() => {
            this.$message.info("取消修改");
            this.$parent.dialogFormVisible = false;
          });
      } else {
        param = {
          ...param,
          userPassword: this.form.password,
        };
        url = this.apiUrl + "user/saveUser";
        this.submit(url, param);
      }
    },
    submit(url, param) {
      this.$axios({
        url: url,
        method: "post",
        params: param,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.$message.success(res.data.msg);
            this.updateNewList();
            this.cealrFrom();
          } else {
            this.$message.error(res.data.msg);
          }
          // this.$parent.$parent.newDate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateNewList() {
      // 更新用户数据列表
      this.$emit("update-list");
      this.$parent.dialogFormVisible = false;
    },
    // 清空表单
    cealrFrom() {
      this.form = {
        name: "",
        account: "",
        password: "",
        phone: "",
        email: "",
        address: "",
        role: "",
        value: "",
      };
    },
  },
  watch: {
    // 根据编辑用户信息的更改，更改用户的编辑面板
    editUser(newValue, oldValue) {
      this.form = {
        name: newValue.userName, // 必传
        account: newValue.userAccount, // 必传
        password: newValue.userPassword, // 必传
        phone: newValue.userPhone, // 必传
        email: newValue.userEmail,
        address: newValue.userAddress,
        role: newValue.userRoleFk, // 必传
        value: newValue.userMechanismFk,
      };
    },
    dialogFormVisible(newValue, oldValue) {
      if (this.userInfoTitle == "undate") {
        if (!newValue) {
          this.cealrFrom();
        } else {
          if (this.editUser) {
            this.setUserInfo();
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .el-form-item__content {
    width: 50%;
  }
}
</style>
<style lang="less">
.el-scrollbar__wrap {
  margin-bottom: 0 !important;
  overflow-x: hidden;
}
</style>