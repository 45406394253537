<template>
  <div class="search-institutions">
    <!-- 机构管理top -->
    <label for="search-key">关键字：</label>
    <Input
      id="search-key"
      v-model.trim="value"
      placeholder="Institution Name"
      style="width: 200px"
      @keyup.native.enter="searchData"
    ></Input>
    &nbsp;
    <Button type="primary" shape="circle" icon="ios-search" @click="searchData"
      >Inquire</Button
    >
    &nbsp;
    <Button type="primary" @click="dialogFormVisible = true">Add</Button>
    &nbsp;
    <Button type="primary" @click="$emit('handle-delete')">Delete</Button>
    &nbsp;
    <Button type="primary" v-if="typeNum != $root.userInfo.organizationType" @click="superior"
      >Back To Previous</Button
    >
    <InstitutionsBoxEnglish :id="id"
      :dialogFormVisible="dialogFormVisible"
      message="add"
      @update-list="$emit('update-list')"
      :typeNum="typeNum"
      :userList="userList"
      >Add Institution Information</InstitutionsBoxEnglish
    >
  </div>
</template>

<script>
import InstitutionsBoxEnglish from "./institutions/InstitutionsBoxEnglish.vue";
export default {
  name: "SearchInstitutions",
  props: ["typeNum", "id", "hierarchy","userList"],
  components: {
    InstitutionsBoxEnglish,
  },
  data() {
    return {
      value: "",
      dialogFormVisible: false,
      oldType: [this.$root.userInfo.organizationType],
      oldId: [""],
    };
  },
  methods: {
    // 搜索机构信息
    searchData() {
      let param = {
        // page: 1,
        searchName: this.value,
      };
      if (this.id != "") {
        param = {
          id: this.id,
          ...param,
        };
      }
      this.$emit("search-list", param);
    },
    // 返回上级
    superior() {
      let num = this.hierarchy - 1;
      this.oldType.pop();
      this.oldId.pop();
      // this.$emit("return-go", this.oldType[num]);
      this.$parent.typeNum = this.oldType[num];
      this.$parent.id = this.oldId[num];
      this.$parent.hierarchy--;
      this.$parent.returnGo();
    },
  },
  watch: {
    hierarchy(newValue, oldValue) {
      // console.log(newValue > oldValue);
      if (newValue > oldValue) {
        this.oldType.push(this.typeNum);
        this.oldId.push(this.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-institutions {
  width: 100%;
  border: 1px solid #e7e7e7;
  padding: 10px 0;
  padding-left: 10px;
  border-radius: 5px;
}
</style>