<template>
  <div class="file-upload">
    <div class="heard">
      <h5>
        Current version number：{{ versionData ? versionData.version : "" }}
      </h5>
      <div class="file-version">
        <label for="version">Version Number：</label>
        <Input
          v-model.trim="fileData.version"
          placeholder="Please enter the version number"
          style="width: auto"
        />
        <small>* Version number format is：V01.01O</small>
      </div>
    </div>
    <Upload
      style="width: 100%"
      type="drag"
      :action="apiUrl + 'OTAOnline/uploadFile'"
      :show-upload-list="false"
      :data="fileData"
      :before-upload="handleBefore"
      ><div style="padding: 80px 0">
        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
        <p>Click or drag files here to upload</p>
      </div>
    </Upload>
    <div class="file-input">
      <Upload
        style="flex: 1"
        :action="apiUrl + 'OTAOnline/uploadFile'"
        :show-upload-list="false"
        :before-upload="handleBefore"
        :data="fileData"
      >
        <!-- :on-success="uploadFile" -->
        <el-input
          style="width: 100%"
          v-model.trim="fileAddress"
          type="text"
          placeholder="Please select a file"
        />
      </Upload>
      <Button
        style="margin-left: 10px"
        icon="ios-cloud-upload-outline"
        type="primary"
        @click="uploadFile"
        >Click upload</Button
      >
      <Button
        style="margin-left: 10px"
        icon="ios-cloud-download-outline"
        type="primary"
        @click="downloadFile"
        >Click download</Button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["versionData"],
  data() {
    return {
      fileList: [],
      fileAddress: "",
      fileData: {
        ID: "",
        version: "",
        versionNow: "",
      },
    };
  },
  methods: {
    /*  上传成功的方法（使用组件自动上传）
    uploadFile(response, file, fileList) {
      this.fileList = fileList;
      this.fileAddress = this.fileList[0].name;
      this.fileData.version = "";
      if (fileList[0].response.code == 200) {
        this.$emit("updata-version");
        this.$message.success("Upload Successful");
      } else {
        this.$message.error(fileList[0].response.msg);
      }
    }, */
    // 上传文件
    uploadFile() {
      if (this.fileData.version == "") {
        this.$message.error("Version number not entered");
        return;
      }
      // 文件上传需以form表单来作为参数数据
      let formData = new FormData();
      formData.append("ID", this.fileData.ID);
      formData.append("file", this.fileList);
      formData.append("version", this.fileData.version);
      formData.append("versionNow", this.fileData.versionNow);
      this.$axios({
        url: this.apiUrl + "OTAOnline/FourGUploadFile",
        method: "post",
        data: formData,
      })
        .then((res) => {
          if (res.data.code == "200") {
            this.fileAddress = "";
            this.$emit("updata-version");
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => err);
    },
    // 上传前调用的函数，返回false不上传
    handleBefore() {
      this.fileList = file;
      this.fileAddress = file.name;
      this.fileData.ID = this.versionData.id;
      this.fileData.versionNow = this.versionData.version;
      // if (this.fileData.version != "") {
      //   return true;
      // } else {
      //   this.$message.error("Version number not entered");
      return false;
      // }
    },
    // 下载文件
    downloadFile() {
      this.$axios({
        url: this.apiUrl + "OTAOnline/TLDownloadFile",
        method: "post",
        responseType: "blob",
      })
        .then((res) => {
          //   console.log(res);
          let date = new Date().toLocaleDateString();
          let year = date.split("/")[0];
          let month =
            date.split("/")[1] >= 10
              ? date.split("/")[1]
              : "0" + date.split("/")[1];
          let day =
            date.split("/")[2] >= 10
              ? date.split("/")[2]
              : "0" + date.split("/")[2];
          let fileName =
            "SNTUM-" + this.versionData.version + "-" + year + month + day;

          const link = document.createElement("a");
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = fileName + ".bin";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })

        .catch((err) => {
          this.$Notice.error({
            title: "Error",
            desc: "Network connection error",
          });
        });
    },
  },
  watch: {
    versionData(newValue, oldValue) {
      if (newValue) {
        this.fileData.ID = this.versionData.id;
        this.fileData.versionNow = this.versionData.version;
      }
    },
  },
};
</script>

<style lang="less">
.file-upload {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .heard {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    .file-version {
      margin-left: 30px;
      font-size: 16px;
      label {
        margin-bottom: 0;
      }
      small {
        margin-left: 10px;
        color: red;
      }
    }
  }
  .file-input {
    width: 100%;
    margin-top: 10px;
    text-align: start;
    display: flex;
    align-items: center;
    .ivu-upload-select {
      display: block;
    }
  }
}
</style>