<template>
  <!-- Apollo一体机运行管理 -->
  <div class="Apollo-operation">
    <template v-if="!faultShow && !equipmentDataShow">
        <template v-if="language == 'Chinese'">
            <SearchEquipment @search-equipment="getApolloOperstion($event)" />
        </template>
        <template v-else>
            <SearchEquipmentEnglish @search-equipment="getApolloOperstion($event)" />
        </template>
      <!-- @search-equipment="ApolloOperstionList = $event" -->
        <ApolloOperationList
          :equipmentList="ApolloOperstionList"
          :count="count"
          :index="index"
          :pageLimit="pageLimit"
          :loadingShow="loadingShow"
          @fault-info="
          faultApolloList = $event;
          faultShow = true;
          "
          @equipment-data="
          equipmentDataApollo = $event;
          equipmentDataShow = true;
          "
          @device-setting="getApolloOperstion"
          :language="language"
        />
    </template>
    <!-- <template v-else-if="faultShow">
      <ApolloFaultInfo
        @return-superior="faultShow = $event"
        :faultUrl="faultApolloUrl"
        :faultList="faultApolloList"
        :loadingShow="loadingShow"
        :language="language"
      />
    </template> -->
    <template v-else-if="equipmentDataShow">
      <ApolloEquipmentData
        :loadingShow="loadingShow"
        :equipmentData="equipmentDataApollo"
        @return-to="equipmentDataShow = false"
        :language="language"
      />
    </template>
  </div>
</template>

<script>
import SearchEquipment from "../../components/equipment/Chinese/SearchEquipment.vue";
import ApolloOperationList from "../../components/equipment/ApolloOperationList.vue";
import ApolloFaultInfo from "../../components/equipment/ApolloFaultInfo.vue";
import ApolloEquipmentData from "../../components/equipment/ApolloEquipmentData.vue";
import SearchEquipmentEnglish from "../../components/equipment/English/SearchEquipmentEnglish.vue";
export default {
  components: {
    SearchEquipment,
    ApolloOperationList,
    ApolloFaultInfo,
    ApolloEquipmentData,
    SearchEquipmentEnglish,
  },
  props: ["language"],
  data() {
    return {
      faultShow: false,
      equipmentDataShow: false,  // 设备数据显示
      count: 0,
      index: 1,
      pageLimit: 10,
      ApolloOperstionList: [], // Apollo列表
      ApolloRunUrl: "equipmentApollo/equipmentApolloListRun", // Apollo所需接口
      faultApolloUrl: "", // 故障信息获取接口
      faultApolloList: null,
      loadingShow: true,
      equipmentDataApollo: null,
      searchParam: {},
    };
  },
  created() {
    this.getApolloOperstion();
  },
  methods: {
    // 获取Apollo运行设备列表数据
    getApolloOperstion(param) {
      this.index = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.index
          : 1
        : this.index;
      this.pageLimit = param
        ? JSON.stringify(param) == JSON.stringify(this.searchParam)
          ? this.pageLimit
          : 10
        : this.pageLimit;
      this.searchParam = param?param:this.searchParam;
      this.loadingShow = true;
      this.$axios({
        url: this.apiUrl + this.ApolloRunUrl,
        method: "get",
        params: {
          page: this.index,
          limit: this.pageLimit,
          userMechanismFk:this.$root.userInfo.organizationId,
          MechanismType:this.$root.userInfo.organizationType,
          ...this.searchParam,
        },
        withCredentials : true
      })
        .then((res) => {
          // console.log(res);
          this.loadingShow = false;
          if (res.data.status == 200) {
            this.ApolloOperstionList = res.data.data;
            this.count = res.data.count;
          }else{
            this.ApolloOperstionList = []
          }
        })
        .catch((err) => {
          this.ApolloOperstionList = []
          this.loadingShow = false;
          console.log(err);
        });
    },
  },
  watch: {
    index(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getApolloOperstion();
      }
    },
    pageLimit(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getApolloOperstion();
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>