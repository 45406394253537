<template>
  <div class="edit-menu">
    <Button type="primary" @click="addHead">新增首级功能</Button>
    &nbsp;
    <Button type="primary" @click="addChildren">新增子级功能</Button> &nbsp;
    <Button type="primary" @click="editMenu">编辑功能菜单</Button> &nbsp;
    <Button type="primary" @click="delectMenu">删除</Button>
  </div>
</template>

<script>
export default {
  props: ["editList"],
  methods: {
    // 添加首级菜单
    addHead() {
      this.$emit("edit-list", { show: true, mode: "head" });
    },
    // 添加子级菜单
    addChildren() {
      if (this.editList) {
        this.$emit("edit-list", { show: true, mode: "children" });
        return;
      }
      this.$alert("操作不成功", "提示", {
        confirmButtonText: "确定",
      });
    },
    // 编辑菜单
    editMenu() {
      if (this.editList) {
        this.$emit("edit-list", { show: true, mode: "edit" });
        return;
      }
      this.$alert("操作不成功", "提示", {
        confirmButtonText: "确定",
      });
    },
    // 删除所选菜单
    delectMenu() {
      this.$confirm("是否确定删除所选菜单")
        .then(() => {
          this.$axios({
            url: this.apiUrl + "systemMenu/DeleteSystemMenu",
            method: "post",
            params: {
              id: this.editList.id,
            },
          })
            .then((res) => {
              console.log(res);
              if (res.data.code == "205") {
                this.$message.success(res.data.msg);
                this.$emit("delect-list");
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => err);
        })
        .catch(() => {
          this.$message.info("取消删除");
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>