<template>
  <div class="search-equipment">
    <template v-if="!$route.path.includes('apollo')">
    <label for="search-key">设备ID：</label>
    <Input
      id="search-key"
      v-model.trim="equipmentID"
      placeholder="请输入设备ID"
      style="width: 200px"
      @keyup.native.enter="searchEquipment"
    ></Input>
    &nbsp;
    </template>
    <label for="search-key">模块ID：</label>
    <Input
      id="search-key"
      v-model.trim="WiFiID"
      placeholder="请输入模块ID"
      style="width: 200px"
      @keyup.native.enter="searchEquipment"
    ></Input>
    &nbsp;
    
    <label for="search-state">设备类型：</label>
    <Select id="search-state" v-model="equipmentType" style="width: 200px">
      <Option
        v-for="item in equipmentTypeList"
        :value="item.value"
        :key="item.value"
        >{{ item.label }}</Option
      >
    </Select>
    &nbsp;
    <label for="search-state">设备状态：</label>
    <Select id="search-state" v-model="state" style="width: 200px">
      <Option
        v-for="item in equipmentState"
        :value="item.value"
        :key="item.value"
        >{{ item.label }}</Option
      >
    </Select>
    &nbsp;
    <Button type="primary" icon="ios-search" @click="searchEquipment"
      >搜索</Button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      WiFiID: "", // wifiID
      equipmentID: "", // 设备ID
      state: "All", // 设备状态
      equipmentType:"all", // 设备类型
      equipmentState: [
        {
          label: "全部",
          value: "All",
        },
        {
          label: "在线",
          value: "1",
        },
        {
          label: "离线",
          value: "0",
        },
      ],
      equipmentTypeList:[{
        label:"全部",
        value:"all"
      },{
        label:"WiFi",
        value:"WIFI"
      },{
        label:"4G",
        value:"4G"
      }]
    };
  },
  methods: {
    // 搜索运行设备方法
    searchEquipment() {
      let param = {
        wifiId: this.WiFiID,
        equipmentId: this.equipmentID,
        equipmentConnectstate: this.state!='All'?this.state:"",
        equipmentType:this.equipmentType == "all"?"":this.equipmentType
      };
      this.$emit("search-equipment", param); // 向父级传输搜索的参数
    },
  },
};
</script>

<style lang="less" scoped>
.search-equipment {
  margin-bottom: 10px;
}
</style>