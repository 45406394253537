<template>
  <div class="search-thirduser">
    <label for="search-key">Keywords：</label>
    <Input
      id="search-name"
      v-model.trim="otherUserName"
      placeholder="Please Enter Username"
      style="width: 200px"
      @keyup.native.enter="searchThirdUser"
    ></Input>
    &nbsp;
    <label for="search-type">Login Type：</label>
    <Select id="search-type" v-model="otherUserType" style="width: 200px">
      <Option v-for="item in cityList" :value="item.value" :key="item.value">{{
        item.label
      }}</Option>
    </Select>
    &nbsp;
    <label for="search-time">Login Time：</label>
    <el-date-picker
      id="search-time"
      v-model="loginTime"
      type="date"
      placeholder="Select Date"
    >
    </el-date-picker>
    &nbsp;
    <Button
      type="primary"
      icon="ios-search"
      @click="searchThirdUser"
      shape="circle"
      >Search</Button
    >
    &nbsp;
    <Button type="primary" v-if="$root.userInfo.organizationType=='1000'||$root.userInfo.organizationType=='1001'" @click="deleteThirdUser">Delete</Button>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["deleteAllThirdUser"],
  data() {
    return {
      otherUserName: "",
      otherUserType: "All",
      loginTime: new Date(),
      cityList: [
        {
          label: "WeChat",
          value: "WX",
        },
        {
          label: "Alipay",
          value: "ZFB",
        },
        {
          label: "QQ",
          value: "QQ",
        },
        {
          label: "Apple",
          value: "Apple",
        },
        {
          label: "All",
          value: "All",
        },
      ],
    };
  },
  methods: {
    // 搜索用户信息
    searchThirdUser() {
      let param = {
        otherUserName: this.otherUserName,
        otherUserType: this.otherUserType != "All" ? this.otherUserType : "",
        date:this.loginTime?this.loginTime.toLocaleDateString():"",
      };
      this.$emit("search-third-user", param);
    },
    // 删除第三方用户信息
    deleteThirdUser() {
      this.$confirm("是否确定删除").then(() => {
        // 判断所选信息是否为多条用户信息组成的数组
        if (Array.isArray(this.deleteAllThirdUser)) {
          this.deleteAllThirdUser.forEach((item) => this.handleThirdUser(item.id));
        } else {
          this.handleThirdUser(this.deleteAllThirdUser.id);
        }
      }).catch(()=>{
        this.$message("取消删除")
      });
    },
    // 向后台传输需要删除的第三方数据
    handleThirdUser(id) {
      this.$axios({
        url: this.apiUrl + "otherUser/delOtherUserById",
        method: "post",
        params: {
          id: id,
        },
      }).then((res) => {
        if (res.data.status == 200) {
          this.$emit("update-list");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.search-thirduser {
  width: 100%;
  border: 1px solid #e7e7e7;
  padding: 5px 0;
  padding-left: 10px;
}
</style>