var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.language == 'Chinese')?_c('div',{staticClass:"junior-administrator"},[_c('SearchInstitutionInfo',{attrs:{"selectList":_vm.selectList,"addTitle":_vm.addTitleChinese,"institutionManufacturers":_vm.institutionJuniorDealer != ''
        ? _vm.institutionJuniorDealer
        : _vm.institutionJunior,"institutionsType":_vm.institutionsTypeChinese,"addUrl":_vm.institutionJuniorDealer != ''
        ? _vm.addJuniorTerminalUrl
        : _vm.institutionJunior != ''
        ? _vm.addJuniorDealerUrl
        : _vm.addJuniorUrl,"type":_vm.institutionsType},on:{"update-list":function($event){_vm.institutionJuniorDealer != ''
        ? _vm.getJuniorTerminal()
        : _vm.institutionJunior != ''
        ? _vm.getJuniorDealer()
        : _vm.getJuniorAdmininstrator()},"delete-select":function($event){return _vm.deleteJunior($event)},"renturn-list":_vm.renturnToList,"search-list":function($event){_vm.institutionJuniorDealer != ''
        ? _vm.getJuniorTerminal($event)
        : _vm.institutionJunior != ''
        ? _vm.getJuniorDealer($event)
        : _vm.getJuniorAdmininstrator($event)}}},[_vm._v("机构名称：")]),_c('InstitutionsList',{attrs:{"count":_vm.count,"index":_vm.index,"pageLimit":_vm.pageLimit,"institutionList":_vm.juniorAdministratorList,"loadingShow":_vm.loadingShow,"editUrl":_vm.institutionJuniorDealer != ''
        ? _vm.editJuniorTerminalUrl
        : _vm.institutionJunior != ''
        ? _vm.editJuniorDealerUrl
        : _vm.editJuniorUrl,"institutionManufacturers":_vm.institutionJuniorDealer != ''
        ? _vm.institutionJuniorDealer
        : _vm.institutionJunior,"institutionsType":_vm.institutionsTypeChinese,"institutionName":_vm.addTitleChinese,"type":_vm.institutionsType},on:{"select-list":function($event){_vm.selectList = $event},"institutions-dealer":function($event){_vm.institutionJunior = $event;
      _vm.addTitleChinese = '经销商';
      _vm.getJuniorDealer();},"institutions-terminal":function($event){_vm.institutionJuniorDealer = $event;
      _vm.addTitleChinese = '终端';
      _vm.getJuniorTerminal();},"delete-list":function($event){return _vm.deleteJunior($event)},"updata-list":function($event){_vm.institutionJuniorDealer != ''
        ? _vm.getJuniorTerminal()
        : _vm.institutionJunior != ''
        ? _vm.getJuniorDealer()
        : _vm.getJuniorAdmininstrator()}}})],1):_c('div',{staticClass:"junior-administrator"},[_c('SearchInstitutionInfoEnglish',{attrs:{"selectList":_vm.selectList,"addTitle":_vm.addTitleEnglish,"institutionManufacturers":_vm.institutionJuniorDealer != ''
        ? _vm.institutionJuniorDealer
        : _vm.institutionJunior,"addUrl":_vm.institutionJuniorDealer != ''
        ? _vm.addJuniorTerminalUrl
        : _vm.institutionJunior != ''
        ? _vm.addJuniorDealerUrl
        : _vm.addJuniorUrl,"institutionsType":_vm.institutionsTypeEnglish,"type":_vm.institutionsType},on:{"update-list":function($event){_vm.institutionJuniorDealer != ''
        ? _vm.getJuniorTerminal()
        : _vm.institutionJunior != ''
        ? _vm.getJuniorDealer()
        : _vm.getJuniorAdmininstrator()},"search-list":function($event){_vm.institutionJuniorDealer != ''
        ? _vm.getJuniorTerminal($event)
        : _vm.institutionJunior != ''
        ? _vm.getJuniorDealer($event)
        : _vm.getJuniorAdmininstrator($event)},"renturn-list":_vm.renturnToList,"delete-select":function($event){return _vm.deleteJunior($event)}}},[_vm._v("Institution Name：")]),_c('InstitutionsListEnglish',{attrs:{"count":_vm.count,"index":_vm.index,"pageLimit":_vm.pageLimit,"institutionList":_vm.juniorAdministratorList,"editUrl":_vm.institutionJuniorDealer != ''
        ? _vm.editJuniorTerminalUrl
        : _vm.institutionJunior != ''
        ? _vm.editJuniorDealerUrl
        : _vm.editJuniorUrl,"loadingShow":_vm.loadingShow,"institutionsType":_vm.institutionsTypeEnglish,"institutionManufacturers":_vm.institutionJuniorDealer != ''
        ? _vm.institutionJuniorDealer
        : _vm.institutionJunior,"institutionName":_vm.addTitleEnglish,"type":_vm.institutionsType},on:{"select-list":function($event){_vm.selectList = $event},"institutions-dealer":function($event){_vm.institutionJunior = $event;
      _vm.addTitleEnglish = 'Dealer';
      _vm.getJuniorDealer();},"institutions-terminal":function($event){_vm.institutionJuniorDealer = $event;
      _vm.addTitleEnglish = 'Terminal';
      _vm.getJuniorTerminal();},"delete-list":function($event){return _vm.deleteJunior($event)},"updata-list":function($event){_vm.institutionJuniorDealer != ''
        ? _vm.getJuniorTerminal()
        : _vm.institutionJunior != ''
        ? _vm.getJuniorDealer()
        : _vm.getJuniorAdmininstrator()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }