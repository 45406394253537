<template>
  <div class="login-wrap">
    <Login
      v-if="language == 'Chinese'"
      :user="user"
      :userName="userName"
      @toggle-lang="$emit('toggle-lang', $event)"
      @login-name="$emit('login-name', $event)"
    />
    <LoginEnglish
      v-else
      :user="user"
      :userName="userName"
      @toggle-lang="$emit('toggle-lang', $event)"
      @login-name="$emit('login-name', $event)"
    />
  </div>
</template>

<script>
import Login from "../components/Login/Login.vue";
import LoginEnglish from "../components/Login/LoginEnglish.vue";
export default {
  components: {
    Login,
    LoginEnglish,
  },
  props: ["language", "userName"],
  data() {
    return {
      user: "account",
    };
  },
};
</script>

<style lang="less" scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../static/images/login.jpg");
  // background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>