<template>
  <!-- 告警设备top搜索 -->
  <div class="search-alarm">
    <Row>
      <template v-if="!$route.path.includes('apollo')">
      <label for="equipment-id">DeviceID：</label>
      <Input
        id="equipment-id"
        v-model.trim="euqipmentID"
        placeholder="Please enter deviceID"
        style="width: 200px"
        @keyup.native.enter="searchAlarm"
      ></Input>
      &nbsp;
      &nbsp;
      </template>
      <label for="equipment-id">ModuleID：</label>
      <Input
        id="equipment-id"
        v-model.trim="wifiID"
        placeholder="Please enter ModuleID"
        style="width: 200px"
        @keyup.native.enter="searchAlarm"
      ></Input>
      &nbsp;
      &nbsp;
      <label for="alarm-state">Processing Status：</label>
      <Select id="alarm-state" v-model="state" style="width: 200px">
        <Option
          v-for="item in disposeState"
          :value="item.value"
          :key="item.value"
          >{{ item.label }}</Option
        >
      </Select>
    </Row>
    <Row class="date">
      <label for="alarm-star-date">Start Date：</label>
      <Col id="alarm-star-date">
        <DatePicker
          v-model="alarmStarDate"
          type="date"
          placement="bottom-end"
          placeholder="Please Select Date"
          style="width: 200px"
          :options="notStarDate"
        ></DatePicker>
      </Col>
      &nbsp;
      &nbsp;
      <label for="alarm-end-date">End Date：</label>
      <Col id="alarm-end-date">
        <DatePicker
          v-model="alarmEndDate"
          type="date"
          placement="bottom-end"
          placeholder="Please Select Date"
          style="width: 200px"
          :options="notEndDate"
        ></DatePicker>
      </Col>
      &nbsp;
      &nbsp;
      <Button type="primary" @click="searchAlarm">Inquiry</Button>
      &nbsp;
      <Button
        v-if="faultShow"
        type="primary"
        @click="$emit('return-superior', false)"
        >Return to previous page</Button
      >
      &nbsp;
      <Button type="primary" @click="exporteExcel">Export</Button>
      &nbsp;
      <Button type="primary" @click="deleteList">Delete</Button>
    </Row>
  </div>
</template>

<script>
export default {
  props: ["faultShow", "alarmList", "selectAlarm", "fileUrl"],
  data() {
    return {
      wifiID: this.alarmList ? this.alarmList.wifiId : "",
      euqipmentID: this.alarmList ? this.alarmList.equipmentId : "", // 设备ID
      alarmStarDate: this.alarmList
        ? new Date(this.alarmList.equipmentCreatime)
        : new Date(), // 开始日期
      alarmEndDate: new Date(), // 结束日期
      disposeState: [
        {
          value: "2",
          label: "All",
        },
        {
          value: "1",
          label: "Processed",
        },
        {
          value: "0",
          label: "Untreated",
        },
      ],
      state: this.alarmList ? this.alarmList.equipmentState : "0",
      fileName: "",
      notStarDate: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now();
        },
      },
      notEndDate: {
        disabledDate(date) {
          // console.log(date);
          return date && date.valueOf() > Date.now();
        },
      },
      delectAlarmUrl: "",
    };
  },
  created() {
    this.updateUrl();
    if (this.faultShow) {
      let param = {
        warnWifiid: this.wifiID,
        warnEquipmentid: this.euqipmentID,
        warnOperstate: this.state != "2" ? this.state : "",
        starDate: this.timeString(this.alarmStarDate),
        endDate: this.timeString(this.alarmEndDate),
      };
      // console.log(this.alarmStarDate,this.alarmEndDate);
      this.$emit("search-list", param);
    }
  },
  methods: {
    // 搜索告警设备
    searchAlarm() {
      let param = {
        warnWifiid: this.wifiID,
        warnEquipmentid: this.euqipmentID,
        warnOperstate: this.state != "2" ? this.state : "",
        starDate: this.timeString(this.alarmStarDate),
        endDate: this.timeString(this.alarmEndDate),
      };
        if (new Date(this.alarmStarDate) > new Date(this.alarmEndDate)) {
        param = {
          ...param,
          starDate: this.timeString(this.alarmEndDate),
          endDate: this.timeString(this.alarmStarDate),
        };
      }
      // console.log(this.alarmStarDate,this.alarmEndDate);
      this.$emit("search-list", param);
    },
    // 设置告警设备删除接口数据
    updateUrl() {
      if (this.$route.path == "/inverter-alarm") {
        this.delectAlarmUrl = "";
      } else if (this.$route.path == "/pv-alarm") {
        this.delectAlarmUrl = "";
      } else if (this.$route.path == "/MPPT-all-in-one-alarm") {
        this.delectAlarmUrl = "equWarnMppt/delEquWarnMpptById";
      }
    },
    // 导出
    exporteExcel() {
      let listId = this.selectAlarm.map((item) => item.id);
      this.$prompt("Please enter the export file name", "Tips", {
        confirmButtonText: "Sure",
        cancelButtonText: "Cancel",
      })
        .then(({ value }) => {
          if (!value)
            return this.$Modal.info("Please enter the export file name");
          this.fileName = value;
          this.$axios({
            url: this.apiUrl + this.fileUrl,
            method: "post",
            params: {
              idList: listId.join("-"),
            },
            withCredentials: true,
            responseType: "blob",
          })
            .then((res) => {
              const link = document.createElement("a");
              let blob = new Blob([res.data], {
                type: "application/x-excel",
              });
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              link.download = this.fileName + ".xls";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch((error) => {
              this.$Notice.error({
                title: "Error",
                desc: "Network connection error",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel input",
          });
        });
      // console.log(this.qs.stringify({ idList: listId }, { indices: false }));
    },
    // 多选删除告警
    deleteList() {
      // selectAlarm
      this.$confirm("Are you sure to delete")
        .then(() => {
          this.selectAlarm.forEach((item, index) => {
            this.$axios({
              url: this.apiUrl + this.delectAlarmUrl,
              method: "post",
              params: {
                id: item.id,
              },
            })
              .then((res) => {
                if (res.data.status == 200) {
                  if (index == this.selectAlarm.length - 1) {
                    this.$message.success(res.data.msg);
                  }
                  this.$emit("update-list");
                } else {
                  if (index == this.selectAlarm.length - 1) {
                    this.$message.error(res.data.msg);
                  }
                }
              })
              .catch((err) => err);
          });
        })
        .catch(() => {
          this.$message("Cancel Delete");
        });
    },
    // 时间格式处理
    timeString(time) {
      let year = time.getFullYear();
      let month =
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1);
      let date = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
      return year + "-" + month + "-" + date;
    },
  },
};
</script>

<style lang="less" scoped>
.search-alarm {
  margin-bottom: 10px;
}
.ivu-row {
  align-items: center;
  label {
    margin-bottom: 0;
  }
  &.date {
    margin-top: 10px;
  }
}
.ivu-col-span-12 {
  flex: 0;
}
</style>
<style lang="less">
.ivu-table-tip {
  overflow-x: hidden;
}
</style>