<template>
  <!-- 导航菜单 -->
  <el-aside 
    :width="!isCollapse ? '225px' : '63px'"
    style="background-color: rgb(50, 65, 87); padding-top: 10px"
  >
    <el-scrollbar
      wrap-class="scrollbar-wrapper"
      :style="{ height: '100%', width: !isCollapse ? '225px' : '63px' }"
    >
      <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        @select="handleOpen"
        :collapse="isCollapse"
        background-color="#324157"
        text-color="#bfcbd9"
        :router="true"
        :unique-opened="false"
        :collapse-transition="false"
      >
        <el-submenu
          v-for="(item, index) in asideList"
          :key="item.id"
          :index="index + ''"
        >
          <template slot="title">
             <i :class='"iconfont icon-"+squareUrl(item.label)'></i>
             <img v-if="squareUrl(item.label)=='apollo-icon'"
              style="width: 20px; height: 20px"
              src="../static/images/apollo-icon.png" />&nbsp;
            <span slot="title">{{ item.egLable }}</span>
          </template>
          <el-menu-item
            v-for="item1 in item.children"
            :key="item1.id"
            :index="item1.label | pathRouter(routerList)"
            :route="item1.label | pathRouter(routerList)"
          ><i class="el-icon-time" style="opacity: 0;margin:0"></i>{{ item1.egLable }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-scrollbar>
  </el-aside>
</template>

<script>
export default {
  props: ["isCollapse","asideList"],
  inject:["reload"],
  data() {
    return {
      // 获取路由信息，使导航栏对应路由信息跳转路由
      routerList: this.$router.options.routes[1].children,
    };
  },
  methods: {
    handleOpen(index,indexPath) {
      if(index==this.$route.path) {
        this.reload()
      }
      // console.log(this.$route);
    },
    squareUrl(imgName){
      if(imgName.includes('系统')){
        return 'xitongguanli'
      }else if(imgName.includes('MPPT')){
        return 'mppt-yitiji'
      }else if(imgName.includes('PV')){
        return 'pv-shebei'
      }else if(imgName.includes('逆变器')){
        return 'nibianqi'
      }else if(imgName.includes('预付费')){
        return 'yufufeiguanli'
      }else if(imgName.includes("数据")){
        return 'shuju'
      }else if(imgName.includes("Apollo")){
        return "apollo-icon"
      }
    }
  },
};
</script>

<style lang="less" scoped>
.el-menu{
  border-right: 0;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 225px;
}
a {
  color: #bfcbd9;
  &.router-link-exact-active {
    color: #2d8cf0;
  }
}
</style>
<style lang="less">
.el-aside{
  .el-scrollbar__wrap {
    margin-bottom: -17px !important;
    margin-right: -17px !important;
    overflow-x: hidden !important;
  }
  .el-submenu__title {
    width: 225px;
  }
}
</style>